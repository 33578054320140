export const DEFAULT_API = "https://admin.stage.mysenscare.com";
export const DAYS = ["mon", "tue", "wed", "thr", "fri", "sat", "sun"];

// time
export const TIME_6_9_AM = [
  { name: "Mon", time: "mon-6 - 9 AM" },
  { name: "Tue", time: "tue-6 - 9 AM" },
  { name: "Wed", time: "wed-6 - 9 AM" },
  { name: "Thr", time: "thr-6 - 9 AM" },
  { name: "Fri", time: "fri-6 - 9 AM" },
  { name: "Sat", time: "sat-6 - 9 AM" },
  { name: "Sun", time: "sun-6 - 9 AM" },
];

export const TIME_9_12_PM = [
  { name: "Mon2", time: "mon2-9 - 12 Pm" },
  { name: "Tue2", time: "tue2-9 - 12 Pm" },
  { name: "Wed2", time: "wed2-9 - 12 Pm" },
  { name: "Thr2", time: "thr2-9 - 12 Pm" },
  { name: "Fri2", time: "fri2-9 - 12 Pm" },
  { name: "Sat2", time: "sat2-9 - 12 Pm" },
  { name: "Sun2", time: "sun2-9 - 12 Pm" },
];

export const TIME_12_3_PM = [
  { name: "Mon3", time: "mon3-12 - 3 PM" },
  { name: "Tue3", time: "tue3-12 - 3 PM" },
  { name: "Wed3", time: "wed3-12 - 3 PM" },
  { name: "Thr3", time: "thr3-12 - 3 PM" },
  { name: "Fri3", time: "fri3-12 - 3 PM" },
  { name: "Sat3", time: "sat3-12 - 3 PM" },
  { name: "Sun3", time: "sun3-12 - 3 PM" },
];

export const TIME_3_6_PM = [
  { name: "Mon4", time: "mon4-3 - 6 PM" },
  { name: "Tue4", time: "tue4-3 - 6 PM" },
  { name: "Wed4", time: "wed4-3 - 6 PM" },
  { name: "Thr4", time: "thr4-3 - 6 PM" },
  { name: "Fri4", time: "fri4-3 - 6 PM" },
  { name: "Sat4", time: "sat4-3 - 6 PM" },
  { name: "Sun4", time: "sun4-3 - 6 PM" },
];

export const TIME_6_9_PM = [
  { name: "Mon5", time: "mon5-6 - 9 PM" },
  { name: "Tue5", time: "tue5-6 - 9 PM" },
  { name: "Wed5", time: "wed5-6 - 9 PM" },
  { name: "Thr5", time: "thr5-6 - 9 PM" },
  { name: "Fri5", time: "fri5-6 - 9 PM" },
  { name: "Sat5", time: "sat5-6 - 9 PM" },
  { name: "Sun5", time: "sun5-6 - 9 PM" },
];

export const TIME_9_12_AM = [
  { name: "Mon6", time: "mon6-9 - 12 AM" },
  { name: "Tue6", time: "tue6-9 - 12 AM" },
  { name: "Wed6", time: "wed6-9 - 12 AM" },
  { name: "Thr6", time: "thr6-9 - 12 AM" },
  { name: "Fri6", time: "fri6-9 - 12 AM" },
  { name: "Sat6", time: "sat6-9 - 12 AM" },
  { name: "Sun6", time: "sun6-9 - 12 AM" },
];

export const TIME_12_6_AM = [
  { name: "Mon7", time: "mon7-12 - 6 AM" },
  { name: "Tue7", time: "tue7-12 - 6 AM" },
  { name: "Wed7", time: "wed7-12 - 6 AM" },
  { name: "Thr7", time: "thr7-12 - 6 AM" },
  { name: "Fri7", time: "fri7-12 - 6 AM" },
  { name: "Sat7", time: "sat7-12 - 6 AM" },
  { name: "Sun7", time: "sun7-12 - 6 AM" },
];

export const TIMES_OF_DAYS = ["morning", "noon", "evening", "moon"];
//

// links
export const LINKS = [
  { to: "/contact-us", label: "contact-us" },
  { to: "/work-with-us", label: "work" },
  { to: "/career", label: "career.title" },
  { to: "/investor-relations", label: "investor-relation.title" },
];

export const RATE_NUMBERS = [10, 20, 30, 40];
export const RATE_NUMBERS_SECOND = [10, 20, 30, 40, 50];

export const SOCIAL_LINKS = [
  {
    href: 'https://www.facebook.com/people/SensCare/100066554561840/',
    imgSrc: window.location.origin + '/images/facebook.svg',
  },
  {
    href: 'https://www.linkedin.com/company/sens-care/',
    imgSrc: window.location.origin + '/images/indi.svg',
  },
  {
    href: 'https://www.instagram.com/mysenscare/',
    imgSrc: window.location.origin + '/images/twiter.svg',
  },
];

export const SOCIAL_MEDIA_ICONS = [
  { src: "/images/whitefb.svg", alt: "Facebook" },
  { src: "/images/whitein.svg", alt: "LinkedIn" },
  { src: "/images/whitetwiter.svg", alt: "Twitter" },
];


//

export const REQUIRED_FIELDS = [
  'username',
  'email',
  'password',
  'c_password',
  'first_name',
  'last_name',
  'dob',
  'phone',
  'address',
  'city',
  'zip',
  'country',
  'hearAboutUs'
];

export const REFINE_OPTIONS = [
  { label: "text-all", value: "" },
  { label: "day", value: 24 },
  { label: "days-3", value: 3 },
  { label: "days-7", value: 7 },
  { label: "days-30", value: 30 },
];

export const OPTIONS = [
  { value: "", label: "text-all" },
  { value: "0", label: "Active" },
  { value: "1", label: "Closed" },
];

export const EMAIL_REGEX = RegExp(
  /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
);
export const VALID_PASSWORD = RegExp(
  "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
);

export const SPECIAL_CHARACTERS = RegExp("(?=.*[!@#$%^&*])");
export const CAPITAL_LETTERS = RegExp("(?=.*[A-Z])");
export const SMALL_LETTERS = RegExp("^[a-zA-Z]*$");
export const NUMBERS = RegExp("(?=.*[0-9])");
export const ENG_LANGUAGE = "en";

export const MONTH_ENG_LANGUAGE = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const MONTH_SRB_LANGUAGE = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "Maj",
  "Jun",
  "Jul",
  "Avg",
  "Sep",
  "Okt",
  "Nov",
  "Dec",
];

export const PROVIDER_ITEMS = [
  "Large selection of jobs (domestically and internationally).",
  "Fast, quality and safe services.",
  "Find an ideal job.",
];

export const PARENT_ITEMS = [
  "Children's services in one place.",
  "Fast, quality and safe services.",
  "Wide selection of qualified providers (domestically and internationally).",
];

export const SERVICE = [
  {
    name: "userId",
  },
  {
    name: "recommendValue",
  },
  {
    name: "navigationValue",
  },
  {
    name: "seeService",
  },
  {
    name: "overallValue",
  },
  {
    name: "userReview",
  },
  {
    name: "otherService",
  },
];

export const HIRE_OPTIONS = [
  {
    imgSrc: "./images/nany.svg",
    altText: "Nanny icon",
    translationKey: "text-nanny",
  },
  {
    imgSrc: "./images/special.svg",
    altText: "Special education provider icon",
    translationKey: "Special education provider",
  },
  {
    imgSrc: "./images/turor.svg",
    altText: "Tutor icon",
    translationKey: "text-tutor",
  },
];

export const GUIDE_ITEMS = [
  { img: "./images/profile.svg", text: "create-profile" },
  { img: "./images/jobs.svg", text: "title-apply" },
  { img: "./images/condidate.svg", text: "search" },
  { img: "./images/team.svg", text: "Team up" },
];

export const TOOLS_ITEMS = [
  { img: "./images/review.svg", text: "review" },
  { img: "./images/rating.svg", text: "read" },
  { img: "./images/interview.svg", text: "title-conduct" },
  { img: "./images/reference.svg", text: "title-contact" },
];

export const AGENSY_CONTACTS = [
  { img: "./images/phone.svg", text: "title-cell" },
  { img: "./images/email.svg", text: "email" },
  { img: "./images/verification.svg", text: "facebook" },
  { img: "./images/criminal.svg", text: "title-criminal" },
];

export const MONTH = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const ABOUT_FIELDS = [{ name: "about" }, { name: "file_path" }];

export const CHILD_FIELDS = [
  { name: "childneedcareno" },
  { name: "childage" },
  { name: "childsocialneed" },
  { name: "yourkidallapplicable" },
  { name: "childneediep" },
  { name: "childmedicalcondition" },
  { name: "childmedicaldescription" },
  { name: "childanyallergies" },
  { name: "childallergiesdescription" },
];

export const TUTOR_FIELDS = [
  { name: "liveinnany" },
  { name: "tutorintrestedonlinecls" },
  { name: "tutorexp" },
  { name: "preferredageofprovider" },
  { name: "tutorstartdate" },
  { name: "tutorintrestedin" },
  { name: "nanyperhrrate" },
  { name: "tutorliketoteach" },
  { name: "tutorperhrrate" },
  { name: "setallapplicable" },
  { name: "setexpmethods" },
  { name: "setexpiep" },
  { name: "setyearexp" },
  { name: "sepexpwithkid" },
  { name: "sepallapplicable" },
  { name: "sepexpmethods" },
  { name: "sepworkexp" },
  { name: "seperhrrate" },
  { name: "seterhrrate" },
];

export const SCHEDULE_FIELDS = [
  { name: "fulltime" },
  { name: "beforeschool" },
  { name: "afterschool" },
  { name: "weekends" },
  { name: "overnight" },
];

export const REQUIREMENTS_FIELDS = [
  { name: "candidatespeaks" },
  { name: "cookingforkids" },
  { name: "lighthousework" },
  { name: "childtransportation" },
  { name: "occasionaltraveling" },
  { name: "workingabroad" },
  { name: "preferredcountry" },
  { name: "preferredcity" },
];

export const JOB_FIELDS = [
  { name: "jobs" },
  { name: "jobs_description" },
  { name: "jobs2" },
  { name: "jobs_description2" },
];

export const CATEGORY_RESOURCES = [
  {
    category: "Savezi",
  },
  {
    category: "Udruženja",
  },
  {
    category: "Nevladine organizacije",
  },
  {
    category: "Zakoni, propisi i priručnici",
  },
];

export const DOCUMENT_TYPES = [
  {
    key: "recommendationsfile",
    originalKey: "recommendationsfile_original",
    updateKey: "recommendationsfile_update_at",
    label: "recommendation-underline",
    deleteLabel: "file-recommend",
  },
  {
    key: "cv",
    originalKey: "cv_original",
    updateKey: "cv_updated_at",
    label: "text-cv-underline",
    deleteLabel: "text-cv",
  },
  {
    key: "qualificationscertificatesdoc",
    originalKey: "qualificationscertificatesdoc_original",
    updateKey: "qualificationscertificatesdoc_updated_at",
    label: "certificate-underline",
    deleteLabel: "qualificationscertificatesdoc",
  },
  {
    key: "backgrounddoc",
    originalKey: "backgrounddoc_original",
    updateKey: "backgrounddoc_updated_at",
    label: "background-check-underline",
    deleteLabel: "backgrounddoc",
  },
];

export const STEP_NANNY = [
  { name: "nanyyearexp" },
  { name: "smoke" },
  { name: "carorlicence" },
  { name: "cooking" },
  { name: "lighthousework" },
  { name: "traveling" },
  { name: "workingabroad" },
  { name: "nanyperhrrate" },
  { name: "nanynewbornexp" },
  { name: "livewithfamily" },
  { name: "nanyexpwithkid" },
];
export const STEP_EXP = [
  { name: "setyearexp" },
  { name: "setexpmethods" },
  { name: "seterhrrate" },
  { name: "setexpiep" },
  { name: "setallapplicable" },
];
export const STEP_RATE = [
  { name: "seperhrrate" },
  { name: "sepexpmethods" },
  { name: "sepworkexp" },
  { name: "sepexpwithkid" },
];
export const STEP_TUTOR = [
  { name: "tutorexp" },
  { name: "tutorperhrrate" },
  { name: "tutorliketoteach" },
  { name: "tutorintrestedonlinecls" },
  { name: "sepexpwithkid" },
];

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Modal from "react-bootstrap/Modal";

import { REQUIRED_FIELDS } from "../../utils/variables";
import { useFormStore } from "../../store/useFormStore";
import { validateField } from "../../utils/helperValidations";
import { fetchRegisterUser } from "../../api/registerApi";
import { SignupForm } from "./SignupForm";

export function SignupPage() {
  const { t } = useTranslation();

  const [category, setCategory] = useState("");
  const [describe, setDescribe] = useState({});
  const [step, setStep] = useState("stap1");
  const [showModal, setShowModal] = useState(false);
  const [isSelect, setIsSelect] = useState("");

  const {
    formLogin,
    setFormLogin,
    errorField,
    setErrorField,
    resetFormLogin,
    resetErrorField,
  } = useFormStore();

  useEffect(() => {
    return () => {
      resetFormLogin();
      resetErrorField();
    };
  }, []);

  const slugData = useParams();
  const data = slugData.refral;

  useState(() => {
    localStorage.setItem("refral", data);
  }, [data]);

  const validationField = (e) => {
    const { name, value } = e.target;

    const newErrors = validateField(name, value, formLogin, handleShowModal);

    setFormLogin({ ...formLogin, [name]: value });
    setErrorField({ ...errorField, ...newErrors });
  };

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const handleDescribeSelect = (a, b) => {
    if (Object.keys(describe).length < 2) {
      setDescribe({ ...describe, [a]: b });
    } else {
      delete describe[a];
      setDescribe({ ...describe });
    }
  };

  const handleSubmit = async () => {
    if (REQUIRED_FIELDS.every((field) => formLogin[field])) {
      try {
        const result = await fetchRegisterUser(formLogin, null, category, describe);

        if (result.success) {
          setTimeout(() => {
            window.location.href =
              category === "parents"
                ? "/parent_thankyou"
                : "/providers_thankyou";
          }, 2010);
        }
      } catch (error) {
        console.error("Error during form submission:", error);
      }
    } else {
      console.error("All fields are required.");
    }
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
      />
      <div className="container-fluid">
        <div className="container">
          <div className="signup_section">
            <div className="header_sign">
              <ul>
                <li>
                  <Link to="/">
                    <img
                      src={window.location.origin + "/images/left_arrow.svg"}
                    />
                    <span>{t("text-home")}</span>
                  </Link>
                </li>
                <li className="login">
                  <span>{t("Already a member?")}</span>
                  <Link to="/login">{t("log-in")} </Link>
                </li>
              </ul>
            </div>

            <div className={"more_about " + (step == "stap1" ? "" : "none")}>
              <img src={window.location.origin + "/images/sign_logo.svg"} />
              <h2>{t("Tell us more about your needs")}</h2>
              <ul>
                <li
                  className={category == "parents" ? "active" : ""}
                  onClick={(e) => {
                    setCategory("parents");
                    setIsSelect("");
                  }}
                >
                  <span className={isSelect == "" ? "" : "bordererror"}>
                    <img
                      src={
                        category == "parents"
                          ? window.location.origin +
                            "/images/sign_service_fill.svg"
                          : window.location.origin + "/images/sign_service.svg"
                      }
                    />
                    <h4>{t("common.title-service")}</h4>
                  </span>
                  <p>
                    {t(
                      "start"
                    )}
                  </p>
                </li>
                <li
                  className={category == "provider" ? "active" : ""}
                  onClick={(e) => {
                    setCategory("provider");
                    setIsSelect("");
                  }}
                >
                  <span className={isSelect == "" ? "" : "bordererror"}>
                    <img
                      src={
                        category == "provider"
                          ? window.location.origin +
                            "/images/sign_search_fill.svg"
                          : window.location.origin + "/images/sign_search.svg"
                      }
                    />
                    <h4>{t("title-job")}</h4>
                  </span>
                  <p>{t("Create a free profile and search for jobs")}</p>
                </li>
              </ul>
              <div className="process_guid ">
                <p>
                  {t(
                    "steps-become-senscare"
                  )}
                </p>
                <ul>
                  <li>
                    <Link to="#">
                      <img
                        src={window.location.origin + "/images/profile.svg"}
                      />
                      <span>{t("Create your profile")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <img src={window.location.origin + "/images/jobs.svg"} />
                      <span>{t("common.title-apply")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <img
                        src={window.location.origin + "/images/condidate.svg"}
                      />
                      <span>{t("search-candidates")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <img src={window.location.origin + "/images/team.svg"} />
                      <span>{t("Team up")}</span>
                    </Link>
                  </li>
                </ul>
              </div>
              <button
                onClick={(e) => {
                  category != ""
                    ? category == "parents"
                      ? (window.location.href = "/signup_Parents")
                      : (window.location.href = "/signup_provider")
                    : setIsSelect("Please select your needs.");
                }}
              >
                {t("text-next")}
              </button>
            </div>
            <div
              className={
                "describe more_about " + (step == "stap2" ? "" : "none")
              }
            >
              <img src={window.location.origin + "/images/sign_logo.png"} />
              <h2>{t("Tell us more about yourself")}</h2>
              <p>
                {t("select-two-profession")}
                <br />
                {t("signup-change-choice")}
              </p>
              <div className="process_guid ">
                <ul>
                  <li
                    className={
                      describe && describe.tab1 == "nany" ? "active" : ""
                    }
                    onClick={(e) => handleDescribeSelect("tab1", "nany")}
                  >
                    <Link to="">
                      <img
                        src={
                          describe && describe.tab1 == "nany"
                            ? "./images/nanny_fill.png"
                            : "./images/nanny.png"
                        }
                      />
                      <span>{t("text-nanny")}</span>
                    </Link>
                  </li>
                  <li
                    className={
                      describe && describe.tab2 == "education_teacher"
                        ? "active"
                        : ""
                    }
                    onClick={(e) =>
                      handleDescribeSelect("tab2", "education_teacher")
                    }
                  >
                    <Link to="">
                      <img
                        src={
                          describe && describe.tab2 == "education_teacher"
                            ? "./images/teacher_fill.png"
                            : "./images/teacher.png"
                        }
                      />
                      <span>{t("education-teacher")}</span>
                    </Link>
                  </li>
                  <li
                    className={
                      describe && describe.tab3 == "paraprofessional"
                        ? "active"
                        : ""
                    }
                    onClick={(e) =>
                      handleDescribeSelect("tab3", "paraprofessional")
                    }
                  >
                    <Link to="">
                      <img
                        src={
                          describe && describe.tab3 == "paraprofessional"
                            ? "./images/education_fill.png"
                            : "./images/education.png"
                        }
                      />
                      <span>{t("education-paraprofessional")}</span>
                    </Link>
                  </li>
                  <li
                    className={
                      describe && describe.tab4 == "tutor" ? "active" : ""
                    }
                    onClick={(e) => handleDescribeSelect("tab4", "tutor")}
                  >
                    
                    <Link to="">
                      <img
                        src={
                          describe && describe.tab4 == "tutor"
                            ? "./images/tutor_fill.png"
                            : "./images/tutor.png"
                        }
                      />
                      <span>{t("text-tutor")}</span>
                    </Link>
                  </li>
                </ul>
              </div>
              <button className="back_sign" onClick={(e) => setStep("stap1")}>
                {t("back")}
              </button>
              <button
                onClick={(e) => {
                  Object.keys(describe).length >= 1
                    ? setStep("stap3")
                    : console.log("Please select yourself.");
                }}
              >
                {t("text-next")}
              </button>
            </div>

            <SignupForm
              step={step}
              errorField={errorField}
              validationField={validationField}
              handleSubmit={handleSubmit}
              setStep={setStep}
            />
            <div
              className={
                "signupdetail " +
                (step == "stap1" || step == "stap2" || step == "stap3"
                  ? ""
                  : "none")
              }
            >
              {category == "provider" ? (
                <>
                  <h2>
                    {t("sign-up")}
                    <br /> {t("for FREE!")}
                  </h2>
                  <ul>
                    <li>
                      <img
                        src={
                          window.location.origin + "/images/thanks_right.svg"
                        }
                      />
                      <span>{t("Create your profile.")}</span>
                    </li>
                    <li>
                      <img
                        src={
                          window.location.origin + "/images/thanks_right.svg"
                        }
                      />
                      <span>
                        {t("Browse and apply for child services jobs.")}
                      </span>
                    </li>
                    <li>
                      <img
                        src={
                          window.location.origin + "/images/thanks_right.svg"
                        }
                      />
                      <span>{t("Find an ideal candidate.")}</span>
                    </li>
                  </ul>
                </>
              ) : (
                ""
              )}
              {category == "parents" ? (
                <>
                  <h2>
                    {t("sign-up")}
                    <br /> {t("for FREE!")}
                  </h2>
                  <ul>
                    <li>
                      <img
                        src={
                          window.location.origin + "/images/thanks_right.svg"
                        }
                      />
                      <span>{t("Add your job post.")}</span>
                    </li>
                    <li>
                      <img
                        src={
                          window.location.origin + "/images/thanks_right.svg"
                        }
                      />
                      <span>{t("Browse a candidate's profile.")}</span>
                    </li>
                    <li>
                      <img
                        src={
                          window.location.origin + "/images/thanks_right.svg"
                        }
                      />
                      <span>{t("Find an ideal candidate")}</span>
                    </li>
                  </ul>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Body>
          <div className="promocode_content younger">
            <Link to="" onClick={handleCloseModal}>
              +
            </Link>
            <h5>{t("Thank you!")}</h5>
            <p>{t("ThankYouFor")}</p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

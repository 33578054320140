import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { api } from "../../urls";
import { DATA_COUNTRY } from "../../utils/data";
import DatePicker from "react-datepicker";
import { FacebookProvider, LoginButton } from "react-facebook";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { Calendar } from "../../components/common/Calendar";
import { LinkedinContent } from "../../components/common/LinkedinContent";
import { Footer } from "../../components/common/Footer";
import { Header } from "../../components/common/Header";
import { DateIcon } from "../../assets/image/DateIcon";
import { BanCarIcon } from "../../assets/image/BanCarIcon";
import { CarIcon } from "../../assets/image/CarIcon";
import { BanPlaneIcon } from "../../assets/image/BanPlaneIcon";
import { PlaneIcon } from "../../assets/image/PlaneIcon";
import { BanHouseIcon } from "../../assets/image/BanHouseIcon";
import { HouseIcon } from "../../assets/image/HouseIcon";
import { BanCookIcon } from "../../assets/image/BanCookIcon";
import { CookIcon } from "../../assets/image/CookIcon";
import { FlagIcon } from "../../assets/image/FlagIcon";
import { BanSmokeIcon } from "../../assets/image/BanSmokeIcon";
import { SmokeIcon } from "../../assets/image/SmokeIcon";
import { ArrowUpIcon } from "../../assets/image/ArrowUpIcon";
import { UploadIcon } from "../../assets/image/UploadIcon";
import { FacebookIcon } from "../../assets/image/FacebookIcon";
import { MarkIcon } from "../../assets/image/MarkIcon";
import { RATE_NUMBERS, RATE_NUMBERS_SECOND } from "../../utils/variables";

export function CreateProviderPage() {
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const [detail, setDetail] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);
  const [mobile, setMobile] = useState(0);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [range, setRange] = useState(0);
  const [workExperience, setWorkExperience] = useState(0);
  const [experience, setExperience] = useState(0);
  const [tutorExperience, setTutorExperience] = useState(0);
  const [isCheck, setIsCheck] = useState(true);
  const [children, setChildren] = useState("");
  const [otp, setOtp] = useState("");
  const [childrenCount, setChildrenCount] = useState("");
  const [calendarType, setcalenderType] = useState(0);
  const [step, setStep] = useState(1);
  const [process, setProcess] = useState(14);
  const [errorField, setErrorField] = useState({
    phone: "",
  });
  const [rate, setRate] = useState({
    min: 0,
    max: 0,
  });
  const [tutoRate, setTutorRate] = useState({
    min: 0,
    max: 0,
  });
  const [educationRate, setEducationRate] = useState({
    min: 0,
    max: 0,
  });
  const [professionRate, setProfessionRate] = useState({
    min: 0,
    max: 0,
  });
  const [option, setOption] = useState({
    smoke: "",
    licence: "",
    kids: "",
    housework: "",
    family: "",
  });

  const handleSetStep = (e) => {
    setStep(e);
    e == 1
      ? setProcess(14)
      : e == 2
        ? setProcess(29)
        : e == 3
          ? setProcess(43)
          : e == 4
            ? setProcess(57)
            : e == 5
              ? setProcess(71)
              : e == 6
                ? setProcess(86)
                : e == 7
                  ? setProcess(100)
                  : setProcess(10);
  };
  const [selectCat, setSelectCat] = useState([]);
  const [qualifications, setQualifications] = useState({
    English: "",
    Serbian: "",
    Mathematics: "",
    Physics: "",
    Chemistry: "",
    Other: "",
  });
  const [sepallapplicable, setsepallapplicable] = useState([]);
  const [setallapplicable2, setsetallapplicable2] = useState([]);
  const [childrenNanny, setChildrenNanny] = useState([]);
  const [childrenAge, setChildrenAge] = useState([]);
  const [allapplicable, setallapplicable] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [isCatOpen, setIsCatOpen] = useState(true);
  const [today, settoday] = useState(new Date());

  const handleCloselogin_first = () => {
    setShowLoginModal(false);
  };

  const handleCalendarData = (name, e) => {
    setDetailProvider({ ...detailProvider, [name]: e });
  };

  const handleRedirect = () => {
    window.location.href = "/search-parents";
  };

  const handleLanguageSelect = (data) => {
    let sum = false;
    let x = data.substr(data.lastIndexOf("\\") + 1).split("_")[0];
    languages.map((e, index) => {
      if (e.name.substr(e.name.lastIndexOf("\\") + 1).split("_")[0] == x) {
        sum = true;
        e.name = data;
      }
    });
    if (sum == false) {
      languages.push({ name: data });
    }
    setTimeout(() => {
      setLanguages([...languages]);
    }, 500);
  };

  const selectoption3 = (data) => {
    let sum = false;
    allapplicable.map((e, index) => {
      if (e.name == data) {
        sum = true;
        allapplicable.splice(index, 1);
      }
    });
    if (sum == false) {
      allapplicable.push({ name: data });
    }
    setTimeout(() => {
      setallapplicable([...allapplicable]);
    }, 500);
  };

  const selectoption2 = (data) => {
    let sum = false;
    childrenAge.map((e, index) => {
      if (e.name == data) {
        sum = true;
        childrenAge.splice(index, 1);
      }
    });
    if (sum == false) {
      childrenAge.push({ name: data });
    }
    setTimeout(() => {
      setChildrenAge([...childrenAge]);
    }, 500);
  };

    const selectoption = (data) => {
        let updatedSelectCat = [...selectCat]; // Create a copy of the array

        const index = updatedSelectCat.findIndex((e) => e.name === data);

        if (index !== -1) {
            // If the item already exists, remove it
            updatedSelectCat.splice(index, 1);
        } else {
          updatedSelectCat.push({ name: data });
        }

        // Update the state with the new array
        setSelectCat(updatedSelectCat);
    };

  const selectoption4 = (data) => {
    let sum = false;
    childrenNanny.map((e, index) => {
      if (e.name == data) {
        sum = true;
        childrenNanny.splice(index, 1);
      }
    });
    if (sum == false) {
      childrenNanny.push({ name: data });
    }
    setTimeout(() => {
      setChildrenNanny([...childrenNanny]);
    }, 500);
  };

  const [tutorTime, setTutorTime] = useState({
    fulltime: "",
    parttime: "",
    occasionally: "",
  });

  const selectoption5 = (data) => {
    let sum = false;
    sepallapplicable.map((e, index) => {
      if (e.name == data) {
        sum = true;
        sepallapplicable.splice(index, 1);
      }
    });
    if (sum == false) {
      sepallapplicable.push({ name: data });
    }
    setTimeout(() => {
      setsepallapplicable([...sepallapplicable]);
    }, 500);
  };
  const selectoption6 = (data) => {
    let sum = false;
    setallapplicable2.map((e, index) => {
      if (e.name == data) {
        sum = true;
        setallapplicable2.splice(index, 1);
      }
    });
    if (sum == false) {
      setallapplicable2.push({ name: data });
    }
    setTimeout(() => {
      setsetallapplicable2([...setallapplicable2]);
    }, 500);
  };
  const handleElementById = (e, x) => {
    if (isCatOpen) {
      document.getElementById(e).style.display = "block";
      document.getElementById(x).style.display = "block";
      setIsCatOpen(false);
    } else {
      document.getElementById(e).style.display = "none";
      document.getElementById(x).style.display = "none";
      setIsCatOpen(true);
    }
  };
  const [verify, setVerify] = useState("");
  const [isCode, setIsCode] = useState(true);

  const codeselect = () => {
    if (isCode) {
      setIsCode(false);
    } else {
      setIsCode(true);
    }
  };
  const [contactCode, setContactCode] = useState({
    code: "",
    flag: "",
  });
  const [certificates, setCertificates] = useState({
    qualificationscertificatesname: "",
    qualificationscertificatesname2: "",
    qualificationscertificatesname3: "",
    qualificationscertificatesname4: "",
  });
  const [methods, setMethods] = useState({
    setexpmethods: "",
    setexpmethods2: "",
    setexpmethods3: "",
    setexpmethods4: "",
  });
  const [detailProvider, setDetailProvider] = useState({
    about: "",
    profile_headline: "",
    video: "",
    file_path: "",
    educationlevel: "",
    school: "",
    yearawarded: "",
    recommendationsfile: "",
    qualificationscertificatesname: "",
    qualificationscertificatesdoc: "",
    nanynewbornexp: "",
    nanyyearexp: "",
    setareaexperties: "",
    setexpmethods: "",
    setexpiep: "",
    setyearexp: range,
    setallapplicable: setallapplicable2,
    nanyexpwithkid: "",
    tutorexp: tutorExperience,
    sepexpwithkid: "",
    nanyallapplicable: allapplicable,
    sepallapplicable: sepallapplicable,
    sepexpmethods: "",
    sepworkexp: workExperience,
    nanyperhrrate: rate.min,
    tutorliketoteach: qualifications,
    tutorintrestedonlinecls: "",
    tutorintrestedinschool: "",
    tutorallapplicable: selectCat,
    tutorperhrrate: tutoRate.min,
    tutorworkwithnochild: "",
    tutorprefchildage: "",
    tutorstartdate: "",
    tutorintrestedin: "",
    nanyhrrate: rate.min,
    nanyintrestedinschool: "",
    nanyworkwithnochild: childrenCount,
    nanystartdate: "",
    nanyprefchildage: childrenNanny,
    nanyintrestedin: "",
    fulltime: {},
    beforeschool: {},
    afterschool: {},
    overnight: {},
    weekends: {},
    countrycode: "",
    englishlevel: "",
    italianlevel: "",
    spanishlevel: "",
    germanlevel: "",
    chineselevel: "",
    frenchlevel: "",
    otherlangname: "",
    otherlevel: "",
    phone: "",
    facebookverify: "",
    twitterverify: "",
    linkdinverify: "",
    instaverify: "",
    backgrounddoc: "",
    backgroundstatus: "",
    smoke: option.smoke,
    carorlicence: option.licence,
    cooking: option.kids,
    lighthousework: option.housework,
    traveling: option.family,
    workingabroad: "",
    livewithfamily: "",
    anyallergies: "",
    anyallergiesdescription: "",
    medicalcondition: "",
    medicalconditiondescription: "",
    plateformsocialmedia: "Yes",
    seperhrrate: "",
    seterhrrate: "",
    yearofexpasteacher: 0,
  });

  const [error, setError] = useState({
    about: "",
    profile_headline: "",
    video: "",
    file_path: "",
    educationlevel: "",
    school: "",
    yearawarded: "",
    recommendationsfile: "",
    qualificationscertificatesname: "",
    cv: "",
    qualificationscertificatesdoc: "",
    nanynewbornexp: "",
    nanyyearexp: "",
    setareaexperties: "",
    setexpmethods: "",
    setexpiep: "",
    setyearexp: range,
    setallapplicable: setallapplicable2,
    nanyexpwithkid: "",
    tutorexp: tutorExperience,
    sepexpwithkid: "",
    nanyallapplicable: allapplicable,
    sepallapplicable: sepallapplicable,
    sepexpmethods: "",
    sepworkexp: workExperience,
    nanyperhrrate: rate.min,
    tutorliketoteach: qualifications,
    tutorintrestedonlinecls: "",
    tutorintrestedinschool: "",
    tutorallapplicable: selectCat,
    tutorperhrrate: tutoRate.min,
    tutorworkwithnochild: children,
    tutorprefchildage: childrenAge,
    tutorstartdate: "",
    tutorintrestedin: "",
    nanyhrrate: rate.min,
    nanyintrestedinschool: "",
    nanyworkwithnochild: childrenCount,
    nanystartdate: "",
    nanyprefchildage: childrenNanny,
    nanyintrestedin: "",
    fulltime: {},
    beforeschool: {},
    afterschool: {},
    overnight: {},
    weekends: {},
    englishlevel: "",
    italianlevel: "",
    spanishlevel: "",
    germanlevel: "",
    chineselevel: "",
    frenchlevel: "",
    otherlangname: "",
    otherlevel: "",
    phone: "",
    backgrounddoc: "",
    backgroundstatus: "",
    smoke: option.smoke,
    carorlicence: option.licence,
    cooking: option.kids,
    lighthousework: option.housework,
    traveling: option.family,
    workingabroad: "",
    livewithfamily: "",
    anyallergies: "",
    anyallergiesdescription: "",
    medicalcondition: "",
    medicalconditiondescription: "",
    plateformsocialmedia: "",
    seperhrrate: "",
    seterhrrate: "",
    yearofexpasteacher: "",
  });

  const [failed, setFailed] = useState(0);

  const [refresh, setRefresh] = useState("");
  const [isLavelo, setIsLavelo] = useState(false);
  const [isAwarded, setIsAwarded] = useState(false);

  const fetchProviderData = (a) => {
    setIsUpdate(true);
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    const formdata = new FormData();
    formdata.append("user_id", localStorage.getItem("id"));
    formdata.append("about", detailProvider.about);
    formdata.append("profile_headline", detailProvider.profile_headline);
    detailProvider.video.name
      ? formdata.append("video", detailProvider.video)
      : formdata.append("about", detailProvider.about);
    detailProvider.file_path.name
      ? formdata.append("file_path", detailProvider.file_path)
      : formdata.append("about", detailProvider.about);
    formdata.append("educationlevel", detailProvider.educationlevel);
    formdata.append("school", detailProvider.school);
    formdata.append("yearawarded", detailProvider.yearawarded);
    detailProvider.recommendationsfile.name
      ? formdata.append(
          "recommendationsfile",
          detailProvider.recommendationsfile
        )
      : formdata.append("about", detailProvider.about);
    formdata.append(
      "qualificationscertificatesname[]",
      certificates.qualificationscertificatesname
    );
    formdata.append(
      "qualificationscertificatesname[]",
      certificates.qualificationscertificatesname2
    );
    formdata.append(
      "qualificationscertificatesname[]",
      certificates.qualificationscertificatesname3
    );
    formdata.append(
      "qualificationscertificatesname[]",
      certificates.qualificationscertificatesname4
    );
    detailProvider?.cv?.name
      ? formdata.append("cv", detailProvider.cv)
      : formdata.append("about", detailProvider.about);
    detailProvider.qualificationscertificatesdoc.name
      ? formdata.append(
          "qualificationscertificatesdoc[]",
          detailProvider.qualificationscertificatesdoc
        )
      : formdata.append("about", detailProvider.about);
    formdata.append("nanynewbornexp", detailProvider.nanynewbornexp);
    formdata.append("nanyyearexp", experience);
    formdata.append("setareaexperties", detailProvider.setareaexperties);
    formdata.append("setexpmethods[]", JSON.stringify(methods));
    formdata.append("setexpiep", detailProvider.setexpiep);
    formdata.append("setyearexp", range);
    formdata.append("setallapplicable", JSON.stringify(setallapplicable2));
    formdata.append("nanyexpwithkid", detailProvider.nanyexpwithkid);
    formdata.append("tutorexp", tutorExperience);
    formdata.append("sepexpwithkid", detailProvider.sepexpwithkid);
    formdata.append("nanyallapplicable", JSON.stringify(allapplicable));
    formdata.append("sepallapplicable", JSON.stringify(sepallapplicable));
    formdata.append("sepexpmethods", detailProvider.sepexpmethods);
    formdata.append("sepworkexp", workExperience);
    formdata.append("countrycode", contactCode.code);
    formdata.append("nanyperhrrate", rate.min + "-" + rate.max);
    formdata.append("tutorliketoteach", JSON.stringify(qualifications));
    formdata.append(
      "tutorintrestedonlinecls",
      detailProvider.tutorintrestedonlinecls
    );
    formdata.append(
      "tutorintrestedinschool",
      detailProvider.tutorintrestedinschool
    );
    formdata.append("tutorallapplicable", JSON.stringify(selectCat));
    formdata.append("tutorperhrrate", tutoRate.min + "-" + tutoRate.max);
    formdata.append("tutorworkwithnochild", children);
    formdata.append("tutorprefchildage", JSON.stringify(childrenAge));
    formdata.append("tutorstartdate", detailProvider.tutorstartdate);
    formdata.append("tutorintrestedin", detailProvider.tutorintrestedin);
    formdata.append("nanyhrrate", rate.min + "-" + rate.max);
    formdata.append(
      "nanyintrestedinschool",
      detailProvider.nanyintrestedinschool
    );
    formdata.append("nanyworkwithnochild", childrenCount);
    formdata.append("nanystartdate", detailProvider.nanystartdate);
    formdata.append("nanyprefchildage", JSON.stringify(childrenNanny));
    formdata.append("nanyintrestedin", detailProvider.nanyintrestedin);
    formdata.append("fulltime", JSON.stringify(detailProvider.fulltime));
    formdata.append(
      "beforeschool",
      JSON.stringify(detailProvider.beforeschool)
    );
    formdata.append("afterschool", JSON.stringify(detailProvider.afterschool));
    formdata.append("overnight", JSON.stringify(detailProvider.overnight));
    formdata.append("weekends", JSON.stringify(detailProvider.weekends));
    formdata.append("englishlevel", detailProvider.englishlevel);
    formdata.append("italianlevel", detailProvider.italianlevel);
    formdata.append("spanishlevel", detailProvider.spanishlevel);
    formdata.append("germanlevel", detailProvider.germanlevel);
    formdata.append("chineselevel", detailProvider.chineselevel);
    formdata.append("frenchlevel", detailProvider.frenchlevel);
    formdata.append("otherlangname", detailProvider.otherlangname);
    formdata.append("otherlevel", detailProvider.otherlevel);
    detailProvider.backgrounddoc.name
      ? formdata.append("backgrounddoc", detailProvider.backgrounddoc)
      : formdata.append("about", detailProvider.about);
    formdata.append("backgroundstatus", detailProvider.backgroundstatus);
    formdata.append("smoke", option.smoke);
    formdata.append("carorlicence", option.licence);
    formdata.append("cooking", option.kids);
    formdata.append("lighthousework", option.housework);
    formdata.append("traveling", option.family);
    formdata.append("workingabroad", detailProvider.workingabroad);
    formdata.append("livewithfamily", detailProvider.livewithfamily);
    formdata.append("anyallergies", detailProvider.anyallergies);
    formdata.append(
      "anyallergiesdescription",
      detailProvider.anyallergiesdescription
    );
    formdata.append("medicalcondition", detailProvider.medicalcondition);
    formdata.append(
      "medicalconditiondescription",
      detailProvider.medicalconditiondescription
    );
    formdata.append(
      "plateformsocialmedia",
      detailProvider.plateformsocialmedia
    );
    formdata.append("facebookverify", detailProvider.facebookverify);
    formdata.append("twitterverify", detailProvider.twitterverify);
    formdata.append("linkdinverify", detailProvider.linkdinverify);
    formdata.append("instaverify", detailProvider.instaverify);
    formdata.append("step", step + 1);
    formdata.append("seterhrrate", educationRate.min + "-" + educationRate.max);
    formdata.append(
      "seperhrrate",
      professionRate.min + "-" + professionRate.max
    );
    formdata.append("yearofexpasteacher", detailProvider.yearofexpasteacher);
    if (a == true) {
      formdata.append("profilecompletestatus", "1");
    }

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(api + "/api/v1/updateprovider", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success == true && step == 7) {
          setShowLoginModal(true);
        }
        setIsUpdate(false);
      })
      .catch((error) => {
        setIsUpdate(false);
      });
  };

  const profile_data = () => {
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(api + "/api/v1/details", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setDetail(result.data);
        setTimeout(() => {
          const x = Object.keys(detailProvider).forEach(function (key) {
            detailProvider[key] =
              result.data[key] != null ? result.data[key] : "";
          });
          setRefresh(result.data);
        }, 500);
        setTimeout(() => {
          setDetailProvider({
            ...detailProvider,
            plateformsocialmedia:
              detailProvider.plateformsocialmedia != ""
                ? detailProvider.plateformsocialmedia
                : "Yes",
          });
        }, 700);
        setExperience(
          result.data.nanyyearexp != null ? result.data.nanyyearexp : 0
        );
        setRange(result.data.setyearexp != null ? result.data.setyearexp : 0);
        setWorkExperience(
          result.data.sepworkexp != null ? result.data.sepworkexp : 0
        );
        setTutorExperience(
          result.data.tutorexp != null ? result.data.tutorexp : 0
        );
        setallapplicable(
          result.data.nanyallapplicable != null
            ? result.data.nanyallapplicable
            : []
        );
        setRate({
          min:
            result.data.nanyperhrrate != null
              ? result.data.nanyperhrrate
                  .substr(result.data.nanyperhrrate.lastIndexOf("\\") + 1)
                  .split("-")[0]
              : 0,
          max:
            result.data.nanyperhrrate != null
              ? result.data.nanyperhrrate
                  .substr(result.data.nanyperhrrate.lastIndexOf("\\") + 1)
                  .split("-")[1]
              : 0,
        });
        setTutorRate({
          min:
            result.data.tutorperhrrate != null
              ? result.data.tutorperhrrate
                  .substr(result.data.tutorperhrrate.lastIndexOf("\\") + 1)
                  .split("-")[0]
              : 0,
          max:
            result.data.tutorperhrrate != null
              ? result.data.tutorperhrrate
                  .substr(result.data.tutorperhrrate.lastIndexOf("\\") + 1)
                  .split("-")[1]
              : 0,
        });
        setEducationRate({
          min:
            result.data.seterhrrate != null
              ? result.data.seterhrrate
                  .substr(result.data.seterhrrate.lastIndexOf("\\") + 1)
                  .split("-")[0]
              : 0,
          max:
            result.data.seterhrrate != null
              ? result.data.seterhrrate
                  .substr(result.data.seterhrrate.lastIndexOf("\\") + 1)
                  .split("-")[1]
              : 0,
        });
        setProfessionRate({
          min:
            result.data.seperhrrate != null
              ? result.data.seperhrrate
                  .substr(result.data.seperhrrate.lastIndexOf("\\") + 1)
                  .split("-")[0]
              : 0,
          max:
            result.data.seperhrrate != null
              ? result.data.seperhrrate
                  .substr(result.data.seperhrrate.lastIndexOf("\\") + 1)
                  .split("-")[1]
              : 0,
        });
        setChildrenCount(
          result.data.nanyworkwithnochild != null
            ? result.data.nanyworkwithnochild
            : ""
        );
        setChildrenAge(
          result.data.tutorprefchildage != null
            ? result.data.tutorprefchildage
            : []
        );
        setChildren(
          result.data.nanyworkwithnochild != null
            ? result.data.tutorworkwithnochild
            : ""
        );
        setSelectCat(
          result.data.tutorallapplicable != null
            ? result.data.tutorallapplicable
            : []
        );
        setChildrenNanny(
          result.data.nanyprefchildage != null
            ? result.data.nanyprefchildage
            : []
        );
        setsepallapplicable(
          result.data.sepallapplicable != null
            ? result.data.sepallapplicable
            : []
        );
        setsetallapplicable2(
          result.data.setallapplicable != null
            ? result.data.setallapplicable
            : []
        );
        setQualifications(
          result.data.tutorliketoteach != null
            ? result.data.tutorliketoteach
            : {}
        );
        setOption({
          smoke: result.data.smoke ? result.data.smoke : "",
          licence: result.data.carorlicence ? result.data.carorlicence : "",
          kids: result.data.cooking ? result.data.cooking : "",
          housework: result.data.lighthousework
            ? result.data.lighthousework
            : "",
          family: result.data.traveling ? result.data.traveling : "",
        });

        setMethods(
          result.data.setexpmethods
            ? JSON.parse(result.data.setexpmethods)
            : methods
        );
        setLanguages(
          result.data.englishlevel != null
            ? [{ name: result.data.englishlevel }]
            : result.data.frenchlevel != null
              ? [{ name: result.data.frenchlevel }]
              : result.data.italianlevel != null
                ? [{ name: result.data.italianlevel }]
                : result.data.germanlevel != null
                  ? [{ name: result.data.germanlevel }]
                  : result.data.spanishlevel != null
                    ? [{ name: result.data.spanishlevel }]
                    : result.data.chineselevel != null
                      ? [{ name: result.data.chineselevel }]
                      : result.data.otherlevel != null
                        ? [{ name: result.data.otherlevel }]
                        : []
        );
        if (
          result.data.qualificationscertificatesname != null &&
          result.data.qualificationscertificatesname[0]
        ) {
          const iterator = result.data.qualificationscertificatesname.values();
          const y = {};

          for (let elements of iterator) {
            if (elements == "First Aid") {
              y = { ...y, qualificationscertificatesname: elements };
            } else if (elements == "Montessori") {
              y = { ...y, qualificationscertificatesname2: elements };
            } else if (elements == "Board Certified Nurse") {
              y = { ...y, qualificationscertificatesname3: elements };
            } else if (
              elements != "First Aid" &&
              elements != "Montessori" &&
              elements != "Board Certified Nurse"
            ) {
              setCertificates({
                ...certificates,
                ...y,
                qualificationscertificatesname4:
                  elements != null ? elements : "",
              });
            }
          }
        }
        setDetail(result.data);
        setStep(
          result.data.step != null
            ? parseInt(result.data.step) <= 7
              ? result.data.plateformsocialmedia
                ? 1
                : parseInt(result.data.step)
              : 1
            : 1
        );
      })
      .catch((error) => console.log("error", error));
  };

  const handleVirifyMobile = () => {
    if (detailProvider.phone.length >= 7) {
      setIsUpdate(true);
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );
      myHeaders.append("Content-Type", "application/json");
      const raw = JSON.stringify({
        phonenoforverify:
          contactCode.code != ""
            ? contactCode.code + detailProvider.phone
            : "+" + detailProvider.countrycode + detailProvider.phone,
        phone: detailProvider.phone,
        countrycode:
          contactCode.code != ""
            ? contactCode.code
            : detailProvider.countrycode,
      });
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      fetch(api + "/api/v1/sendotp", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          setIsUpdate(false);
        })
        .catch((error) => {
          setIsUpdate(false);
        });
    } else {
      setErrorField({
        ...errorField,
        phone: "error",
      });
    }
  };

  const handleVerifyOtp = () => {
    if (otp.length > 3) {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );
      myHeaders.append("Content-Type", "application/json");
      const raw = JSON.stringify({
        phonetoken: otp,
        phone: detailProvider.phone,
        countrycode:
          contactCode.code != ""
            ? contactCode.code
            : detailProvider.countrycode,
      });
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      fetch(api + "/api/v1/verifyotp", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          setVerify(result.success ? "done" : "");
          setFailed(failed + 1);
          result.success == true
            ? (document.getElementById("success").style.display = "block")
            : (document.getElementById("success").style.display = "none");
          result.message == "failed" && failed == 0
            ? (document.getElementById("success4").style.display = "block")
            : (document.getElementById("success4").style.display = "none");
          result.message == "failed" && failed == 1
            ? (document.getElementById("success3").style.display = "block")
            : (document.getElementById("success3").style.display = "none");
        })
        .catch((error) => console.log("error", error));
    } else {
      setErrorField({
        ...errorField,
        otperror: "error",
      });
    }
  };

  const validateAfterLoginField = (name) => {
    setRefresh();
    switch (name) {
      case "about":
        error.about = detailProvider.about == "" ? "required" : "";
        break;
      case "profile_headline":
        error.profile_headline =
          detailProvider.profile_headline == "" ? "required" : "";
        break;
      case "video":
        error.video = detailProvider.video != "" ? "" : "required";

        break;
      case "file_path":
        error.file_path = detailProvider.file_path != "" ? "" : "required";
        break;
      case "educationlevel":
        error.educationlevel =
          detailProvider.educationlevel != "" ? "" : "required";
        break;
      case "school":
        error.school = detailProvider.school != "" ? "" : "required";
        break;
      case "yearawarded":
        error.yearawarded = detailProvider.yearawarded != "" ? "" : "required";
        break;
      case "recommendationsfile":
        error.recommendationsfile =
          detailProvider.recommendationsfile != "" ? "" : "required";
        break;
      case "qualificationscertificatesname":
        error.qualificationscertificatesname =
          detailProvider.qualificationscertificatesname != "" ? "" : "required";
        break;
      case "cv":
        error.cv = detailProvider.cv != "" ? "" : "required";
        break;
      case "qualificationscertificatesdoc":
        error.qualificationscertificatesdoc =
          detailProvider.qualificationscertificatesdoc != "" ? "" : "required";
        break;
      case "nanynewbornexp":
        error.nanynewbornexp =
          detailProvider.nanynewbornexp != "" ? "" : "required";
        break;
      case "nanyyearexp":
        error.nanyyearexp = experience == 0 ? "required" : "";
        break;
      case "setareaexperties":
        error.setareaexperties =
          detailProvider.setareaexperties != "" ? "" : "required";
        break;
      case "setexpmethods":
        error.setexpmethods =
          methods.setexpmethods != "" ||
          methods.setexpmethods2 != "" ||
          methods.setexpmethods3 != "" ||
          methods.setexpmethods4 != ""
            ? ""
            : "required";
        break;
      case "setexpiep":
        error.setexpiep = detailProvider.setexpiep != "" ? "" : "required";
        break;
      case "setyearexp":
        error.setyearexp = range > 0 ? "" : "required";
        break;
      case "setallapplicable":
        error.setallapplicable =
          detailProvider.setallapplicable != "" ? "" : "required";
        break;
      case "nanyexpwithkid":
        error.nanyexpwithkid =
          detailProvider.nanyexpwithkid != "" ? "" : "required";
        break;
      case "tutorexp":
        error.tutorexp = tutorExperience > 0 ? "" : "required";
        break;
      case "sepexpwithkid":
        error.sepexpwithkid =
          detailProvider.sepexpwithkid != "" ? "" : "required";
        break;
      case "sepworkexp":
        error.sepworkexp = workExperience == 0 ? "required" : "";
        break;

      case "nanyallapplicable":
        error.nanyallapplicable =
          detailProvider.nanyallapplicable != "" ? "" : "required";
        break;
      case "sepallapplicable":
        error.sepallapplicable =
          detailProvider.sepallapplicable != "" ? "" : "required";
        break;
      case "sepexpmethods":
        error.sepexpmethods =
          detailProvider.sepexpmethods != "" ? "" : "required";
        break;

      case "nanyperhrrate":
        error.nanyperhrrate = rate.max == 0 ? "required" : "";
        break;
      case "seterhrrate":
        error.seterhrrate = educationRate.max == 0 ? "required" : "";
        break;
      case "seperhrrate":
        error.seperhrrate = professionRate.max == 0 ? "required" : "";
        break;
      case "tutorintrestedonlinecls":
        error.tutorintrestedonlinecls =
          detailProvider.tutorintrestedonlinecls != "" ? "" : "required";
        break;
      case "tutorintrestedinschool":
        error.tutorintrestedinschool =
          detailProvider.tutorintrestedinschool != "" ? "" : "required";
        break;
      case "tutorallapplicable":
        error.tutorallapplicable =
          detailProvider.tutorallapplicable != "" ? "" : "required";
        break;
      case "tutorperhrrate":
        error.tutorperhrrate = tutoRate.max == 0 ? "required" : "";
        break;
      case "tutorworkwithnochild":
        error.tutorworkwithnochild = children != "" ? "" : "required";
        break;

      case "tutorprefchildage":
        error.tutorprefchildage = !childrenAge[0] ? "required" : "";
        break;
      case "tutorstartdate":
        error.tutorstartdate =
          detailProvider.tutorstartdate == "" ? "required" : "";
        break;
      case "tutorintrestedin":
        error.tutorintrestedin =
          detailProvider.tutorintrestedin == "" ? "required" : "";
        break;
      case "yearofexpasteacher":
        error.yearofexpasteacher =
          detailProvider.yearofexpasteacher == 0 ? "required" : "";
        break;

      case "tutorliketoteach":
        error.tutorliketoteach =
          qualifications.English ||
          qualifications.Serbian ||
          qualifications.Mathematics ||
          qualifications.Physics ||
          qualifications.Chemistry ||
          qualifications.Other
            ? ""
            : "required";
        break;

      case "nanyhrrate":
        error.nanyhrrate = detailProvider.nanyhrrate == "" ? "required" : "";
        break;
      case "nanyintrestedinschool":
        error.nanyintrestedinschool =
          detailProvider.nanyintrestedinschool == "" ? "required" : "";
        break;
      case "nanyworkwithnochild":
        error.nanyworkwithnochild = children == "" ? "required" : "";
        break;
      case "nanystartdate":
        error.nanystartdate =
          detailProvider.nanystartdate == "" ? "required" : "";
        break;
      case "nanyprefchildage":
        error.nanyprefchildage = childrenNanny[0] ? "" : "required";
        break;
      case "nanyintrestedin":
        error.nanyintrestedin =
          detailProvider.nanyintrestedin == "" ? "required" : "";
        break;
      case "fulltime":
        error.fulltime = detailProvider.fulltime == "" ? "required" : "";
        break;
      case "beforeschool":
        error.beforeschool =
          detailProvider.beforeschool == "" ? "required" : "";
        break;
      case "afterschool":
        error.afterschool = detailProvider.afterschool == "" ? "required" : "";
        break;
      case "overnight":
        error.overnight = detailProvider.overnight == "" ? "required" : "";
        break;
      case "weekends":
        error.weekends = detailProvider.weekends == "" ? "required" : "";
        break;
      case "englishlevel":
        error.englishlevel =
          detailProvider.englishlevel == "" ? "required" : "";
        break;
      case "italianlevel":
        error.italianlevel =
          detailProvider.italianlevel == "" ? "required" : "";
        break;
      case "spanishlevel":
        error.spanishlevel =
          detailProvider.spanishlevel == "" ? "required" : "";
        break;
      case "germanlevel":
        error.germanlevel = detailProvider.germanlevel == "" ? "required" : "";
        break;
      case "chineselevel":
        error.chineselevel =
          detailProvider.chineselevel == "" ? "required" : "";
        break;
      case "frenchlevel":
        error.frenchlevel = detailProvider.frenchlevel == "" ? "required" : "";
        break;
      case "otherlangname":
        error.otherlangname =
          detailProvider.otherlangname == "" ? "required" : "";
        break;
      case "otherlevel":
        error.otherlevel = detailProvider.otherlevel == "" ? "required" : "";
        break;
      case "phone":
        error.phone =
          detailProvider.phone == "" ||
          otp == "" ||
          detail.phoneVerifiedStatus != 1 ||
          verify != "done"
            ? "required"
            : "";
        break;
      case "backgrounddoc":
        error.backgrounddoc =
          detailProvider.backgrounddoc == "" ? "required" : "";
        break;
      case "backgroundstatus":
        error.backgroundstatus =
          detailProvider.backgroundstatus == "" ? "required" : "";
        break;
      case "smoke":
        error.smoke = option.smoke == "" ? "required" : "";
        break;
      case "carorlicence":
        error.carorlicence = option.licence == "" ? "required" : "";
        break;
      case "cooking":
        error.cooking = option.kids == "" ? "required" : "";
        break;
      case "lighthousework":
        error.lighthousework = option.housework == "" ? "required" : "";
        break;
      case "traveling":
        error.traveling = option.family == "" ? "required" : "";
        break;
      case "workingabroad":
        error.workingabroad =
          detailProvider.workingabroad == "" ? "required" : "";
        break;
      case "livewithfamily":
        error.livewithfamily =
          detailProvider.livewithfamily == "" ? "required" : "";
        break;
      case "anyallergies":
        error.anyallergies =
          detailProvider.anyallergies == "" ? "required" : "";
        break;

      case "anyallergiesdescription":
        error.anyallergiesdescription =
          detailProvider.anyallergiesdescription == "" ? "required" : "";
        break;
      case "medicalcondition":
        error.medicalcondition =
          detailProvider.medicalcondition == "" ? "required" : "";
        break;
      case "medicalconditiondescription":
        error.medicalconditiondescription =
          detailProvider.medicalconditiondescription == "" ? "required" : "";
        break;
      case "plateformsocialmedia":
        error.plateformsocialmedia =
          detailProvider.plateformsocialmedia == "" ? "required" : "";
        break;

      default:
        break;
    }
    setError(error);
    setTimeout(() => {
      setDetail(detail);
      setRefresh(name);
      window.scrollTo({ top: 0 });
    }, 1000);
  };
  const step1 = [
    { name: "about" },
    { name: "file_path" },
    { name: "profile_headline" },
  ];
  const step2 = [
    { name: "educationlevel" },
    { name: "school" },
    { name: "yearawarded" },
    { name: "recommendationsfile" },
    { name: "qualificationscertificatesname" },
    { name: "cv" },
    { name: "qualificationscertificatesdoc" },
  ];
  const step3 = [
    { name: "nanynewbornexp" },
    { name: "nanyallapplicable" },
    { name: "nanyexpwithkid" },
    { name: "setallapplicable" },
    { name: "setexpmethods" },
    { name: "setexpiep" },
    { name: "setyearexp" },
    { name: "sepallapplicable" },
    { name: "sepexpmethods" },
    { name: "sepworkexp" },
    { name: "sepexpwithkid" },
    { name: "tutorexp" },
    { name: "nanyyearexp" },
  ];
  const step4 = [
    { name: "nanyperhrrate" },
    { name: "tutorliketoteach" },
    { name: "tutorintrestedonlinecls" },
    { name: "tutorperhrrate" },
    { name: "tutorintrestedinschool" },
    { name: "tutorallapplicable" },
    { name: "tutorworkwithnochild" },
    { name: "tutorprefchildage" },
    { name: "tutorstartdate" },
    { name: "tutorintrestedin" },
    { name: "nanyintrestedinschool" },
    { name: "nanyworkwithnochild" },
    { name: "nanyprefchildage" },
    { name: "nanystartdate" },
    { name: "nanyintrestedin" },
    { name: "setareaexperties" },
    { name: "seperhrrate" },
    { name: "seterhrrate" },
    { name: "yearofexpasteacher" },
  ];
  const step5 = [
    { name: "fulltime" },
    { name: "beforeschool" },
    { name: "afterschool" },
    { name: "overnight" },
    { name: "weekends" },
  ];
  const step6 = [
    { name: "englishlevel" },
    { name: "italianlevel" },
    { name: "spanishlevel" },
    { name: "germanlevel" },
    { name: "chineselevel" },
    { name: "frenchlevel" },
    { name: "otherlangname" },
    { name: "otherlevel" },
    { name: "smoke" },
    { name: "carorlicence" },
    { name: "cooking" },
    { name: "lighthousework" },
    { name: "traveling" },
    { name: "workingabroad" },
    { name: "livewithfamily" },
    { name: "anyallergies" },
    { name: "anyallergiesdescription" },
    { name: "medicalcondition" },
    { name: "medicalconditiondescription" },
  ];
  const step7 = [
    { name: "plateformsocialmedia" },
    { name: "phone" },
    { name: "backgrounddoc" },
    { name: "backgroundstatus" },
  ];

  useEffect(() => {
    if (isCheck) {
      profile_data();
      setIsCheck(false);
      localStorage.getItem("user_type") == "provider"
        ? localStorage.getItem("user_type")
        : window.location.replace("/complete-parents-profile");
    }
  }, [
    detail,
    experience,
    detailProvider,
    option,
    methods,
    errorField,
    step,
    refresh,
    error,
  ]);

  const handleResponse = (data) => {
    if (data.profile.type) {
      setDetailProvider({ ...detailProvider, linkdinverify: true });
    } else {
      setDetailProvider({ ...detailProvider, facebookverify: true });
    }
  };

  const handleError = (error) => {
    console.log({ error });
  };
  console.log("DETAIL", detail);
  return (
    <>
      <Header page={"proile"} />
      <div className="container-fluid">
        <div className="container">
          <div className="Profile_complete">
            <div className="left">
              <div className="stop">
                <h3>
                  {t("profile-progress")} <span>{process}%</span>
                </h3>
                <span>
                  <div class="progress">
                    <div class="color" style={{ width: process + "%" }}></div>
                  </div>
                </span>
              </div>
              <div
                class={
                  "progress-circle" +
                  " " +
                  "p" +
                  process +
                  " " +
                  (process > 50 ? "over50" : "")
                }
              >
                <span>{process}%</span>
                <div class="left-half-clipper">
                  <div class="first50-bar"></div>
                  <div class="value-bar"></div>
                </div>
              </div>
              <ul
                style={
                  window.screen.width < 767
                    ? { transform: "translateX(" + mobile + "% )" }
                    : {}
                }
              >
                <li
                  onClick={(e) => {
                    if (
                      detail.plateformsocialmedia &&
                      detail.plateformsocialmedia
                    ) {
                      setStep(1);
                    }
                  }}
                  className={step == 1 ? "active" : step >= 1 ? "active2" : ""}
                >
                  <span></span>
                  <p>{t("about-person")}</p>
                </li>
                <li
                  onClick={(e) => {
                    if (
                      detail.plateformsocialmedia &&
                      detail.plateformsocialmedia
                    ) {
                      setStep(2);
                    }
                  }}
                  className={step == 2 ? "active" : step >= 2 ? "active2" : ""}
                >
                  <span></span>
                  <p>{t("Education")}</p>
                </li>
                <li
                  onClick={(e) => {
                    if (
                      detail.plateformsocialmedia &&
                      detail.plateformsocialmedia
                    ) {
                      setStep(3);
                    }
                  }}
                  className={step == 3 ? "active" : step >= 3 ? "active2" : ""}
                >
                  <span></span>
                  <p>{t("Work Experience")}</p>
                </li>
                <li
                  onClick={(e) => {
                    if (
                      detail.plateformsocialmedia &&
                      detail.plateformsocialmedia
                    ) {
                      setStep(4);
                    }
                  }}
                  className={step == 4 ? "active" : step >= 4 ? "active2" : ""}
                >
                  <span></span>
                  <p>{t("job-preferences")}</p>
                </li>
                <li
                  onClick={(e) => {
                    if (
                      detail.plateformsocialmedia &&
                      detail.plateformsocialmedia
                    ) {
                      setStep(5);
                      setTimeout(() => {
                        setcalenderType(1);
                      }, 500);
                    }
                  }}
                  className={step == 5 ? "active" : step >= 5 ? "active2" : ""}
                >
                  <span></span>
                  <p>{t("availability")}</p>
                </li>
                <li
                  onClick={(e) => {
                    if (
                      detail.plateformsocialmedia &&
                      detail.plateformsocialmedia
                    ) {
                      setStep(6);
                    }
                  }}
                  className={step == 6 ? "active" : step >= 6 ? "active2" : ""}
                >
                  <span></span>
                  <p>{t("additional-info")}</p>
                </li>
                <li
                  onClick={(e) => {
                    if (
                      detail.plateformsocialmedia &&
                      detail.plateformsocialmedia
                    ) {
                      setStep(7);
                    }
                  }}
                  className={step == 7 ? "active" : step >= 7 ? "active2" : ""}
                >
                  <span></span>
                  <p>{t("security-verification")}</p>
                </li>
              </ul>
            </div>
            <div className="right">
              <div
                className="detail job_post stap1"
                style={step == 1 ? {} : { display: "none" }}
              >
                <h2 className="border">
                  {t("text-welcome")}
                  <span>
                    {detail.first_name
                      ? detail.first_name + " " + detail.last_name
                      : ""}
                  </span>
                  , {t("complete-profile")}
                </h2>
                <span>{t("required-fields")}</span>

                <div className="form_group full">
                  <label>
                    {t("Your profile headline")}
                    <span
                      className={error.profile_headline != "" ? "starred" : ""}
                    >
                      *
                    </span>
                  </label>
                  <textarea
                    rows={2}
                    className={
                      error.profile_headline != "" ? "bordererror" : ""
                    }
                    placeholder={
                      language === "en"
                        ? detail.service_type
                          ? t("text-experience-gap") +
                            Object.values(detail.service_type)[0] +
                            t(" with 10 years of experience.")
                          : ""
                        : "Imam 10 godina iskustva u radu sa decom"
                    }
                    // placeholder={
                    //   detail.service_type
                    //     ? t("text-experience-gap") +
                    //       Object.values(detail.service_type)[0] +
                    //       t(" with 10 years of experience.")
                    //     : ""
                    // }
                    maxlength="70"
                    name="message"
                    defaultValue={detailProvider.profile_headline}
                    onChange={(e) => {
                      setError({ ...error, profile_headline: "" });
                      setDetailProvider({
                        ...detailProvider,
                        profile_headline: e.target.value,
                      });
                    }}
                  ></textarea>
                  {/* <div className='errorfield'>{error.message}</div>*/}
                  <span>
                    {t("number-characters")}
                    {70 - detailProvider.profile_headline.length}
                  </span>
                </div>
                <div className="form_group full sec">
                  <label>
                    {t("Tell us more about yourself")}
                    <span className={error.about != "" ? "starred" : ""}>
                      *
                    </span>
                  </label>
                  <textarea
                    rows={4}
                    className={error.about != "" ? "bordererror" : ""}
                    placeholder={
                      detail.service_type
                        ? Object.keys(detail.service_type).filter(
                            (e) => e == "tab2"
                          )[0]
                          ? t("myNameElena")
                          : Object.keys(detail.service_type).filter(
                                (e) => e == "tab1"
                              )[0]
                            ? t("workingMyPassion")
                            : Object.keys(detail.service_type).filter(
                                  (e) => e == "tab4"
                                )[0]
                              ? t("elenaTutor")
                              : Object.keys(detail.service_type).filter(
                                    (e) => e == "tab3"
                                  )[0]
                                ? t("elenaSpecialEducator")
                                : ""
                        : ""
                    }
                    maxlength="300"
                    name="message"
                    defaultValue={detailProvider.about}
                    onChange={(e) => {
                      setError({ ...error, about: "" });
                      setDetailProvider({
                        ...detailProvider,
                        about: e.target.value,
                      });
                    }}
                  ></textarea>
                  {/* <div className='errorfield'>{error.message}</div>*/}
                  <span>
                    {t("number-characters")} {300 - detailProvider.about.length}
                  </span>
                </div>
                <div className="form_group">
                  <label>
                    {t("upload-profile")} <a>{t("picture")} </a>
                    <span className="smallpop">
                      <strong>{t("Choices")}</strong>
                      {t("ands")}
                      <strong>{t("text-premium")}</strong> {t("upload-photo")}
                    </span>
                    <span className={error.file_path != "" ? "starred" : ""}>
                      *
                    </span>
                  </label>
                  <div className="upload">
                    <input
                      type="file"
                      className={error.file_path != "" ? "bordererror" : ""}
                      placeholder={t("choose-file")}
                      onChange={(e) => {
                        setError({ ...error, file_path: "" });
                        setDetailProvider({
                          ...detailProvider,
                          file_path: e.target.files[0],
                        });
                      }}
                      accept="image/*"
                    />
                    <span>
                      {detailProvider.file_path != ""
                        ? detailProvider.file_path.name
                          ? detailProvider.file_path.name
                          : detailProvider.file_path
                        : t("choose-file")}
                    </span>
                    <button>
                      <UploadIcon />
                    </button>
                  </div>
                  {/* <div className='errorfield'>{error.message}</div>*/}
                </div>
                <div className="form_group">
                  <label>
                    {t("text-upload")} <a>{t("text-video")} </a>
                    <span className="smallpop">
                      {t("feature-available")}
                      <strong>{t("text-premium")}</strong> {t("members.")}
                    </span>
                    {t("presentation of yourself")}
                  </label>
                  <div className="upload">
                    <input
                      type="file"
                      placeholder={t("choose-file")}
                      onChange={(e) =>
                        setDetailProvider({
                          ...detailProvider,
                          video: e.target.files[0],
                        })
                      }
                      accept="video/*"
                    />
                    <span>
                      {detailProvider.video != ""
                        ? detailProvider.video.name
                          ? detailProvider.video.name
                          : detailProvider.video
                        : t("choose-file")}
                    </span>
                    <button>
                      <UploadIcon />
                    </button>
                  </div>
                  {/* <div className='errorfield'>{error.message}</div>*/}
                </div>
                <div className="button">
                  <button
                    onClick={(e) => {
                      if (
                        detailProvider.about != "" &&
                        detailProvider.profile_headline != "" &&
                        detailProvider.file_path != ""
                      ) {
                        handleSetStep(2);
                        setMobile(-12);
                        window.scrollTo({ top: 0 });
                        fetchProviderData(false);
                      } else {
                        step1.map((e) => {
                          validateAfterLoginField(e.name);
                        });
                      }
                    }}
                  >
                    {t("text-next")}
                  </button>
                </div>
              </div>

              <div
                className="detail stap2"
                style={step == 2 ? {} : { display: "none" }}
              >
                <div className="form_group">
                  <label>
                    {t("Education Level")}
                    <span
                      className={error.educationlevel != "" ? "starred" : ""}
                    >
                      *
                    </span>
                  </label>
                  <div className="text">
                    <span
                      onClick={(e) => {
                        setError({ ...error, educationlevel: "" });
                        setIsLavelo(isLavelo ? false : true);
                      }}
                    >
                      {detailProvider.educationlevel
                        ? detailProvider.educationlevel
                        : t("choose-list")}
                    </span>
                    <div classes="select_data">
                      <ul
                        style={
                          isLavelo ? { display: "block" } : { display: "none" }
                        }
                      >
                        <li
                          onClick={(e) => {
                            setIsLavelo(isLavelo ? false : true);
                            setDetailProvider({
                              ...detailProvider,
                              educationlevel: t("High school"),
                            });
                          }}
                        >
                          {t("High school")}
                        </li>
                        <li
                          onClick={(e) => {
                            setIsLavelo(isLavelo ? false : true);
                            setDetailProvider({
                              ...detailProvider,
                              educationlevel: t("Bachelor"),
                            });
                          }}
                        >
                          {t("Bachelor")}
                        </li>
                        <li
                          onClick={(e) => {
                            setIsLavelo(isLavelo ? false : true);
                            setDetailProvider({
                              ...detailProvider,
                              educationlevel: t("Master"),
                            });
                          }}
                        >
                          {t("Master")}
                        </li>
                        <li
                          onClick={(e) => {
                            setIsLavelo(isLavelo ? false : true);
                            setDetailProvider({
                              ...detailProvider,
                              educationlevel: t("PhD"),
                            });
                          }}
                        >
                          {t("PhD")}
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* <div className='errorfield'>{error.message}</div>*/}
                </div>
                <div className="form_group">
                  <label>
                    {t("Name of your school/university")}
                    <span className={error.school != "" ? "starred" : ""}>
                      *
                    </span>
                  </label>
                  <div className="text">
                    <input
                      type="text"
                      placeholder={t("text-type")}
                      onChange={(e) => {
                        setError({ ...error, school: "" });
                        setDetailProvider({
                          ...detailProvider,
                          school: e.target.value,
                        });
                      }}
                      defaultValue={detailProvider.school}
                    />
                  </div>
                  {/* <div className='errorfield'>{error.message}</div>*/}
                </div>
                <div className="form_group">
                  <label>
                    {t("Graduation Status")}
                    <span className={error.yearawarded != "" ? "starred" : ""}>
                      *
                    </span>
                  </label>
                  <div className="text">
                    <span
                      onClick={(e) => {
                        setError({ ...error, yearawarded: "" });
                        setIsAwarded(isAwarded ? false : true);
                      }}
                    >
                      {detailProvider.yearawarded
                        ? detailProvider.yearawarded
                        : t("choose-list")}
                    </span>
                    <div classes="select_data">
                      <ul
                        style={
                          isAwarded ? { display: "block" } : { display: "none" }
                        }
                      >
                        <li
                          onClick={(e) => {
                            setIsAwarded(isAwarded ? false : true);
                            setDetailProvider({
                              ...detailProvider,
                              yearawarded: t("in-process"),
                            });
                          }}
                        >
                          {t("in-process")}
                        </li>
                        <li
                          onClick={(e) => {
                            setIsAwarded(isAwarded ? false : true);
                            setDetailProvider({
                              ...detailProvider,
                              yearawarded: t("text-graduated"),
                            });
                          }}
                        >
                          {t("text-graduated")}
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* <div className='errorfield'>{error.message}</div>*/}
                </div>
                <div className="form_group">
                  <label>
                    {t("Upload yours")} <a>{t("recommendations")} </a>
                    <span className="smallpop">
                      {t("recomentationsUpload")}
                    </span>
                  </label>
                  <div className="upload">
                    <input
                      type="file"
                      placeholder={t("choose-file")}
                      onChange={(e) => {
                        setError({
                          ...error,
                          recommendationsfile: "",
                        });
                        setDetailProvider({
                          ...detailProvider,
                          recommendationsfile: e.target.files[0],
                        });
                      }}
                      accept=".doc, .docx,.ppt, .pptx,.txt,.pdf"
                    />
                    <span>
                      {detailProvider.recommendationsfile != ""
                        ? detailProvider.recommendationsfile.name
                          ? detailProvider.recommendationsfile.name
                          : detailProvider.recommendationsfile
                        : t("choose-file")}
                    </span>
                    <button>
                      <UploadIcon />
                    </button>
                  </div>
                  {/* <div className='errorfield'>{error.message}</div>*/}
                </div>
                <div className="form_group qualification set">
                  <label>{t("professional-qualifications-certificates")}</label>
                  <div className="checkbox">
                    <ul
                      onClick={(e) =>
                        setError({
                          ...error,
                          qualificationscertificatesname: "",
                        })
                      }
                    >
                      <li>
                        <input
                          type="checkbox"
                          name=""
                          onClick={(e) => {
                            if (e.target.checked) {
                              setCertificates({
                                ...certificates,
                                qualificationscertificatesname: t("First Aid"),
                              });
                            } else {
                              setCertificates({
                                ...certificates,
                                qualificationscertificatesname: "",
                              });
                            }
                          }}
                          checked={
                            certificates.qualificationscertificatesname ==
                            t("First Aid")
                              ? true
                              : false
                          }
                        />
                        <span class="text-nowrap"> {t("First Aid")}</span>
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          name=""
                          onClick={(e) => {
                            if (e.target.checked) {
                              setCertificates({
                                ...certificates,
                                qualificationscertificatesname3: t(
                                  "Board Certified Nurse"
                                ),
                              });
                            } else {
                              setCertificates({
                                ...certificates,
                                qualificationscertificatesname3: "",
                              });
                            }
                          }}
                          checked={
                            certificates.qualificationscertificatesname3 ==
                            t("Board Certified Nurse")
                              ? true
                              : false
                          }
                        />
                        <span class="text-nowrap"> {t("Board Certified Nurse")}</span>
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          name=""
                          onClick={(e) => {
                            if (e.target.checked) {
                              setCertificates({
                                ...certificates,
                                qualificationscertificatesname2:
                                  t("Montessori"),
                              });
                            } else {
                              setCertificates({
                                ...certificates,
                                qualificationscertificatesname2: "",
                              });
                            }
                          }}
                          checked={
                            certificates.qualificationscertificatesname2 ==
                            t("Montessori")
                              ? true
                              : false
                          }
                        />
                        <span class="text-nowrap"> {t("Montessori")}</span>
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          name=""
                          onClick={(e) => {
                            if (e.target.checked) {
                              setCertificates({
                                ...certificates,
                                qualificationscertificatesname4: "e",
                              });
                            } else {
                              setCertificates({
                                ...certificates,
                                qualificationscertificatesname4: "",
                              });
                            }
                          }}
                          checked={
                            certificates.qualificationscertificatesname4 != ""
                              ? true
                              : false
                          }
                        />

                        <span>
                          <input
                            type="text"
                            placeholder={t("text-other")}
                            onChange={(e) => {
                              setCertificates({
                                ...certificates,
                                qualificationscertificatesname4: e.target.value,
                              });
                            }}
                            defaultValue={
                              certificates.qualificationscertificatesname4
                            }
                            className={
                              certificates.qualificationscertificatesname4 ==
                              "e"
                                ? "bordererror"
                                : ""
                            }
                          />
                        </span>
                      </li>
                    </ul>
                  </div>
                  {/* <div className='errorfield'>{error.message}</div>*/}
                </div>
                <div className="form_group">
                  <label>
                    {t("Upload your")} <a>{t("text-cv-up")} </a>
                    <span className="smallpop">{t("uploadCv")}</span>
                    {/* <span className={errorfield.cv != "" ? "starred" : ""}>*</span> */}
                  </label>
                  <div className="upload">
                    <input
                      type="file"
                      placeholder={t("choose-file")}
                      onChange={(e) => {
                        //seterrorfield({ ...errorfield, cv: "" });
                        setDetailProvider({
                          ...detailProvider,
                          cv: e.target.files[0],
                        });
                      }}
                      accept=".doc, .docx,.ppt, .pptx,.txt,.pdf"
                    />
                    <span>
                      {detailProvider.cv != ""
                        ? detailProvider.cv?.name
                          ? detailProvider.cv?.name
                          : detailProvider.cv
                        : t("choose-file")}
                    </span>
                    <button>
                      <UploadIcon />
                    </button>
                  </div>
                  {/* <div className='errorfield'>{error.message}</div>*/}
                </div>
                <div className="form_group free">
                  <label>{t("Upload certificates")}</label>
                  <div className="upload">
                    <input
                      type="file"
                      placeholder={t("choose-file")}
                      onChange={(e) => {
                        setError({
                          ...error,
                          qualificationscertificatesdoc: "",
                        });
                        setDetailProvider({
                          ...detailProvider,
                          qualificationscertificatesdoc: e.target.files[0],
                        });
                      }}
                      accept=".doc, .docx,.ppt, .pptx,.txt,.pdf"
                    />
                    <span>
                      {detailProvider.qualificationscertificatesdoc != ""
                        ? detailProvider.qualificationscertificatesdoc.name
                          ? detailProvider.qualificationscertificatesdoc.name
                          : detailProvider.qualificationscertificatesdoc
                        : t("choose-file")}
                    </span>
                    <button>
                      <UploadIcon />
                    </button>
                  </div>
                  {/* <div className='errorfield'>{error.message}</div>*/}
                </div>
                <div className="button">
                  <button
                    onClick={(e) => {
                      handleSetStep(1);
                      setMobile(-12);
                      window.scrollTo({ top: 0 });
                    }}
                  >
                    {t("text-back")}
                  </button>
                  <button
                    onClick={(e) => {
                      if (
                        detailProvider.educationlevel != "" &&
                        detailProvider.school != "" &&
                        detailProvider.yearawarded != "" &&
                        (certificates.qualificationscertificatesname4 == "e"
                          ? certificates.qualificationscertificatesname4
                              .length > 2
                          : detailProvider.educationlevel != "")
                      ) {
                        handleSetStep(3);
                        setMobile(-20);
                        window.scrollTo({ top: 0 });
                        fetchProviderData(false);
                      } else {
                        step2.map((e) => {
                          validateAfterLoginField(e.name);
                        });
                      }
                    }}
                  >
                    {t("text-next")}
                  </button>
                </div>
              </div>
              <div
                className="detail work-experience your_kids  setp3"
                style={step == 3 ? {} : { display: "none" }}
              >
                {detail.service_type && detail.service_type.tab1 ? (
                  <div className="nanny">
                    <h2 className="border">
                      <img src="./images/nany_pur.svg" /> {t("text-nanny")}
                    </h2>
                    <div className="form_group full space">
                      <label>
                        {t("Do you have experience with newborns?")}
                        <span
                          className={
                            error.nanynewbornexp != "" ? "starred" : ""
                          }
                        >
                          *
                        </span>
                      </label>
                      <div className="checkbox">
                        <ul
                          onClick={(w) =>
                            setError({ ...error, nanynewbornexp: "" })
                          }
                        >
                          <li>
                            <input
                              type="radio"
                              name="quality"
                              checked={
                                (
                                    detailProvider.nanynewbornexp == t("confirm") ||
                                    detailProvider.nanynewbornexp == "Da" ||
                                    detailProvider.nanynewbornexp == "Yes"
                                )
                                  ? true
                                  : false
                              }
                              onClick={(e) =>
                                setDetailProvider({
                                  ...detailProvider,
                                  nanynewbornexp: t("confirm"),
                                })
                              }
                            />
                            <span> {t("confirm")}</span>
                          </li>
                          <li>
                            <input
                              type="radio"
                              name="quality"
                              checked={
                                (
                                    detailProvider.nanynewbornexp == t("objection") ||
                                    detailProvider.nanynewbornexp == "Ne" ||
                                    detailProvider.nanynewbornexp == "No"
                                )
                                  ? true
                                  : false
                              }
                              onClick={(e) =>
                                setDetailProvider({
                                  ...detailProvider,
                                  nanynewbornexp: t("objection"),
                                })
                              }
                            />
                            <span> {t("objection")}</span>
                          </li>
                        </ul>
                      </div>
                      {/* <div className='errorfield'>{error.message}</div>*/}
                    </div>
                    {detail.service_type &&
                    !detail.service_type.tab2 &&
                    !detail.service_type.tab3 ? (
                      <>
                        <div className="form_group full space">
                          <label>
                            {t(
                              "Do you have experience in working with kids with"
                            )}
                            <br />
                            {t("special needs?")}
                            <span
                              className={
                                error.nanyexpwithkid != "" ? "starred" : ""
                              }
                            >
                              *
                            </span>
                          </label>
                          <div className="checkbox">
                            <ul
                              onClick={(e) =>
                                setError({
                                  ...error,
                                  nanyexpwithkid: "",
                                })
                              }
                            >
                              <li>
                                <input
                                  type="radio"
                                  name="quality2"
                                  checked={
                                    (
                                        detailProvider.nanynewbornexp == t("confirm") ||
                                        detailProvider.nanynewbornexp == "Da" ||
                                        detailProvider.nanynewbornexp == "Yes"
                                    )
                                      ? true
                                      : false
                                  }
                                  onClick={(e) =>
                                    setDetailProvider({
                                      ...detailProvider,
                                      nanyexpwithkid: t("confirm"),
                                    })
                                  }
                                />
                                <span> {t("confirm")}</span>
                              </li>
                              <li>
                                <input
                                  type="radio"
                                  name="quality2"
                                  checked={
                                    (
                                        detailProvider.nanynewbornexp == t("objection") ||
                                        detailProvider.nanynewbornexp == "Ne" ||
                                        detailProvider.nanynewbornexp == "No"
                                    )
                                      ? true
                                      : false
                                  }
                                  onClick={(e) =>
                                    setDetailProvider({
                                      ...detailProvider,
                                      nanyexpwithkid: t("objection"),
                                    })
                                  }
                                />
                                <span> {t("objection")}</span>
                              </li>
                            </ul>
                          </div>
                          {/* <div className='errorfield'>{error.message}</div>*/}
                        </div>
                        {detailProvider.nanyexpwithkid == t("confirm") ? (
                          <div className="job_performance">
                            <div className="form_group full">
                              <label>
                                {t("select-applicable")}
                                <span
                                  className={
                                    error.nanyallapplicable != ""
                                      ? "starred"
                                      : ""
                                  }
                                >
                                  *
                                </span>
                              </label>
                              <div className="customselect inp">
                                <input
                                  className="keyword"
                                  type="text"
                                  placeholder={t("choose-list")}
                                  value={allapplicable.map((e) => {
                                    return e.name;
                                  })}
                                />
                                <div
                                  className="overflow"
                                  id="over"
                                  onClick={(e) =>
                                    handleElementById("cate2", "over")
                                  }
                                ></div>

                                <div
                                  className="option"
                                  id="cate2"
                                  onClick={(e) =>
                                    setError({
                                      ...error,
                                      nanyallapplicable: "",
                                    })
                                  }
                                >
                                  <p>
                                    <input
                                      type="checkbox"
                                      onClick={(a) =>
                                        selectoption3(t("text-adhd"))
                                      }
                                    />
                                    <h3>{t("text-adhd")}</h3>
                                    <span></span>
                                  </p>
                                  <p>
                                    <input
                                      type="checkbox"
                                      onClick={(a) =>
                                        selectoption3(t("auditory-impairment"))
                                      }
                                    />
                                    <h3>{t("auditory-impairment")} </h3>
                                    <span></span>
                                  </p>
                                  <p>
                                    <input
                                      type="checkbox"
                                      onClick={(a) =>
                                        selectoption3(t("autism"))
                                      }
                                    />
                                    <h3>{t("autism")} </h3>
                                    <span></span>
                                  </p>
                                  <p>
                                    <input
                                      type="checkbox"
                                      onClick={(a) =>
                                        selectoption3(t("cerebral-palsy"))
                                      }
                                    />
                                    <h3>{t("cerebral-palsy")} </h3>
                                    <span></span>
                                  </p>
                                  <p>
                                    <input
                                      type="checkbox"
                                      onClick={(a) =>
                                        selectoption3(t("down-syndrome"))
                                      }
                                    />
                                    <h3>{t("down-syndrome")} </h3>
                                    <span></span>
                                  </p>
                                  <p>
                                    <input
                                      type="checkbox"
                                      onClick={(a) =>
                                        selectoption3(t("dyslexia"))
                                      }
                                    />
                                    <h3>{t("dyslexia")} </h3>
                                    <span></span>
                                  </p>
                                  <p>
                                    <input
                                      type="checkbox"
                                      onClick={(a) =>
                                        selectoption3(
                                          t("intellectual-disability")
                                        )
                                      }
                                    />
                                    <h3>{t("intellectual-disability")} </h3>
                                    <span></span>
                                  </p>
                                  <p>
                                    <input
                                      type="checkbox"
                                      onClick={(a) =>
                                        selectoption3(t("moderate-disability"))
                                      }
                                    />
                                    <h3>{t("moderate-disability")}</h3>
                                    <span></span>
                                  </p>
                                  <p>
                                    <input
                                      type="checkbox"
                                      onClick={(a) =>
                                        selectoption3(
                                          t("orthopedic-impairment")
                                        )
                                      }
                                    />
                                    <h3>{t("orthopedic-impairment")} </h3>
                                    <span></span>
                                  </p>
                                  <p>
                                    <input
                                      type="checkbox"
                                      onClick={(a) =>
                                        selectoption3(t("learn-impairment"))
                                      }
                                    />
                                    <h3>{t("learn-impairment")} </h3>
                                    <span></span>
                                  </p>
                                  <p>
                                    <input
                                      type="checkbox"
                                      onClick={(a) =>
                                        selectoption3(t("learn-disabilities"))
                                      }
                                    />
                                    <h3>{t("learn-disabilities")} </h3>
                                    <span></span>
                                  </p>
                                  <p>
                                    <input
                                      type="checkbox"
                                      onClick={(a) =>
                                        selectoption3(t("visual-impairment"))
                                      }
                                    />
                                    <h3>{t("visual-impairment")} </h3>
                                    <span></span>
                                  </p>
                                  <p>
                                    <input
                                      type="checkbox"
                                      onClick={(a) =>
                                        selectoption3(t("text-other"))
                                      }
                                    />
                                    <h3>{t("text-other")} </h3>
                                    <span></span>
                                  </p>
                                </div>

                                <span
                                  onClick={(e) =>
                                    handleElementById("cate2", "over")
                                  }
                                >
                                  <ArrowUpIcon />
                                </span>
                              </div>
                              {/* <div className='errorfield'>{error.message}</div>*/}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      ""
                    )}
                    <div className="form_group full">
                      <label>
                        {t("Years of work experience")}?
                        <span
                          className={error.nanyyearexp != "" ? "starred" : ""}
                        >
                          *
                        </span>
                      </label>
                      <div className="rang">
                        <div
                          class="slider"
                          onClick={(w) =>
                            setError({ ...error, nanyyearexp: "" })
                          }
                        >
                          <input
                            type="range"
                            min="0"
                            max="60"
                            onChange={(e) => {
                              setExperience(e.target.value);
                              setDetailProvider({
                                ...detailProvider,
                                nanyyearexp: parseInt(e.target.value),
                              });
                            }}
                            style={{
                              "background-size":
                                (experience * 100) / 60 + "% 100% ",
                            }}
                            value={experience}
                          />
                          <ul>
                            <li>0</li>
                            <li>15</li>
                            <li>30</li>
                            <li>45</li>
                            <li>60</li>
                          </ul>
                        </div>
                        <span id="rangeValue">{experience}</span>
                      </div>
                      {/* <div className='errorfield'>{error.message}</div>*/}
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {detail.service_type && detail.service_type.tab2 ? (
                  <div className="special_education">
                    <h2 className="border">
                      <img src="./images/special_education.svg" />
                      {t("education-teacher")}
                    </h2>
                    <div className="job_performance">
                      <div className="form_group">
                        <label>
                          {t("Area of expertise (select all applicable)")}
                          <span
                            className={
                              error.setallapplicable != "" ? "starred" : ""
                            }
                          >
                            *
                          </span>
                        </label>
                        <div className="customselect inp">
                          <input
                            className="keyword"
                            type="text"
                            placeholder={t("choose-list")}
                            value={setallapplicable2.map((e) => {
                              return e.name;
                            })}
                          />
                          <div
                            className="overflow"
                            id="over2"
                            onClick={(e) => handleElementById("cate8", "over2")}
                          ></div>

                          <div
                            className="option"
                            id="cate8"
                            onClick={(e) =>
                              setError({
                                ...error,
                                setallapplicable: "",
                              })
                            }
                          >
                            <p>
                              <input
                                type="checkbox"
                                checked={
                                  setallapplicable2.filter(
                                    (e) => e.name == "ADHD"
                                  )[0]
                                    ? true
                                    : false
                                }
                                onClick={(a) => selectoption6(t("text-adhd"))}
                              />
                              <h3>{t("text-adhd")} </h3>
                              <span></span>
                            </p>
                            <p>
                              <input
                                type="checkbox"
                                checked={
                                  setallapplicable2.filter(
                                    (e) => e.name == t("auditory-impairment")
                                  )[0]
                                    ? true
                                    : false
                                }
                                onClick={(a) =>
                                  selectoption6(t("auditory-impairment"))
                                }
                              />
                              <h3>{t("auditory-impairment")} </h3>
                              <span></span>
                            </p>
                            <p>
                              <input
                                type="checkbox"
                                checked={
                                  setallapplicable2.filter(
                                    (e) => e.name == t("autism")
                                  )[0]
                                    ? true
                                    : false
                                }
                                onClick={(a) => selectoption6(t("autism"))}
                              />
                              <h3>{t("autism")} </h3>
                              <span></span>
                            </p>
                            <p>
                              <input
                                type="checkbox"
                                checked={
                                  setallapplicable2.filter(
                                    (e) => e.name == t("cerebral-palsy")
                                  )[0]
                                    ? true
                                    : false
                                }
                                onClick={(a) =>
                                  selectoption6(t("cerebral-palsy"))
                                }
                              />
                              <h3>{t("cerebral-palsy")} </h3>
                              <span></span>
                            </p>
                            <p>
                              <input
                                type="checkbox"
                                checked={
                                  setallapplicable2.filter(
                                    (e) => e.name == t("down-syndrome")
                                  )[0]
                                    ? true
                                    : false
                                }
                                onClick={(a) =>
                                  selectoption6(t("down-syndrome"))
                                }
                              />
                              <h3>{t("down-syndrome")} </h3>
                              <span></span>
                            </p>
                            <p>
                              <input
                                type="checkbox"
                                checked={
                                  setallapplicable2.filter(
                                    (e) => e.name == t("dyslexia")
                                  )[0]
                                    ? true
                                    : false
                                }
                                onClick={(a) => selectoption6(t("dyslexia"))}
                              />
                              <h3>{t("dyslexia")} </h3>
                              <span></span>
                            </p>
                            <p>
                              <input
                                type="checkbox"
                                checked={
                                  setallapplicable2.filter(
                                    (e) =>
                                      e.name == t("intellectual-disability")
                                  )[0]
                                    ? true
                                    : false
                                }
                                onClick={(a) =>
                                  selectoption6(t("intellectual-disability"))
                                }
                              />
                              <h3>{t("intellectual-disability")} </h3>
                              <span></span>
                            </p>
                            <p>
                              <input
                                type="checkbox"
                                checked={
                                  setallapplicable2.filter(
                                    (e) => e.name == t("moderate-disability")
                                  )[0]
                                    ? true
                                    : false
                                }
                                onClick={(a) =>
                                  selectoption6(t("moderate-disability"))
                                }
                              />
                              <h3>{t("moderate-disability")}</h3>
                              <span></span>
                            </p>
                            <p>
                              <input
                                type="checkbox"
                                checked={
                                  setallapplicable2.filter(
                                    (e) => e.name == t("orthopedic-impairment")
                                  )[0]
                                    ? true
                                    : false
                                }
                                onClick={(a) =>
                                  selectoption6(t("orthopedic-impairment"))
                                }
                              />
                              <h3>{t("orthopedic-impairment")} </h3>
                              <span></span>
                            </p>
                            <p>
                              <input
                                type="checkbox"
                                checked={
                                  setallapplicable2.filter(
                                    (e) => e.name == t("learn-impairment")
                                  )[0]
                                    ? true
                                    : false
                                }
                                onClick={(a) =>
                                  selectoption6(t("learn-impairment"))
                                }
                              />
                              <h3>{t("learn-impairment")}</h3>
                              <span></span>
                            </p>
                            <p>
                              <input
                                type="checkbox"
                                checked={
                                  setallapplicable2.filter(
                                    (e) => e.name == t("learn-disabilities")
                                  )[0]
                                    ? true
                                    : false
                                }
                                onClick={(a) =>
                                  selectoption6(t("learn-disabilities"))
                                }
                              />
                              <h3>{t("learn-disabilities")} </h3>
                              <span></span>
                            </p>
                            <p>
                              <input
                                type="checkbox"
                                checked={
                                  setallapplicable2.filter(
                                    (e) => e.name == t("visual-impairment")
                                  )[0]
                                    ? true
                                    : false
                                }
                                onClick={(a) =>
                                  selectoption6(t("visual-impairment"))
                                }
                              />
                              <h3>{t("visual-impairment")} </h3>
                              <span></span>
                            </p>
                            <p>
                              <input
                                type="checkbox"
                                checked={
                                  setallapplicable2.filter(
                                    (e) => e.name == t("text-other")
                                  )[0]
                                    ? true
                                    : false
                                }
                                onClick={(a) => selectoption6(t("text-other"))}
                              />
                              <h3>{t("text-other")} </h3>
                              <span></span>
                            </p>
                          </div>

                          <span
                            onClick={(e) => handleElementById("cate8", "over2")}
                          >
                            <ArrowUpIcon />
                          </span>
                        </div>
                        {/* <div className='errorfield'>{error.message}</div>*/}
                      </div>
                    </div>
                    <div className="form_group full qualification methad">
                      <label>
                        {t("experience-follow-methods")}
                        <span
                          className={error.setexpmethods != "" ? "starred" : ""}
                        >
                          *
                        </span>
                      </label>
                      <div className="checkbox">
                        <ul
                          onClick={(e) =>
                            setError({ ...error, setexpmethods: "" })
                          }
                        >
                          <li>
                            <input
                              type="checkbox"
                              name=""
                              checked={
                                methods.setexpmethods &&
                                methods.setexpmethods == t("ABA")
                                  ? true
                                  : false
                              }
                              onClick={(e) => {
                                if (e.target.checked) {
                                  setMethods({
                                    ...methods,
                                    setexpmethods: t("ABA"),
                                  });
                                } else {
                                  setMethods({ ...methods, setexpmethods: "" });
                                }
                              }}
                            />
                            <span> {t("ABA")}</span>
                          </li>
                          <li>
                            <input
                              type="checkbox"
                              name=""
                              checked={
                                methods.setexpmethods2 &&
                                methods.setexpmethods2 == t("PECS")
                                  ? true
                                  : false
                              }
                              onClick={(e) => {
                                if (e.target.checked) {
                                  setMethods({
                                    ...methods,
                                    setexpmethods2: t("PECS"),
                                  });
                                } else {
                                  setMethods({
                                    ...methods,
                                    setexpmethods2: "",
                                  });
                                }
                              }}
                            />
                            <span> {t("PECS")}</span>
                          </li>
                          <li>
                            <input
                              type="checkbox"
                              name=""
                              checked={
                                methods.setexpmethods3 &&
                                methods.setexpmethods3 == t("Floortime")
                                  ? true
                                  : false
                              }
                              onClick={(e) => {
                                if (e.target.checked) {
                                  setMethods({
                                    ...methods,
                                    setexpmethods3: t("Floortime"),
                                  });
                                } else {
                                  setMethods({
                                    ...methods,
                                    setexpmethods3: "",
                                  });
                                }
                              }}
                            />
                            <span> {t("Floortime")}</span>
                          </li>
                          <li>
                            <input
                              type="checkbox"
                              name=""
                              checked={
                                methods.setexpmethods4 &&
                                methods.setexpmethods4 == t("None")
                                  ? true
                                  : false
                              }
                              onClick={(e) => {
                                if (e.target.checked) {
                                  setMethods({
                                    ...methods,
                                    setexpmethods4: t("None"),
                                  });
                                } else {
                                  setMethods({
                                    ...methods,
                                    setexpmethods4: "",
                                  });
                                }
                              }}
                            />
                            <span> {t("None")}</span>
                          </li>
                        </ul>
                      </div>
                      {/* <div className='errorfield'>{error.message}</div>*/}
                    </div>
                    <div className="form_group full">
                      <label>
                        <span>{t("Do you have an experience in")}</span>
                        <a style={{
                          marginRight: "5px",
                          marginLeft: "5px",
                        }}>
                          {t("text-iep")}
                        </a>
                        <span className="smallpop">{t("iepProgram")}</span>
                        {t("development")}?
                        <span
                          className={error.setexpiep != "" ? "starred" : ""}
                        >
                          *
                        </span>
                      </label>
                      <div className="checkbox">
                        <ul
                          onClick={(e) => setError({ ...error, setexpiep: "" })}
                        >
                          <li>
                            <input
                              type="radio"
                              name="quality6"
                              checked={
                                detailProvider.setexpiep == t("confirm")
                                  ? true
                                  : false
                              }
                              onClick={(e) =>
                                setDetailProvider({
                                  ...detailProvider,
                                  setexpiep: t("confirm"),
                                })
                              }
                            />
                            <span> {t("confirm")}</span>
                          </li>
                          <li>
                            <input
                              type="radio"
                              name="quality6"
                              checked={
                                detailProvider.setexpiep == t("objection")
                                  ? true
                                  : false
                              }
                              onClick={(e) =>
                                setDetailProvider({
                                  ...detailProvider,
                                  setexpiep: t("objection"),
                                })
                              }
                            />
                            <span> {t("objection")}</span>
                          </li>
                        </ul>
                      </div>
                      {/* <div className='errorfield'>{error.message}</div>*/}
                    </div>
                    <div className="form_group full">
                      <label>
                        {t("Years of work experience")}
                        <span
                          className={error.setyearexp != "" ? "starred" : ""}
                        >
                          *
                        </span>
                      </label>
                      <div className="rang">
                        <div
                          class="slider"
                          onClick={(w) =>
                            setError({ ...error, setyearexp: "" })
                          }
                        >
                          <input
                            type="range"
                            min="0"
                            max="60"
                            onChange={(e) => setRange(e.target.value)}
                            style={{
                              "background-size": (range * 100) / 60 + "% 100% ",
                            }}
                            value={range}
                          />
                          <ul>
                            <li>0</li>
                            <li>15</li>
                            <li>30</li>
                            <li>45</li>
                            <li>60</li>
                          </ul>
                        </div>
                        <span id="rangeValue">{range}</span>
                      </div>
                      {/* <div className='errorfield'>{error.message}</div>*/}
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {detail.service_type && detail.service_type.tab3 ? (
                  <div className="special_education">
                    <h2 className="border">
                      <img src="./images/professional.svg" />
                      {t("education-paraprofessional")}
                    </h2>
                    {detail.service_type && !detail.service_type.tab2 ? (
                      <>
                        <div className="form_group full">
                          <label>
                            {t("experience-working-kids")}
                            <span
                              className={
                                error.sepexpwithkid != "" ? "starred" : ""
                              }
                            >
                              *
                            </span>
                          </label>
                          <div className="checkbox">
                            <ul
                              onClick={(e) =>
                                setError({
                                  ...error,
                                  sepexpwithkid: "",
                                })
                              }
                            >
                              <li>
                                <input
                                  type="radio"
                                  name="quality3"
                                  checked={
                                    detailProvider.sepexpwithkid == t("confirm")
                                      ? true
                                      : false
                                  }
                                  onClick={(e) =>
                                    setDetailProvider({
                                      ...detailProvider,
                                      sepexpwithkid: t("confirm"),
                                    })
                                  }
                                />
                                <span> {t("confirm")}</span>
                              </li>
                              <li>
                                <input
                                  type="radio"
                                  name="quality3"
                                  checked={
                                    detailProvider.sepexpwithkid ==
                                    t("objection")
                                      ? true
                                      : false
                                  }
                                  onClick={(e) =>
                                    setDetailProvider({
                                      ...detailProvider,
                                      sepexpwithkid: t("objection"),
                                    })
                                  }
                                />
                                <span> {t("objection")}</span>
                              </li>
                            </ul>
                          </div>
                          {/* <div className='errorfield'>{error.message}</div>*/}
                        </div>
                        {detailProvider.sepexpwithkid == t("confirm") ? (
                          <div className="job_performance">
                            <div className="form_group">
                              <label>
                                {t("Area of expertise (select all applicable)")}
                                <span
                                  className={
                                    error.sepallapplicable != ""
                                      ? "starred"
                                      : ""
                                  }
                                >
                                  *
                                </span>
                              </label>
                              <div className="customselect inp">
                                <input
                                  className="keyword"
                                  type="text"
                                  placeholder={t("choose-list")}
                                  value={sepallapplicable.map((e) => {
                                    return e.name;
                                  })}
                                />
                                <div
                                  className="overflow"
                                  id="over3"
                                  onClick={(e) =>
                                    handleElementById("cate7", "over3")
                                  }
                                ></div>

                                <div
                                  className="option"
                                  id="cate7"
                                  onClick={(e) =>
                                    setError({
                                      ...error,
                                      setallapplicable: "",
                                    })
                                  }
                                >
                                  <p>
                                    <input
                                      type="checkbox"
                                      onClick={(a) =>
                                        selectoption5(t("text-adhd"))
                                      }
                                    />
                                    <h3>{t("text-adhd")} </h3>
                                    <span></span>
                                  </p>
                                  <p>
                                    <input
                                      type="checkbox"
                                      onClick={(a) =>
                                        selectoption5(t("auditory-impairment"))
                                      }
                                    />
                                    <h3>{t("auditory-impairment")} </h3>
                                    <span></span>
                                  </p>
                                  <p>
                                    <input
                                      type="checkbox"
                                      onClick={(a) =>
                                        selectoption5(t("autism"))
                                      }
                                    />
                                    <h3>{t("autism")} </h3>
                                    <span></span>
                                  </p>
                                  <p>
                                    <input
                                      type="checkbox"
                                      onClick={(a) =>
                                        selectoption5(t("cerebral-palsy"))
                                      }
                                    />
                                    <h3>{t("cerebral-palsy")} </h3>
                                    <span></span>
                                  </p>
                                  <p>
                                    <input
                                      type="checkbox"
                                      onClick={(a) =>
                                        selectoption5(t("down-syndrome"))
                                      }
                                    />
                                    <h3>{t("down-syndrome")} </h3>
                                    <span></span>
                                  </p>
                                  <p>
                                    <input
                                      type="checkbox"
                                      onClick={(a) =>
                                        selectoption5(t("dyslexia"))
                                      }
                                    />
                                    <h3>{t("dyslexia")} </h3>
                                    <span></span>
                                  </p>
                                  <p>
                                    <input
                                      type="checkbox"
                                      onClick={(a) =>
                                        selectoption5(
                                          t("intellectual-disability")
                                        )
                                      }
                                    />
                                    <h3>{t("intellectual-disability")} </h3>
                                    <span></span>
                                  </p>
                                  <p>
                                    <input
                                      type="checkbox"
                                      onClick={(a) =>
                                        selectoption5(t("moderate-disability"))
                                      }
                                    />
                                    <h3>{t("moderate-disability")} </h3>
                                    <span></span>
                                  </p>
                                  <p>
                                    <input
                                      type="checkbox"
                                      onClick={(a) =>
                                        selectoption5(
                                          t("orthopedic-impairment")
                                        )
                                      }
                                    />
                                    <h3>{t("orthopedic-impairment")} </h3>
                                    <span></span>
                                  </p>
                                  <p>
                                    <input
                                      type="checkbox"
                                      onClick={(a) =>
                                        selectoption5(t("learn-impairment"))
                                      }
                                    />
                                    <h3>{t("learn-impairment")} </h3>
                                    <span></span>
                                  </p>
                                  <p>
                                    <input
                                      type="checkbox"
                                      onClick={(a) =>
                                        selectoption5(t("learn-disabilities"))
                                      }
                                    />
                                    <h3>{t("learn-disabilities")} </h3>
                                    <span></span>
                                  </p>
                                  <p>
                                    <input
                                      type="checkbox"
                                      onClick={(a) =>
                                        selectoption5(t("visual-impairment"))
                                      }
                                    />
                                    <h3>{t("visual-impairment")} </h3>
                                    <span></span>
                                  </p>
                                  <p>
                                    <input
                                      type="checkbox"
                                      onClick={(a) =>
                                        selectoption5(t("text-other"))
                                      }
                                    />
                                    <h3>{t("text-other")} </h3>
                                    <span></span>
                                  </p>
                                </div>

                                <span
                                  onClick={(e) =>
                                    handleElementById("cate7", "over3")
                                  }
                                >
                                  <ArrowUpIcon />
                                </span>
                              </div>
                              {/* <div className='errorfield'>{error.message}</div>*/}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="form_group full">
                          <label>
                            {t("experience-follow-methods")}
                            <span
                              className={
                                error.sepexpmethods != "" ? "starred" : ""
                              }
                            >
                              *
                            </span>
                          </label>
                          <div className="checkbox">
                            <ul
                              onClick={(e) =>
                                setError({
                                  ...error,
                                  sepexpmethods: "",
                                })
                              }
                            >
                              <li>
                                <input
                                  type="radio"
                                  name="quality4"
                                  checked={
                                    detailProvider.sepexpmethods &&
                                    detailProvider.sepexpmethods == t("ABA")
                                      ? true
                                      : false
                                  }
                                  onClick={(e) =>
                                    setDetailProvider({
                                      ...detailProvider,
                                      sepexpmethods: t("ABA"),
                                    })
                                  }
                                />
                                <span> {t("ABA")}</span>
                              </li>
                              <li>
                                <input
                                  type="radio"
                                  name="quality4"
                                  checked={
                                    detailProvider.sepexpmethods &&
                                    detailProvider.sepexpmethods == t("PECS")
                                      ? true
                                      : false
                                  }
                                  onClick={(e) =>
                                    setDetailProvider({
                                      ...detailProvider,
                                      sepexpmethods: t("PECS"),
                                    })
                                  }
                                />
                                <span> {t("PECS")}</span>
                              </li>
                              <li>
                                <input
                                  type="radio"
                                  name="quality4"
                                  checked={
                                    detailProvider.sepexpmethods &&
                                    detailProvider.sepexpmethods ==
                                      t("Floortime")
                                      ? true
                                      : false
                                  }
                                  onClick={(e) =>
                                    setDetailProvider({
                                      ...detailProvider,
                                      sepexpmethods: t("Floortime"),
                                    })
                                  }
                                />
                                <span> {t("Floortime")}</span>
                              </li>
                              <li>
                                <input
                                  type="radio"
                                  name="quality4"
                                  checked={
                                    detailProvider.sepexpmethods &&
                                    detailProvider.sepexpmethods == t("None")
                                      ? true
                                      : false
                                  }
                                  onClick={(e) =>
                                    setDetailProvider({
                                      ...detailProvider,
                                      sepexpmethods: t("None"),
                                    })
                                  }
                                />
                                <span> {t("None")}</span>
                              </li>
                            </ul>
                          </div>
                          {/* <div className='errorfield'>{error.message}</div>*/}
                        </div>
                      </>
                    ) : (
                      ""
                    )}

                    <div className="form_group full">
                      <label>
                        {t("Years of work experience")}
                        <span
                          className={error.sepworkexp != "" ? "starred" : ""}
                        >
                          *
                        </span>
                      </label>
                      <div className="rang">
                        <div
                          class="slider"
                          onClick={(w) =>
                            setError({ ...error, sepworkexp: "" })
                          }
                        >
                          <input
                            type="range"
                            min="0"
                            max="60"
                            onChange={(e) => setWorkExperience(e.target.value)}
                            style={{
                              "background-size":
                                (workExperience * 100) / 60 + "% 100% ",
                            }}
                            value={workExperience}
                          />
                          <ul>
                            <li>0</li>
                            <li>15</li>
                            <li>30</li>
                            <li>45</li>
                            <li>60</li>
                          </ul>
                        </div>
                        <span id="rangeValue">{workExperience}</span>
                      </div>
                      {/* <div className='errorfield'>{error.message}</div>*/}
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {detail.service_type && detail.service_type.tab4 ? (
                  <div className="nanny tutor">
                    <h2 className="border">
                      <img src="./images/tutorform.svg" /> {t("text-tutor")}
                    </h2>
                    <div className="form_group full">
                      <label>
                        {t("Years of work experience")}
                        <span className={error.tutorexp != "" ? "starred" : ""}>
                          *
                        </span>
                      </label>
                      <div className="rang">
                        <div
                          class="slider"
                          onClick={(w) => setError({ ...error, tutorexp: "" })}
                        >
                          <input
                            type="range"
                            min="0"
                            max="60"
                            onChange={(e) => setTutorExperience(e.target.value)}
                            style={{
                              "background-size":
                                (tutorExperience * 100) / 60 + "% 100% ",
                            }}
                            value={tutorExperience}
                          />
                          <ul>
                            <li>0</li>
                            <li>15</li>
                            <li>30</li>
                            <li>45</li>
                            <li>60</li>
                          </ul>
                        </div>
                        <span id="rangeValue">{tutorExperience}</span>
                      </div>
                      {/* <div className='errorfield'>{error.message}</div>*/}
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="button">
                  <button
                    onClick={(e) => {
                      handleSetStep(2);
                      setMobile(-20);
                      window.scrollTo({ top: 0 });
                    }}
                  >
                    {t("text-back")}
                  </button>
                  <button
                    onClick={(e) => {
                      if (
                        (detail.service_type && detail.service_type.tab1
                          ? detailProvider.nanynewbornexp != "" &&
                            (detail.service_type &&
                            !detail.service_type.tab2 &&
                            !detail.service_type.tab3
                              ? (detailProvider.nanyexpwithkid == "Yes"
                                  ? allapplicable[0]
                                  : detailProvider.educationlevel != "") &&
                                detailProvider.nanyexpwithkid != ""
                              : detailProvider.educationlevel != "")
                          : detailProvider.educationlevel != "") &&
                        (detail.service_type && detail.service_type.tab2
                          ? setallapplicable2[0] &&
                            (methods.setexpmethods != "" ||
                              methods.setexpmethods2 != "" ||
                              methods.setexpmethods3 != "" ||
                              methods.setexpmethods4 != "") &&
                            detailProvider.setexpiep != ""
                          : detailProvider.educationlevel != "") &&
                        (detail.service_type && detail.service_type.tab3
                          ? detail.service_type && !detail.service_type.tab2
                            ? (detailProvider.sepexpwithkid == "Yes"
                                ? sepallapplicable[0]
                                : detailProvider.educationlevel != "") &&
                              detailProvider.sepexpmethods != "" &&
                              detailProvider.sepexpwithkid != ""
                            : workExperience
                          : detailProvider.educationlevel != "")
                      ) {
                        handleSetStep(4);
                        setMobile(-32);
                        window.scrollTo({ top: 0 });
                        fetchProviderData(false);
                      } else {
                        step3.map((e) => {
                          validateAfterLoginField(e.name);
                        });
                      }
                    }}
                  >
                    {t("text-next")}
                  </button>
                </div>
              </div>
              {/**/}
              <div
                className="detail work-experience job_performance setp4"
                style={step == 4 ? {} : { display: "none" }}
              >
                {detail.service_type && detail.service_type.tab1 ? (
                  <div className="nanny">
                    <h2 className="border">
                      <img src="./images/nany_pur.svg" /> {t("text-nanny")}
                    </h2>
                    <div className="form_group full">
                      <label>
                        {t("Rate per hour")}
                        {detail.country == "Serbia" || detail.country == "Srbija"
                          ? "(RSD)"
                          : "(USD)"}
                        <span
                          className={error.nanyperhrrate != "" ? "starred" : ""}
                        >
                          *
                        </span>
                      </label>
                      {(detail && detail.country == "Serbia") || "Srbija" ? (
                        <div class="wrapper rang">
                          <div class="container_slide">
                            <div class="slider-track">
                              <ul>
                                {RATE_NUMBERS.map((value, index) => {
                                  const style =
                                    rate.min >= value ||
                                    (rate.max < value && rate.max > 0)
                                      ? { backgroundColor: "#E5E5E5" }
                                      : {};
                                  return <li key={index} style={style}></li>;
                                })}
                              </ul>
                            </div>
                            <div class="bggray_slider">
                              <ul>
                                {RATE_NUMBERS_SECOND.map((value, index) => {
                                  const style =
                                    rate.min >= value ||
                                    (rate.max < value && rate.max > 0)
                                      ? { backgroundColor: "#E5E5E5" }
                                      : {};
                                  return <li key={index} style={style}></li>;
                                })}
                              </ul>
                            </div>
                            <input
                              type="range"
                              min="0"
                              max="5"
                              id="slider-1"
                              value={rate.min / 10}
                              onChange={(e) => {
                                if (
                                  (rate.max > 0 ? rate.max : 60) >
                                  (e.target.value == 5
                                    ? 60
                                    : e.target.value * 10)
                                ) {
                                  setRate({
                                    ...rate,
                                    min:
                                      e.target.value == 5
                                        ? 60
                                        : e.target.value * 10,
                                  });
                                }
                              }}
                            />
                            <input
                              type="range"
                              min="0"
                              max="5"
                              id="slider-2"
                              value={rate.max == 0 ? 50 / 10 : rate.max / 10}
                              onChange={(e) => {
                                if (
                                  rate.min <
                                  (e.target.value == 5
                                    ? 60
                                    : e.target.value * 10)
                                ) {
                                  setRate({
                                    ...rate,
                                    max:
                                      e.target.value == 5
                                        ? 60
                                        : e.target.value * 10,
                                  });
                                }
                              }}
                            />
                          </div>
                          <div class="slider two">
                            <ul>
                              <li>0</li>
                              <li>1000</li>
                              <li>2000</li>
                              <li>3000</li>
                              <li>4000</li>
                              <li>6000+</li>
                            </ul>
                          </div>
                        </div>
                      ) : (
                        <div class="wrapper rang">
                          <div class="container_slide">
                            <div class="slider-track">
                              <ul>
                                {RATE_NUMBERS.map((value, index) => {
                                  const style =
                                    rate.min >= value ||
                                    (rate.max < value && rate.max > 0)
                                      ? { backgroundColor: "#E5E5E5" }
                                      : {};
                                  return <li key={index} style={style}></li>;
                                })}
                              </ul>
                            </div>
                            <div class="bggray_slider">
                              <ul>
                                {RATE_NUMBERS_SECOND.map((value, index) => {
                                  const style =
                                    rate.min >= value ||
                                    (rate.max < value && rate.max > 0)
                                      ? { backgroundColor: "#E5E5E5" }
                                      : {};
                                  return <li key={index} style={style}></li>;
                                })}
                              </ul>
                            </div>
                            <input
                              type="range"
                              min="0"
                              max="5"
                              id="slider-1"
                              value={rate.min / 10}
                              onChange={(e) => {
                                if (
                                  (rate.max > 0 ? rate.max : 60) >
                                  (e.target.value == 5
                                    ? 60
                                    : e.target.value * 10)
                                ) {
                                  setRate({
                                    ...rate,
                                    min:
                                      e.target.value == 5
                                        ? 60
                                        : e.target.value * 10,
                                  });
                                }
                              }}
                            />
                            <input
                              type="range"
                              min="0"
                              max="5"
                              id="slider-2"
                              value={rate.max == 0 ? 50 / 10 : rate.max / 10}
                              onChange={(e) => {
                                if (
                                  rate.min <
                                  (e.target.value == 5
                                    ? 60
                                    : e.target.value * 10)
                                ) {
                                  setRate({
                                    ...rate,
                                    max:
                                      e.target.value == 5
                                        ? 60
                                        : e.target.value * 10,
                                  });
                                }
                              }}
                            />
                          </div>
                          <div class="slider two">
                            <ul>
                              <li>0</li>
                              <li>10</li>
                              <li>20</li>
                              <li>30</li>
                              <li>40</li>
                              <li>60+</li>
                            </ul>
                          </div>
                        </div>
                      )}
                      <span class="price">
                        {(detail && detail.country == "Serbia") || "Srbija"
                          ? rate.min * 100 + " - " + rate.max * 100
                          : rate.min + " - " + rate.max}
                      </span>
                      {/* <div className='errorfield'>{error.message}</div> */}
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {detail.service_type && detail.service_type.tab2 ? (
                  <div className="special_education big">
                    <h2 className="border">
                      <img src="./images/special_education.svg" />
                      {t("education-teacher")}
                    </h2>
                    <div className="form_group full">
                      <label>
                        {t("Rate per hour")}
                        {detail.country == "Serbia" || detail.country == "Srbija"
                            ? "(RSD)"
                            : "(USD)"}
                        <span
                          className={error.nanyperhrrate != "" ? "starred" : ""}
                        >
                          *
                        </span>
                      </label>
                      {(detail && detail.country == "Serbia") || "Srbija" ? (
                        <div class="wrapper rang">
                          <div class="container_slide">
                            <div class="slider-track">
                              <ul>
                                {RATE_NUMBERS.map((value, index) => {
                                  const style =
                                    educationRate.min >= value ||
                                    (educationRate.max < value &&
                                      educationRate.max > 0)
                                      ? { backgroundColor: "#E5E5E5" }
                                      : {};
                                  return <li key={index} style={style}></li>;
                                })}
                              </ul>
                            </div>
                            <div class="bggray_slider">
                              <ul>
                                {RATE_NUMBERS_SECOND.map((value, index) => {
                                  const style =
                                    educationRate.min >= value ||
                                    (educationRate.max < value &&
                                      educationRate.max > 0)
                                      ? { backgroundColor: "#E5E5E5" }
                                      : {};
                                  return <li key={index} style={style}></li>;
                                })}
                              </ul>
                            </div>
                            <input
                              type="range"
                              min="0"
                              max="5"
                              id="slider-1"
                              value={educationRate.min / 10}
                              onChange={(e) => {
                                if (
                                  (educationRate.max > 0
                                    ? educationRate.max
                                    : 60) >
                                  (e.target.value == 5
                                    ? 60
                                    : e.target.value * 10)
                                ) {
                                  setEducationRate({
                                    ...educationRate,
                                    min:
                                      e.target.value == 5
                                        ? 60
                                        : e.target.value * 10,
                                  });
                                }
                              }}
                            />
                            <input
                              type="range"
                              min="0"
                              max="5"
                              id="slider-2"
                              value={
                                educationRate.max == 0
                                  ? 50 / 10
                                  : educationRate.max / 10
                              }
                              onChange={(e) => {
                                if (
                                  educationRate.min <
                                  (e.target.value == 5
                                    ? 60
                                    : e.target.value * 10)
                                ) {
                                  setEducationRate({
                                    ...educationRate,
                                    max:
                                      e.target.value == 5
                                        ? 60
                                        : e.target.value * 10,
                                  });
                                }
                              }}
                            />
                          </div>
                          <div class="slider two">
                            <ul>
                              <li>0</li>
                              <li>1000</li>
                              <li>2000</li>
                              <li>3000</li>
                              <li>4000</li>
                              <li>6000+</li>
                            </ul>
                          </div>
                        </div>
                      ) : (
                        <div class="wrapper rang">
                          <div class="container_slide">
                            <div class="slider-track">
                              <ul>
                                {RATE_NUMBERS.map((value, index) => {
                                  const style =
                                    educationRate.min >= value ||
                                    (educationRate.max < value &&
                                      educationRate.max > 0)
                                      ? { backgroundColor: "#E5E5E5" }
                                      : {};
                                  return <li key={index} style={style}></li>;
                                })}
                              </ul>
                            </div>
                            <div class="bggray_slider">
                              <ul>
                                {RATE_NUMBERS_SECOND.map((value, index) => {
                                  const style =
                                    educationRate.min >= value ||
                                    (educationRate.max < value &&
                                      educationRate.max > 0)
                                      ? { backgroundColor: "#E5E5E5" }
                                      : {};
                                  return <li key={index} style={style}></li>;
                                })}
                              </ul>
                            </div>
                            <input
                              type="range"
                              min="0"
                              max="5"
                              id="slider-1"
                              value={educationRate.min / 10}
                              onChange={(e) => {
                                if (
                                  (educationRate.max > 0
                                    ? educationRate.max
                                    : 60) >
                                  (e.target.value == 5
                                    ? 60
                                    : e.target.value * 10)
                                ) {
                                  setEducationRate({
                                    ...educationRate,
                                    min:
                                      e.target.value == 5
                                        ? 60
                                        : e.target.value * 10,
                                  });
                                }
                              }}
                            />
                            <input
                              type="range"
                              min="0"
                              max="5"
                              id="slider-2"
                              value={
                                educationRate.max == 0
                                  ? 50 / 10
                                  : educationRate.max / 10
                              }
                              onChange={(e) => {
                                if (
                                  educationRate.min <
                                  (e.target.value == 5
                                    ? 60
                                    : e.target.value * 10)
                                ) {
                                  setEducationRate({
                                    ...educationRate,
                                    max:
                                      e.target.value == 5
                                        ? 60
                                        : e.target.value * 10,
                                  });
                                }
                              }}
                            />
                          </div>
                          <div class="slider two">
                            <ul>
                              <li>0</li>
                              <li>10</li>
                              <li>20</li>
                              <li>30</li>
                              <li>40</li>
                              <li>60+</li>
                            </ul>
                          </div>
                        </div>
                      )}
                      <span class="price">
                        {(detail && detail.country == "Serbia") || "Srbija"
                          ? educationRate.min * 100 +
                            " - " +
                            educationRate.max * 100
                          : educationRate.min + " - " + educationRate.max}
                      </span>
                      {/* <div className='errorfield'>{error.message}</div> */}
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {detail.service_type && detail.service_type.tab3 ? (
                  <div className="special_education big">
                    <h2 className="border">
                      <img src="./images/professional.svg" />
                      {t("education-paraprofessional")}
                    </h2>
                    <div className="form_group full">
                      <label>
                        {t("Rate per hour")}
                        {detail.country == "Serbia" || detail.country == "Srbija"
                            ? "(RSD)"
                            : "(USD)"}
                        <span
                          className={error.nanyperhrrate != "" ? "starred" : ""}
                        >
                          *
                        </span>
                      </label>
                      {(detail && detail.country == "Serbia") || "Srbija" ? (
                        <div class="wrapper rang">
                          <div class="container_slide">
                            <div class="slider-track">
                              <ul>
                                {RATE_NUMBERS.map((value, index) => {
                                  const style =
                                    professionRate.min >= value ||
                                    (professionRate.max < value &&
                                      professionRate.max > 0)
                                      ? { backgroundColor: "#E5E5E5" }
                                      : {};
                                  return <li key={index} style={style}></li>;
                                })}
                              </ul>
                            </div>
                            <div class="bggray_slider">
                              <ul>
                                {RATE_NUMBERS_SECOND.map((value, index) => {
                                  const style =
                                    professionRate.min >= value ||
                                    (professionRate.max < value &&
                                      professionRate.max > 0)
                                      ? { backgroundColor: "#E5E5E5" }
                                      : {};
                                  return <li key={index} style={style}></li>;
                                })}
                              </ul>
                            </div>
                            <input
                              type="range"
                              min="0"
                              max="5"
                              id="slider-1"
                              value={professionRate.min / 10}
                              onChange={(e) => {
                                if (
                                  (professionRate.max > 0
                                    ? professionRate.max
                                    : 60) >
                                  (e.target.value == 5
                                    ? 60
                                    : e.target.value * 10)
                                ) {
                                  setProfessionRate({
                                    ...professionRate,
                                    min:
                                      e.target.value == 5
                                        ? 60
                                        : e.target.value * 10,
                                  });
                                }
                              }}
                            />
                            <input
                              type="range"
                              min="0"
                              max="5"
                              id="slider-2"
                              value={
                                professionRate.max == 0
                                  ? 50 / 10
                                  : professionRate.max / 10
                              }
                              onChange={(e) => {
                                if (
                                  professionRate.min <
                                  (e.target.value == 5
                                    ? 60
                                    : e.target.value * 10)
                                ) {
                                  setProfessionRate({
                                    ...professionRate,
                                    max:
                                      e.target.value == 5
                                        ? 60
                                        : e.target.value * 10,
                                  });
                                }
                              }}
                            />
                          </div>
                          <div class="slider two">
                            <ul>
                              <li>0</li>
                              <li>1000</li>
                              <li>2000</li>
                              <li>3000</li>
                              <li>4000</li>
                              <li>6000+</li>
                            </ul>
                          </div>
                        </div>
                      ) : (
                        <div class="wrapper rang">
                          <div class="container_slide">
                            <div class="slider-track">
                              <ul>
                                {RATE_NUMBERS.map((value, index) => {
                                  const style =
                                    professionRate.min >= value ||
                                    (professionRate.max < value &&
                                      professionRate.max > 0)
                                      ? { backgroundColor: "#E5E5E5" }
                                      : {};
                                  return <li key={index} style={style}></li>;
                                })}
                              </ul>
                            </div>
                            <div class="bggray_slider">
                              <ul>
                                {RATE_NUMBERS_SECOND.map((value, index) => {
                                  const style =
                                    professionRate.min >= value ||
                                    (professionRate.max < value &&
                                      professionRate.max > 0)
                                      ? { backgroundColor: "#E5E5E5" }
                                      : {};
                                  return <li key={index} style={style}></li>;
                                })}
                              </ul>
                            </div>
                            <input
                              type="range"
                              min="0"
                              max="5"
                              id="slider-1"
                              value={professionRate.min / 10}
                              onChange={(e) => {
                                if (
                                  (professionRate.max > 0
                                    ? professionRate.max
                                    : 60) >
                                  (e.target.value == 5
                                    ? 60
                                    : e.target.value * 10)
                                ) {
                                  setProfessionRate({
                                    ...professionRate,
                                    min:
                                      e.target.value == 5
                                        ? 60
                                        : e.target.value * 10,
                                  });
                                }
                              }}
                            />
                            <input
                              type="range"
                              min="0"
                              max="5"
                              id="slider-2"
                              value={
                                professionRate.max == 0
                                  ? 50 / 10
                                  : professionRate.max / 10
                              }
                              onChange={(e) => {
                                if (
                                  professionRate.min <
                                  (e.target.value == 5
                                    ? 60
                                    : e.target.value * 10)
                                ) {
                                  setProfessionRate({
                                    ...professionRate,
                                    max:
                                      e.target.value == 5
                                        ? 60
                                        : e.target.value * 10,
                                  });
                                }
                              }}
                            />
                          </div>
                          <div class="slider two">
                            <ul>
                              <li>0</li>
                              <li>10</li>
                              <li>20</li>
                              <li>30</li>
                              <li>40</li>
                              <li>60+</li>
                            </ul>
                          </div>
                        </div>
                      )}
                      <span class="price">
                        {(detail && detail.country == "Serbia") || "Srbija"
                          ? professionRate.min * 100 +
                            " - " +
                            professionRate.max * 100
                          : professionRate.min + " - " + professionRate.max}
                      </span>
                      {/* <div className='errorfield'>{error.message}</div> */}
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {detail.service_type && detail.service_type.tab4 ? (
                  <div className="tutor">
                    <h2 className="border">
                      <img src="./images/tuter.svg" /> {t("text-tutor")}
                    </h2>

                    <div className="form_group full qualification tutorteach">
                      <label>
                        {t("Classes you would like to teach")}
                        <span
                          className={
                            error.tutorliketoteach == "" ? "" : "starred"
                          }
                        >
                          *
                        </span>
                      </label>
                      <div className="checkbox">
                        <ul
                          onClick={(e) =>
                            setError({
                              ...error,
                              tutorliketoteach: "",
                            })
                          }
                        >
                          <li>
                            <input
                              type="checkbox"
                              name=""
                              defaultChecked={
                                qualifications.Mathematics == t("mathematics")
                                  ? true
                                  : false
                              }
                              onClick={(e) => {
                                if (e.target.checked) {
                                  setQualifications({
                                    ...qualifications,
                                    Mathematics: t("mathematics"),
                                  });
                                } else {
                                  setQualifications({
                                    ...qualifications,
                                    Mathematics: "",
                                  });
                                }
                              }}
                            />
                            <span> {t("mathematics")}</span>
                          </li>
                          <li>
                            <input
                              type="checkbox"
                              name=""
                              defaultChecked={
                                qualifications.Physics == t("physics")
                                  ? true
                                  : false
                              }
                              onClick={(e) => {
                                if (e.target.checked) {
                                  setQualifications({
                                    ...qualifications,
                                    Physics: t("physics"),
                                  });
                                } else {
                                  setQualifications({
                                    ...qualifications,
                                    Physics: "",
                                  });
                                }
                              }}
                            />
                            <span> {t("physics")}</span>
                          </li>
                          <li>
                            <input
                              type="checkbox"
                              name=""
                              defaultChecked={
                                qualifications.English == t("english")
                                  ? true
                                  : false
                              }
                              onClick={(e) => {
                                if (e.target.checked) {
                                  setQualifications({
                                    ...qualifications,
                                    English: t("english"),
                                  });
                                } else {
                                  setQualifications({
                                    ...qualifications,
                                    English: "",
                                  });
                                }
                              }}
                            />
                            <span> {t("english")}</span>
                          </li>
                          <li>
                            <input
                              type="checkbox"
                              name=""
                              defaultChecked={
                                qualifications.Serbian == t("serbian")
                                  ? true
                                  : false
                              }
                              onClick={(e) => {
                                if (e.target.checked) {
                                  setQualifications({
                                    ...qualifications,
                                    Serbian: t("serbian"),
                                  });
                                } else {
                                  setQualifications({
                                    ...qualifications,
                                    Serbian: "",
                                  });
                                }
                              }}
                            />
                            <span> {t("serbian")}</span>
                          </li>

                          <li>
                            <input
                              type="checkbox"
                              name=""
                              defaultChecked={
                                qualifications.Chemistry == t("chemistry")
                                  ? true
                                  : false
                              }
                              onClick={(e) => {
                                if (e.target.checked) {
                                  setQualifications({
                                    ...qualifications,
                                    Chemistry: t("chemistry"),
                                  });
                                } else {
                                  setQualifications({
                                    ...qualifications,
                                    Chemistry: "",
                                  });
                                }
                              }}
                            />
                            <span> {t("chemistry")}</span>
                          </li>
                          <li>
                            <input type="checkbox" name="" />
                            <span>
                              {t("text-other")}
                              <input
                                type="text"
                                placeholder={t("text-other")}
                                onChange={(e) => {
                                  setQualifications({
                                    ...qualifications,
                                    Other: e.target.value,
                                  });
                                }}
                                defaultValue={
                                  qualifications.Other
                                    ? qualifications.Other
                                    : " "
                                }
                              />
                            </span>
                          </li>
                        </ul>
                      </div>
                      {/* <div className='errorfield'>{error.message}</div>*/}
                    </div>
                    <div className="form_group full">
                      <label>
                        {t("I am interested in providing online classes")}
                        <span
                          className={
                            error.tutorintrestedonlinecls != "" ? "starred" : ""
                          }
                        >
                          *
                        </span>
                      </label>
                      <div className="checkbox create">
                        <ul
                          onClick={(e) =>
                            setError({
                              ...error,
                              tutorintrestedonlinecls: "",
                            })
                          }
                        >
                          <li>
                            <input
                              type="radio"
                              name="yes"
                              checked={
                                detailProvider.tutorintrestedonlinecls ===
                                t("confirm")
                                  ? true
                                  : false
                              }
                              onClick={(e) =>
                                setDetailProvider({
                                  ...detailProvider,
                                  tutorintrestedonlinecls: t("confirm"),
                                })
                              }
                            />
                            <span> {t("confirm")}</span>
                          </li>
                          <li>
                            <input
                              type="radio"
                              name="yes"
                              checked={
                                detailProvider.tutorintrestedonlinecls ===
                                t("objection")
                                  ? true
                                  : false
                              }
                              onClick={(e) =>
                                setDetailProvider({
                                  ...detailProvider,
                                  tutorintrestedonlinecls: t("objection"),
                                })
                              }
                            />
                            <span> {t("objection")}</span>
                          </li>
                        </ul>
                      </div>
                      {/* <div className='errorfield'>{error.message}</div>*/}
                    </div>

                    <div className="form_group full">
                      <label>
                        {t("Rate per hour")}
                        {detail.country == "Serbia" || detail.country == "Srbija"
                            ? "(RSD)"
                            : "(USD)"}
                        <span
                          className={error.nanyperhrrate != "" ? "starred" : ""}
                        >
                          *
                        </span>
                      </label>
                      {(detail && detail.country == "Serbia") || "Srbija" ? (
                        <div class="wrapper rang">
                          <div class="container_slide">
                            <div class="slider-track">
                              <ul>
                                {RATE_NUMBERS.map((value, index) => {
                                  const style =
                                    tutoRate.min >= value ||
                                    (tutoRate.max < value && tutoRate.max > 0)
                                      ? { backgroundColor: "#E5E5E5" }
                                      : {};
                                  return <li key={index} style={style}></li>;
                                })}
                              </ul>
                            </div>
                            <div class="bggray_slider">
                              <ul>
                                {RATE_NUMBERS_SECOND.map((value, index) => {
                                  const style =
                                    tutoRate.min >= value ||
                                    (tutoRate.max < value && tutoRate.max > 0)
                                      ? { backgroundColor: "#E5E5E5" }
                                      : {};
                                  return <li key={index} style={style}></li>;
                                })}
                              </ul>
                            </div>
                            <input
                              type="range"
                              min="0"
                              max="5"
                              id="slider-1"
                              value={tutoRate.min / 10}
                              onChange={(e) => {
                                if (
                                  (tutoRate.max > 0 ? tutoRate.max : 60) >
                                  (e.target.value == 5
                                    ? 60
                                    : e.target.value * 10)
                                ) {
                                  setTutorRate({
                                    ...tutoRate,
                                    min:
                                      e.target.value == 5
                                        ? 60
                                        : e.target.value * 10,
                                  });
                                }
                              }}
                            />
                            <input
                              type="range"
                              min="0"
                              max="5"
                              id="slider-2"
                              value={
                                tutoRate.max == 0 ? 50 / 10 : tutoRate.max / 10
                              }
                              onChange={(e) => {
                                if (
                                  tutoRate.min <
                                  (e.target.value == 5
                                    ? 60
                                    : e.target.value * 10)
                                ) {
                                  setTutorRate({
                                    ...tutoRate,
                                    max:
                                      e.target.value == 5
                                        ? 60
                                        : e.target.value * 10,
                                  });
                                }
                              }}
                            />
                          </div>
                          <div class="slider two">
                            <ul>
                              <li>0</li>
                              <li>1000</li>
                              <li>2000</li>
                              <li>3000</li>
                              <li>4000</li>
                              <li>6000+</li>
                            </ul>
                          </div>
                        </div>
                      ) : (
                        <div class="wrapper rang">
                          <div class="container_slide">
                            <div class="slider-track">
                              <ul>
                                {RATE_NUMBERS.map((value, index) => {
                                  const style =
                                    tutoRate.min >= value ||
                                    (tutoRate.max < value && tutoRate.max > 0)
                                      ? { backgroundColor: "#E5E5E5" }
                                      : {};
                                  return <li key={index} style={style}></li>;
                                })}
                              </ul>
                            </div>
                            <div class="bggray_slider">
                              <ul>
                                {RATE_NUMBERS_SECOND.map((value, index) => {
                                  const style =
                                    tutoRate.min >= value ||
                                    (tutoRate.max < value && tutoRate.max > 0)
                                      ? { backgroundColor: "#E5E5E5" }
                                      : {};
                                  return <li key={index} style={style}></li>;
                                })}
                              </ul>
                            </div>
                            <input
                              type="range"
                              min="0"
                              max="5"
                              id="slider-1"
                              value={tutoRate.min / 10}
                              onChange={(e) => {
                                if (
                                  (tutoRate.max > 0 ? tutoRate.max : 60) >
                                  (e.target.value == 5
                                    ? 60
                                    : e.target.value * 10)
                                ) {
                                  setTutorRate({
                                    ...tutoRate,
                                    min:
                                      e.target.value == 5
                                        ? 60
                                        : e.target.value * 10,
                                  });
                                }
                              }}
                            />
                            <input
                              type="range"
                              min="0"
                              max="5"
                              id="slider-2"
                              value={
                                tutoRate.max == 0 ? 50 / 10 : tutoRate.max / 10
                              }
                              onChange={(e) => {
                                if (
                                  tutoRate.min <
                                  (e.target.value == 5
                                    ? 60
                                    : e.target.value * 10)
                                ) {
                                  setTutorRate({
                                    ...tutoRate,
                                    max:
                                      e.target.value == 5
                                        ? 60
                                        : e.target.value * 10,
                                  });
                                }
                              }}
                            />
                          </div>
                          <div class="slider two">
                            <ul>
                              <li>0</li>
                              <li>10</li>
                              <li>20</li>
                              <li>30</li>
                              <li>40</li>
                              <li>60+</li>
                            </ul>
                          </div>
                        </div>
                      )}
                      <span class="price">
                        {(detail && detail.country == "Serbia") || "Srbija"
                          ? tutoRate.min * 100 + " - " + tutoRate.max * 100
                          : tutoRate.min + " - " + tutoRate.max}
                      </span>
                      {/* <div className='errorfield'>{error.message}</div> */}
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {detail.service_type && detail.service_type.tab4 ? (
                  <div className="preferred_school_jobs tutor">
                    <h2 className="border">{t("preffer-jobs")}</h2>
                    <div className="form_group full space">
                      <label>
                        {t("I am interested in working in school")}
                        <span
                          className={
                            error.tutorintrestedinschool != "" ? "starred" : ""
                          }
                        >
                          *
                        </span>
                      </label>
                      <div className="checkbox create">
                        <ul
                          onClick={(e) =>
                            setError({
                              ...error,
                              tutorintrestedinschool: "",
                            })
                          }
                        >
                          <li>
                            <input
                              type="radio"
                              name="yes2"
                              checked={
                                detailProvider.tutorintrestedinschool ===
                                t("confirm")
                                  ? true
                                  : false
                              }
                              onClick={(e) =>
                                setDetailProvider({
                                  ...detailProvider,
                                  tutorintrestedinschool: t("confirm"),
                                })
                              }
                            />
                            <span> {t("confirm")}</span>
                          </li>
                          <li>
                            <input
                              type="radio"
                              name="yes2"
                              checked={
                                detailProvider.tutorintrestedinschool ===
                                t("objection")
                                  ? true
                                  : false
                              }
                              onClick={(e) =>
                                setDetailProvider({
                                  ...detailProvider,
                                  tutorintrestedinschool: t("objection"),
                                })
                              }
                            />
                            <span> {t("objection")}</span>
                          </li>
                        </ul>
                      </div>
                      {/* <div className='errorfield'>{error.message}</div>*/}
                    </div>
                    {detailProvider.tutorintrestedinschool === "Yes" ? (
                      <div className="form_group full">
                        <label>{t("select-applicable")}</label>
                        <div className="customselect inp">
                          <input
                            className="keyword"
                            type="text"
                            placeholder={t("choose-list")}
                            value={selectCat.map((e) => {
                              return e.name;
                            })}
                          />
                          <div
                            className="overflow"
                            id="over4"
                            onClick={(e) => handleElementById("cate", "over4")}
                          ></div>

                          <div className="option" id="cate">
                            <p>
                              <input
                                type="checkbox"
                                onClick={(a) =>
                                  selectoption(t("kindergarten-teacher"))
                                }
                              />
                              <h3>{t("kindergarten-teacher")} </h3>
                              <span></span>
                            </p>
                            <p>
                              <input
                                type="checkbox"
                                onClick={(a) =>
                                  selectoption(t("elementary-teacher"))
                                }
                              />
                              <h3>{t("elementary-teacher")} </h3>
                              <span></span>
                            </p>
                            <p>
                              <input
                                type="checkbox"
                                onClick={(a) => selectoption(t("high-teacher"))}
                              />
                              <h3>{t("high-teacher")} </h3>
                              <span></span>
                            </p>
                            <p>
                              <input
                                type="checkbox"
                                onClick={(a) =>
                                  selectoption(t("education-teacher"))
                                }
                              />
                              <h3>{t("education-teacher")} </h3>
                              <span></span>
                            </p>
                            <p>
                              <input
                                type="checkbox"
                                onClick={(a) =>
                                  selectoption(t("education-paraprofessional"))
                                }
                              />
                              <h3>{t("education-paraprofessional")} </h3>
                              <span></span>
                            </p>
                            <p>
                              <input
                                type="checkbox"
                                onClick={(a) => setSelectCat([])}
                              />
                              <h3>{t("None")} </h3>
                              <span></span>
                            </p>
                            {/* <div className='clr inp' onClick={e => {
                                                    setselectcat([])
                                                    window.location.reload()
                                                }}>Clear All <span>+</span></div>*/}
                          </div>

                          <span
                            onClick={(e) => handleElementById("cate", "over4")}
                          >
                            <ArrowUpIcon />
                          </span>
                        </div>
                        {/* <div className='errorfield'>{error.message}</div>*/}
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="form_group full">
                      <label>
                        {t("Years of work experience")}
                        <span className={tutorExperience == 0 ? "starred" : ""}>
                          *
                        </span>
                      </label>
                      <div className="rang">
                        <div class="slider">
                          <input
                            type="range"
                            min="0"
                            max="60"
                            onChange={(e) => setTutorExperience(e.target.value)}
                            style={{
                              "background-size":
                                (tutorExperience * 100) / 60 + "% 100% ",
                            }}
                            value={tutorExperience}
                          />
                          <ul>
                            <li>0</li>
                            <li>15</li>
                            <li>30</li>
                            <li>45</li>
                            <li>60</li>
                          </ul>
                        </div>
                        <span id="rangeValue">{tutorExperience}</span>
                      </div>
                      {/* <div className='errorfield'>{error.message}</div>*/}
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {detail.service_type && detail.service_type.tab4 ? (
                  <div className="general_info tutor  preferred_school_jobs">
                    <h2 className="border">{t("general-info")}</h2>
                    <div className="form_group full">
                      <label>
                        {t("Number of children you prefer to work with?")}
                        <span
                          className={
                            error.tutorworkwithnochild != "" ? "starred" : ""
                          }
                        >
                          *
                        </span>
                      </label>
                      <div className="children_number">
                        <ul
                          onClick={(w) =>
                            setError({
                              ...error,
                              tutorworkwithnochild: "",
                            })
                          }
                        >
                          <li
                            onClick={(e) => setChildren(1)}
                            className={children == 1 ? "active" : ""}
                          >
                            1
                          </li>
                          <li
                            onClick={(e) => setChildren(2)}
                            className={children == 2 ? "active" : ""}
                          >
                            2
                          </li>
                          <li
                            onClick={(e) => setChildren(t("twins"))}
                            className={children == t("twins") ? "active" : ""}
                          >
                            {t("text-twins")}
                          </li>
                          <li
                            onClick={(e) => setChildren(3)}
                            className={children == 3 ? "active" : ""}
                          >
                            3+
                          </li>
                        </ul>
                      </div>
                      {/* <div className='errorfield'>{error.message}</div>*/}
                    </div>
                    <div className="form_group  full">
                      <label>
                        {t("Preferred child’s age")}
                        <span
                          className={
                            error.tutorprefchildage != "" ? "starred" : ""
                          }
                        >
                          *
                        </span>
                      </label>
                      <div className="customselect inp">
                        <input
                          className="keyword"
                          type="text"
                          placeholder={t("choose-list")}
                          value={childrenAge.map((e) => {
                            return e.name;
                          })}
                        />
                        <div
                          className="overflow"
                          id="over5"
                          onClick={(e) => handleElementById("cate5", "over5")}
                        ></div>

                        <div
                          className="option"
                          id="cate5"
                          onClick={(w) =>
                            setError({
                              ...error,
                              tutorprefchildage: "",
                            })
                          }
                        >
                          <p>
                            <input
                              type="checkbox"
                              checked={
                                childrenAge.filter(
                                  (e) => e.name == t("years-0-1")
                                )[0]
                                  ? true
                                  : false
                              }
                              onClick={(a) => selectoption2(t("years-0-1"))}
                            />
                            <h3>{t("years-0-1")} </h3>
                            <span></span>
                          </p>
                          <p>
                            <input
                              type="checkbox"
                              checked={
                                childrenAge.filter(
                                  (e) => e.name == t("years-1-3")
                                )[0]
                                  ? true
                                  : false
                              }
                              onClick={(a) => selectoption2(t("years-1-3"))}
                            />
                            <h3>{t("years-1-3")} </h3>
                            <span></span>
                          </p>
                          <p>
                            <input
                              type="checkbox"
                              checked={
                                childrenAge.filter(
                                  (e) => e.name == t("years-4-7")
                                )[0]
                                  ? true
                                  : false
                              }
                              onClick={(a) => selectoption2(t("years-4-7"))}
                            />
                            <h3>{t("years-4-7")} </h3>
                            <span></span>
                          </p>
                          <p>
                            <input
                              type="checkbox"
                              checked={
                                childrenAge.filter(
                                  (e) => e.name == t("years-8-10")
                                )[0]
                                  ? true
                                  : false
                              }
                              onClick={(a) => selectoption2(t("years-8-10"))}
                            />
                            <h3>{t("years-8-10")} </h3>
                            <span></span>
                          </p>
                          <p>
                            <input
                              type="checkbox"
                              checked={
                                childrenAge.filter(
                                  (e) => e.name == t("years-11-15")
                                )[0]
                                  ? true
                                  : false
                              }
                              onClick={(a) => selectoption2(t("years-11-15"))}
                            />
                            <h3>{t("years-11-15")} </h3>
                            <span></span>
                          </p>
                          <p>
                            <input
                              type="checkbox"
                              checked={
                                childrenAge.filter(
                                  (e) => e.name == t("years-16")
                                )[0]
                                  ? true
                                  : false
                              }
                              onClick={(a) => selectoption2(t("years-16"))}
                            />
                            <h3>{t("years-16")} </h3>
                            <span></span>
                          </p>
                        </div>

                        <span
                          onClick={(e) => handleElementById("cate5", "over5")}
                        >
                          <ArrowUpIcon />
                        </span>
                      </div>
                      {/* <div className='errorfield'>{error.message}</div>*/}
                    </div>
                    <div className="form_group full">
                      <label>
                        {t("start-date")}
                        <span
                          className={
                            error.tutorstartdate != "" ? "starred" : ""
                          }
                        >
                          *
                        </span>
                      </label>
                      <div className="date_cal">
                        <span>
                          <DateIcon />
                        </span>

                        <DatePicker
                          className={
                            error.tutorstartdate != "" ? "bordererror" : ""
                          }
                          minDate={today}
                          selected={
                            detailProvider.tutorstartdate != null &&
                            detailProvider.tutorstartdate != ""
                              ? new Date(detailProvider.tutorstartdate)
                              : today
                          }
                          onChange={(date) => {
                            setError({
                              ...error,
                              tutorstartdate: "",
                            });
                            setDetailProvider({
                              ...detailProvider,
                              tutorstartdate:
                                date.getFullYear() +
                                "-" +
                                String(date.getMonth() + 1).padStart(2, "0") +
                                "-" +
                                String(date.getDate()).padStart(2, "0"),
                            });
                          }}
                        />
                      </div>
                      {/* <div className='errorfield'>{error.message}</div>*/}
                    </div>
                    <div className="form_group full qualification ">
                      <label>
                        {t("interested-in")}
                        <span
                          className={
                            error.tutorintrestedin != "" ? "starred" : ""
                          }
                        >
                          *
                        </span>
                      </label>
                      <div className="checkbox create">
                        <ul
                          className="intrestedin"
                          onClick={(e) =>
                            setError({
                              ...error,
                              tutorintrestedin: "",
                            })
                          }
                        >
                          <li>
                            <input
                              type="checkbox"
                              name=""
                              checked={
                                tutorTime.fulltime != ""
                                  ? true
                                  : detailProvider.tutorintrestedin ==
                                      t("full-time")
                                    ? true
                                    : false
                              }
                              onClick={(e) => {
                                if (e.target.checked) {
                                  setTutorTime({
                                    ...tutorTime,
                                    fulltime: t("full-time"),
                                  });
                                  setDetailProvider({
                                    ...detailProvider,
                                    tutorintrestedin: t("full-time"),
                                  });
                                } else {
                                  setTutorTime({
                                    ...tutorTime,
                                    fulltime: "",
                                  });
                                  setDetailProvider({
                                    ...detailProvider,
                                    tutorintrestedin: "",
                                  });
                                }
                              }}
                            />
                            <span class="text-nowrap"> {t("full-time")}</span>
                          </li>
                          <li>
                            <input
                              type="checkbox"
                              name=""
                              checked={
                                tutorTime.parttime != ""
                                  ? true
                                  : detailProvider.tutorintrestedin ==
                                      t("part-time")
                                    ? true
                                    : false
                              }
                              onClick={(e) => {
                                if (e.target.checked) {
                                  setTutorTime({
                                    ...tutorTime,
                                    parttime: t("part-time"),
                                  });
                                  setDetailProvider({
                                    ...detailProvider,
                                    tutorintrestedin: t("part-time"),
                                  });
                                } else {
                                  setTutorTime({
                                    ...tutorTime,
                                    parttime: "",
                                  });
                                  setDetailProvider({
                                    ...detailProvider,
                                    tutorintrestedin: "",
                                  });
                                }
                              }}
                            />
                            <span class="text-nowrap"> {t("part-time")}</span>
                          </li>
                          <li>
                            <input
                              type="checkbox"
                              name=""
                              checked={
                                tutorTime.occasionally != ""
                                  ? true
                                  : detailProvider.tutorintrestedin ==
                                      t("occasionally")
                                    ? true
                                    : false
                              }
                              onClick={(e) => {
                                if (e.target.checked) {
                                  setTutorTime({
                                    ...tutorTime,
                                    occasionally: t("occasionally"),
                                  });
                                  setDetailProvider({
                                    ...detailProvider,
                                    tutorintrestedin: t("occasionally"),
                                  });
                                } else {
                                  setTutorTime({
                                    ...tutorTime,
                                    occasionally: "",
                                  });
                                  setDetailProvider({
                                    ...detailProvider,
                                    tutorintrestedin: "",
                                  });
                                }
                              }}
                            />
                            <span class="text-nowrap"> {t("occasionally")}</span>
                          </li>
                        </ul>
                      </div>
                      {/* <div className='errorfield'>{error.message}</div>*/}
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {detail.service_type && detail.service_type.tab1 && !detail.service_type.tab4 &&
                <div className="nany2">
                  <div className="preferred_school_jobs tutor">
                    <h2 className="border">{t("preffer-jobs")}</h2>
                    <div className="form_group full">
                      <label>
                        {t("I am interested in working in school")}
                        <span
                          className={
                            error.nanyintrestedinschool != "" ? "starred" : ""
                          }
                        >
                          *
                        </span>
                      </label>
                      <div className="checkbox create">
                        <ul
                          onClick={(e) =>
                            setError({
                              ...error,
                              nanyintrestedinschool: "",
                            })
                          }
                        >
                          <li>
                            <input
                              type="radio"
                              name="school"
                              checked={
                                detailProvider.nanyintrestedinschool == "Yes"
                                  ? true
                                  : false
                              }
                              onClick={(e) =>
                                setDetailProvider({
                                  ...detailProvider,
                                  nanyintrestedinschool: "Yes",
                                })
                              }
                            />
                            <span> {t("confirm")}</span>
                          </li>
                          <li>
                            <input
                              type="radio"
                              name="school"
                              checked={
                                detailProvider.nanyintrestedinschool == "No"
                                  ? true
                                  : false
                              }
                              onClick={(e) =>
                                setDetailProvider({
                                  ...detailProvider,
                                  nanyintrestedinschool: "No",
                                })
                              }
                            />
                            <span> {t("objection")}</span>
                          </li>
                        </ul>
                      </div>
                      {/* <div className='errorfield'>{error.message}</div>*/}
                    </div>
                    {detailProvider.nanyintrestedinschool == "Yes" ? (
                      <>
                        <div className="form_group full">
                          <label>{t("select-applicable")}</label>
                          <div className="customselect inp">
                            <input
                              className="keyword"
                              type="text"
                              placeholder={t("choose-list")}
                              value={selectCat.map((e) => {
                                return e.name;
                              })}
                            />
                            <div
                              className="overflow"
                              id="over6"
                              onClick={(e) =>
                                handleElementById("cate9", "over6")
                              }
                            ></div>

                            <div className="option" id="cate9">
                              <p>
                                <input
                                  type="checkbox"
                                  onClick={(a) =>
                                    selectoption(t("kindergarten-teacher"))
                                  }
                                  checked={
                                    selectCat.filter(
                                      (e) => e.name == t("kindergarten-teacher")
                                    )[0]
                                      ? true
                                      : false
                                  }
                                />
                                <h3>{t("kindergarten-teacher")} </h3>
                                <span></span>
                              </p>
                              <p>
                                <input
                                  type="checkbox"
                                  onClick={(a) =>
                                    selectoption(t("elementary-teacher"))
                                  }
                                  checked={
                                    selectCat.filter(
                                      (e) => e.name == t("elementary-teacher")
                                    )[0]
                                      ? true
                                      : false
                                  }
                                />
                                <h3>{t("elementary-teacher")} </h3>
                                <span></span>
                              </p>
                              <p>
                                <input
                                  type="checkbox"
                                  onClick={(a) =>
                                    selectoption(t("high-teacher"))
                                  }
                                  checked={
                                    selectCat.filter(
                                      (e) => e.name == t("high-teacher")
                                    )[0]
                                      ? true
                                      : false
                                  }
                                />
                                <h3>{t("high-teacher")} </h3>
                                <span></span>
                              </p>
                              <p>
                                <input
                                  type="checkbox"
                                  onClick={(a) =>
                                    selectoption(t("education-teacher"))
                                  }
                                  checked={
                                    selectCat.filter(
                                      (e) => e.name == t("education-teacher")
                                    )[0]
                                      ? true
                                      : false
                                  }
                                />
                                <h3>{t("education-teacher")} </h3>
                                <span></span>
                              </p>
                              <p>
                                <input
                                  type="checkbox"
                                  onClick={(a) =>
                                    selectoption(
                                      t("education-paraprofessional")
                                    )
                                  }
                                  checked={
                                    selectCat.filter(
                                      (e) =>
                                        e.name ==
                                        t("education-paraprofessional")
                                    )[0]
                                      ? true
                                      : false
                                  }
                                />
                                <h3>{t("education-paraprofessional")} </h3>
                                <span></span>
                              </p>
                              <p>
                                <input type="checkbox" onClick={(a) => {}} />
                                <h3>{t("None")} </h3>
                                <span></span>
                              </p>
                              {/* <div className='clr inp' onClick={e => {
                                                    setselectcat([])
                                                    window.location.reload()
                                                }}>Clear All <span>+</span></div>*/}
                            </div>

                            <span
                              onClick={(e) =>
                                handleElementById("cate9", "over6")
                              }
                            >
                              <ArrowUpIcon />
                            </span>
                          </div>
                          {/* <div className='errorfield'>{error.message}</div>*/}
                        </div>

                        <div className="form_group full">
                          <label>
                            {t("Years of work experience as a teacher")}
                            <span
                              className={
                                error.yearofexpasteacher != "" ? "starred" : ""
                              }
                            >
                              *
                            </span>
                          </label>
                          <div className="rang">
                            <div
                              class="slider"
                              onClick={(e) =>
                                setError({
                                  ...error,
                                  yearofexpasteacher: "",
                                })
                              }
                            >
                              <input
                                type="range"
                                min="0"
                                max="60"
                                onChange={(e) =>
                                  setDetailProvider({
                                    ...detailProvider,
                                    yearofexpasteacher: e.target.value,
                                  })
                                }
                                style={{
                                  "background-size":
                                    ((detailProvider.yearofexpasteacher == ""
                                      ? 0
                                      : detailProvider.yearofexpasteacher) *
                                      100) /
                                      60 +
                                    "% 100% ",
                                }}
                                value={
                                  detailProvider.yearofexpasteacher == ""
                                    ? 0
                                    : detailProvider.yearofexpasteacher
                                }
                              />
                              <ul>
                                <li>0</li>
                                <li>15</li>
                                <li>30</li>
                                <li>45</li>
                                <li>60</li>
                              </ul>
                            </div>
                            <span id="rangeValue">
                              {detailProvider.yearofexpasteacher == ""
                                ? 0
                                : detailProvider.yearofexpasteacher}
                            </span>
                          </div>
                          {/* <div className='errorfield'>{error.message}</div>*/}
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="general_info tutor  preferred_school_jobs">
                    <h2 className="border">{t("general-info")}</h2>
                    <div className="form_group full">
                      <label>
                        {t("Number of children you prefer to work with?")}
                        <span
                          className={
                            error.nanyworkwithnochild == "" ? "" : "starred"
                          }
                        >
                          *
                        </span>
                      </label>
                      <div className="children_number">
                        <ul
                            onClick={(w) => {
                              setError((prevError) => ({
                                ...prevError,
                                tutorworkwithnochild: "",  // Update state using functional update
                              }));
                            }}
                        >
                          <li
                            onClick={(e) => setChildrenCount(1)}
                            className={childrenCount == 1 ? "active" : ""}
                          >
                            1
                          </li>
                          <li
                            onClick={(e) => setChildrenCount(2)}
                            className={childrenCount == 2 ? "active" : ""}
                          >
                            2
                          </li>
                          <li
                            onClick={(e) => setChildrenCount(t("twins"))}
                            className={
                              childrenCount == t("twins") ? "active" : ""
                            }
                          >
                            {t("text-twins")}
                          </li>
                          <li
                            onClick={(e) => setChildrenCount(3)}
                            className={childrenCount == 3 ? "active" : ""}
                          >
                            3+
                          </li>
                        </ul>
                      </div>
                      {/* <div className='errorfield'>{error.message}</div>*/}
                    </div>
                    <div className="form_group  full">
                      <label>
                        {t("Preferred child’s age")}
                        <span
                          className={
                            error.nanyprefchildage != "" ? "starred" : ""
                          }
                        >
                          *
                        </span>
                      </label>
                      <div className="customselect inp">
                        <div className="childinput">
                          {childrenNanny.map((e, index) => {
                            return (
                              e.name +
                              (childrenNanny.length - 1 >= index + 1
                                ? ", "
                                : "")
                            );
                          })}
                        </div>

                        {/* <input className='keyword' type="text" placeholder='Choose from the list' value={children_age2.map((e, index) => {
                                                    return e.name + (children_age2.length - 1 >= index + 1 ? "; " : "")
                                                })} /> */}
                        <div
                          className="overflow"
                          id="over7"
                          onClick={(e) => handleElementById("cate6", "over7")}
                        ></div>

                        <div
                          className="option"
                          id="cate6"
                          onClick={(e) =>
                            setError({
                              ...error,
                              nanyprefchildage: "",
                            })
                          }
                        >
                          <p>
                            <input
                              type="checkbox"
                              checked={
                                childrenNanny.filter(
                                  (e) => e.name == t("years-0-1")
                                )[0]
                                  ? true
                                  : false
                              }
                              onClick={(a) => selectoption4(t("years-0-1"))}
                            />
                            <h3>{t("years-0-1")}</h3>
                            <span></span>
                          </p>
                          <p>
                            <input
                              type="checkbox"
                              checked={
                                childrenNanny.filter(
                                  (e) => e.name == t("years-4-7")
                                )[0]
                                  ? true
                                  : false
                              }
                              onClick={(a) => selectoption4(t("years-4-7"))}
                            />
                            <h3>{t("years-4-7")} </h3>
                            <span></span>
                          </p>
                          <p>
                            <input
                              type="checkbox"
                              checked={
                                childrenNanny.filter(
                                  (e) => e.name == t("years-8-10")
                                )[0]
                                  ? true
                                  : false
                              }
                              onClick={(a) => selectoption4(t("years-8-10"))}
                            />
                            <h3>{t("years-8-10")} </h3>
                            <span></span>
                          </p>
                          <p>
                            <input
                              type="checkbox"
                              checked={
                                childrenNanny.filter(
                                  (e) => e.name == t("years-11-15")
                                )[0]
                                  ? true
                                  : false
                              }
                              onClick={(a) => selectoption4(t("years-11-15"))}
                            />
                            <h3>{t("years-11-15")} </h3>
                            <span></span>
                          </p>
                          <p>
                            <input
                              type="checkbox"
                              checked={
                                childrenNanny.filter(
                                  (e) => e.name == t("years-16")
                                )[0]
                                  ? true
                                  : false
                              }
                              onClick={(a) => selectoption4(t("years-16"))}
                            />
                            <h3>{t("years-16")} </h3>
                            <span></span>
                          </p>
                        </div>

                        <span
                          onClick={(e) => handleElementById("cate6", "over7")}
                        >
                          <ArrowUpIcon />
                        </span>
                      </div>
                      {/* <div className='errorfield'>{error.message}</div>*/}
                    </div>
                    <div className="form_group full">
                      <label>
                        {t("start-date")}
                        <span
                          className={error.nanystartdate != "" ? "starred" : ""}
                        >
                          *
                        </span>
                      </label>
                      <div className="date_cal">
                        <span>
                          <DateIcon />
                        </span>

                        <DatePicker
                          className={
                            error.nanystartdate != "" ? "bordererror" : ""
                          }
                          minDate={today}
                          selected={
                            detailProvider.nanystartdate != null &&
                            detailProvider.nanystartdate != ""
                              ? new Date(detailProvider.nanystartdate)
                              : today
                          }
                          onChange={(date) => {
                            setError({ ...error, nanystartdate: "" });
                            setDetailProvider({
                              ...detailProvider,
                              nanystartdate:
                                date.getFullYear() +
                                "-" +
                                String(date.getMonth() + 1).padStart(2, "0") +
                                "-" +
                                String(date.getDate()).padStart(2, "0"),
                            });
                          }}
                        />
                      </div>
                      {/* <div className='errorfield'>{error.message}</div>*/}
                    </div>
                    <div className="form_group full qualification ">
                      <label>
                        {t("interested-in")}
                        <span
                          className={
                            error.nanyintrestedin != "" ? "starred" : ""
                          }
                        >
                          *
                        </span>
                      </label>
                      <div className="checkbox create">
                        <ul
                            class="flex-row align-items-center"
                          onClick={(e) =>
                            setError({
                              ...error,
                              nanyintrestedin: "",
                            })
                          }
                        >
                          <li>
                            <input
                              type="radio"
                              name=""
                              checked={
                                tutorTime.fulltime != ""
                                  ? true
                                  : detailProvider.nanyintrestedin ==
                                      t("full-time")
                                    ? true
                                    : false
                              }
                              onClick={(e) => {
                                if (e.target.checked) {
                                  // settutorintrestedinm({ ...tutorintrestedinm, fulltime: "Full time" })
                                  setDetailProvider({
                                    ...detailProvider,
                                    nanyintrestedin: t("full-time"),
                                  });
                                } else {
                                  setDetailProvider({
                                    ...detailProvider,
                                    nanyintrestedin: "",
                                  });
                                  // settutorintrestedinm({ ...tutorintrestedinm, fulltime: "" })
                                }
                              }}
                            />
                            <span class="text-nowrap"> {t("full-time")}</span>
                          </li>
                          <li>
                            <input
                              type="radio"
                              name=""
                              checked={
                                tutorTime.parttime != ""
                                  ? true
                                  : detailProvider.nanyintrestedin ==
                                      t("part-time")
                                    ? true
                                    : false
                              }
                              onClick={(e) => {
                                if (e.target.checked) {
                                  // settutorintrestedinm({ ...tutorintrestedinm, parttime: "Part time" })
                                  setDetailProvider({
                                    ...detailProvider,
                                    nanyintrestedin: t("part-time"),
                                  });
                                } else {
                                  setDetailProvider({
                                    ...detailProvider,
                                    nanyintrestedin: "",
                                  });
                                  // settutorintrestedinm({ ...tutorintrestedinm, parttime: "" })
                                }
                              }}
                            />
                            <span class="text-nowrap" style={{ marginRight: "16px" }}>
                              {t("part-time")}
                            </span>
                          </li>
                          <li>
                            <input
                              type="radio"
                              name=""
                              checked={
                                tutorTime.occasionally != ""
                                  ? true
                                  : detailProvider.nanyintrestedin ==
                                      t("occasionally")
                                    ? true
                                    : false
                              }
                              onClick={(e) => {
                                if (e.target.checked) {
                                  // settutorintrestedinm({ ...tutorintrestedinm, occasionally: "Occasionally" })
                                  setDetailProvider({
                                    ...detailProvider,
                                    nanyintrestedin: t("occasionally"),
                                  });
                                } else {
                                  setDetailProvider({
                                    ...detailProvider,
                                    nanyintrestedin: "",
                                  });
                                  // settutorintrestedinm({ ...tutorintrestedinm, occasionally: "" })
                                }
                              }}
                            />
                            <span class="text-nowrap"> {t("occasionally")}</span>
                          </li>
                        </ul>
                      </div>
                      {/* <div className='errorfield'>{error.message}</div>*/}
                    </div>
                  </div>
                </div>
                }

                <div className="button">
                  <button
                    onClick={(e) => {
                      handleSetStep(3);
                      setMobile(-32);
                      window.scrollTo({ top: 0 });
                    }}
                  >
                    {t("text-back")}
                  </button>
                  <button
                    onClick={(e) => {
                      if (
                        (detail.service_type && detail.service_type.tab1
                          ? rate.min > 5 || rate.max > 5
                          : detailProvider.nanyintrestedinschool != "") &&
                        (detail.service_type && detail.service_type.tab4
                          ? (detail.service_type &&
                            !detail.service_type.tab2 &&
                            !detail.service_type.tab3
                              ? (qualifications.English ||
                                  qualifications.Serbian ||
                                  qualifications.Mathematics ||
                                  qualifications.Physics ||
                                  qualifications.Chemistry ||
                                  qualifications.Other) &&
                                detailProvider.tutorintrestedonlinecls != ""
                              : detailProvider.nanyintrestedin != "") &&
                            tutoRate.max > 5
                          : detailProvider.nanyintrestedin != "") &&
                        (detail.service_type && detail.service_type.tab2
                          ? educationRate.max > 0
                          : detailProvider.nanyintrestedin != "") &&
                        (detail.service_type && detail.service_type.tab3
                          ? professionRate.max > 0
                          : detailProvider.nanyintrestedin != "") &&
                        detailProvider.nanyintrestedinschool != "" &&
                        childrenCount != "" &&
                        childrenNanny[0] &&
                        detailProvider.nanystartdate != "" &&
                        detailProvider.nanyintrestedin != ""
                      ) {
                        {
                          handleSetStep(5);
                          setMobile(-46);
                          window.scrollTo({ top: 0 });
                          fetchProviderData(false);
                        }
                        setTimeout(() => {
                          setcalenderType(1);
                        }, 500);
                      } else {
                        step4.map((e) => {
                          validateAfterLoginField(e.name);
                        });
                      }
                    }}
                  >
                    {t("text-next")}
                  </button>
                </div>
              </div>
              {/**/}
              <div
                className="detail availability setp5"
                style={step == 5 ? {} : { display: "none" }}
              >
                <div className="form_group full">
                  <label>{t("availability-working")}</label>
                  <p>{t("share-availability-families")}</p>
                </div>
                <div className="form_group full">
                  <label>{t("auto-calendar")}</label>
                  <ul>
                    <li
                      onClick={(e) => setcalenderType(1)}
                      className={calendarType == 1 ? "active" : ""}
                    >
                      {t("full-time")}
                    </li>
                    <li
                      onClick={(e) => setcalenderType(2)}
                      className={calendarType == 2 ? "active" : ""}
                    >
                      {t("before-school")}
                    </li>
                    <li
                      onClick={(e) => setcalenderType(3)}
                      className={calendarType == 3 ? "active" : ""}
                    >
                      {t("after-school")}
                    </li>
                    <li
                      onClick={(e) => setcalenderType(4)}
                      className={calendarType == 4 ? "active" : ""}
                    >
                      {t("text-overnight")}
                    </li>
                    <li
                      onClick={(e) => setcalenderType(5)}
                      className={calendarType == 5 ? "active" : ""}
                    >
                      {t("text-weekends")}
                    </li>
                  </ul>
                </div>
                <div className="calander">
                  <div className="form_group full">
                    <label>{t("select-days")}</label>
                  </div>
                  <div className="calanderfull">
                    {calendarType == 1 ? (
                      <Calendar
                        data={handleCalendarData}
                        data1={detailProvider.fulltime}
                        data2={"fulltime"}
                      />
                    ) : (
                      ""
                    )}
                    {calendarType == 2 ? (
                      <Calendar
                        data={handleCalendarData}
                        data1={detailProvider.beforeschool}
                        data2={"beforeschool"}
                      />
                    ) : (
                      ""
                    )}
                    {calendarType == 3 ? (
                      <Calendar
                        data={handleCalendarData}
                        data1={detailProvider.afterschool}
                        data2={"afterschool"}
                      />
                    ) : (
                      ""
                    )}
                    {calendarType == 4 ? (
                      <Calendar
                        data={handleCalendarData}
                        data1={detailProvider.overnight}
                        data2={"overnight"}
                      />
                    ) : (
                      ""
                    )}
                    {calendarType == 5 ? (
                      <Calendar
                        data={handleCalendarData}
                        data1={detailProvider.weekends}
                        data2={"weekends"}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="legend">
                    <div className="form_group full">
                      <label className="border">{t("text-legend")}</label>
                      <ul>
                        <li>
                          <strong>{t("full-time")}</strong>
                          <br />
                          {t("mon-fri-9-6")}
                        </li>
                        <li>
                          <strong>{t("before-school")}</strong>
                          <br />
                          {t("mon-fri-6-9")}
                        </li>
                        <li>
                          <strong>{t("after-school")}</strong>
                          <br />
                          {t("mon-fri-3-9")}
                        </li>
                        <li>
                          <strong>{t("text-overnight")}</strong>
                          <br />
                          {t("mon-fri-9-6-am")}
                        </li>
                        <li>
                          <strong>{t("text-weekends")}</strong>
                          <br />
                          {t("saturday-sunday")}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="button">
                  <button
                    onClick={(e) => {
                      handleSetStep(4);
                      setMobile(-46);
                      window.scrollTo({ top: 0 });
                    }}
                  >
                    {t("text-back")}
                  </button>
                  <button
                    onClick={(e) => {
                      if (
                        detailProvider.fulltime != "" ||
                        detailProvider.beforeschool != "" ||
                        detailProvider.afterschool != "" ||
                        detailProvider.overnight != "" ||
                        detailProvider.weekends != ""
                      ) {
                        handleSetStep(6);
                        setMobile(-60);
                        window.scrollTo({ top: 0 });
                        fetchProviderData(false);
                      } else {
                        step5.map((e) => {
                          validateAfterLoginField(e.name);
                        });
                      }
                    }}
                  >
                    {t("text-next")}
                  </button>
                </div>
              </div>
              {/**/}
              <div
                className="detail  additional_info setp6"
                style={step == 6 ? {} : { display: "none" }}
              >
                <div
                  className="form_group full"
                  onClick={(e) =>
                    setError({
                      ...error,
                      englishlevel: "",
                      frenchlevel: "",
                      italianlevel: "",
                      germanlevel: "",
                      spanishlevel: "",
                      chineselevel: "",
                      otherlevel: "",
                    })
                  }
                >
                  <label>
                    {t("What languages do you speak?")}
                    <span
                      className={
                        error.englishlevel != "" ||
                        error.frenchlevel != "" ||
                        error.italianlevel != "" ||
                        error.germanlevel != "" ||
                        error.spanishlevel != "" ||
                        error.chineselevel != "" ||
                        error.otherlevel != ""
                          ? "starred"
                          : ""
                      }
                    >
                      *
                    </span>
                  </label>
                  <div className="language">
                    <strong>{t("english")}</strong>
                    <ul>
                      <li
                        onClick={(e) => {
                          if (
                            languages.filter((e) => e.name == "English_1") == ""
                          ) {
                            handleLanguageSelect("English_1");
                            setDetailProvider({
                              ...detailProvider,
                              englishlevel: "Beginner",
                            });
                          } else {
                            setDetailProvider({
                              ...detailProvider,
                              englishlevel: "",
                            });
                            handleLanguageSelect("English_5");
                          }
                        }}
                        className={
                          languages.filter((e) => e.name == "English_1") !=
                            "" || detailProvider.englishlevel == "Beginner"
                            ? "active"
                            : ""
                        }
                      >
                        {t("Beginner")}
                      </li>
                      <li
                        onClick={(e) => {
                          if (
                            languages.filter((e) => e.name == "English_2") == ""
                          ) {
                            handleLanguageSelect("English_2");
                            setDetailProvider({
                              ...detailProvider,
                              englishlevel: "Intermediate",
                            });
                          } else {
                            setDetailProvider({
                              ...detailProvider,
                              englishlevel: "",
                            });
                            handleLanguageSelect("English_5");
                          }
                        }}
                        className={
                          languages.filter((e) => e.name == "English_2") !=
                            "" || detailProvider.englishlevel == "Intermediate"
                            ? "active"
                            : ""
                        }
                      >
                        {t("Intermediate")}
                      </li>
                      <li
                        onClick={(e) => {
                          if (
                            languages.filter((e) => e.name == "English_3") == ""
                          ) {
                            handleLanguageSelect("English_3");
                            setDetailProvider({
                              ...detailProvider,
                              englishlevel: "Fluent",
                            });
                          } else {
                            setDetailProvider({
                              ...detailProvider,
                              englishlevel: "",
                            });
                            handleLanguageSelect("English_5");
                          }
                        }}
                        className={
                          languages.filter((e) => e.name == "English_3") !=
                            "" || detailProvider.englishlevel == "Fluent"
                            ? "active"
                            : ""
                        }
                      >
                        {t("Fluent")}
                      </li>
                      <li
                        onClick={(e) => {
                          if (
                            languages.filter((e) => e.name == "English_4") == ""
                          ) {
                            handleLanguageSelect("English_4");
                            setDetailProvider({
                              ...detailProvider,
                              englishlevel: "Native",
                            });
                          } else {
                            setDetailProvider({
                              ...detailProvider,
                              englishlevel: "",
                            });
                            handleLanguageSelect("English_5");
                          }
                        }}
                        className={
                          languages.filter((e) => e.name == "English_4") !=
                            "" || detailProvider.englishlevel == "Native"
                            ? "active"
                            : ""
                        }
                      >
                        {t("Native")}
                      </li>
                    </ul>
                  </div>
                  <div className="language">
                    <strong>{t("french")}</strong>
                    <ul>
                      <li
                        onClick={(e) => {
                          if (
                            languages.filter((e) => e.name == "French_1") == ""
                          ) {
                            handleLanguageSelect("French_1");
                            setDetailProvider({
                              ...detailProvider,
                              frenchlevel: "Beginner",
                            });
                          } else {
                            setDetailProvider({
                              ...detailProvider,
                              frenchlevel: "",
                            });
                            handleLanguageSelect("French_5");
                          }
                        }}
                        className={
                          languages.filter((e) => e.name == "French_1") != "" ||
                          detailProvider.frenchlevel == "Beginner"
                            ? "active"
                            : ""
                        }
                      >
                        {t("Beginner")}
                      </li>
                      <li
                        onClick={(e) => {
                          if (
                            languages.filter((e) => e.name == "French_2") == ""
                          ) {
                            handleLanguageSelect("French_2");
                            setDetailProvider({
                              ...detailProvider,
                              frenchlevel: "Intermediate",
                            });
                          } else {
                            setDetailProvider({
                              ...detailProvider,
                              frenchlevel: "",
                            });
                            handleLanguageSelect("French_5");
                          }
                        }}
                        className={
                          languages.filter((e) => e.name == "French_2") != "" ||
                          detailProvider.frenchlevel == "Intermediate"
                            ? "active"
                            : ""
                        }
                      >
                        {t("Intermediate")}
                      </li>
                      <li
                        onClick={(e) => {
                          if (
                            languages.filter((e) => e.name == "French_3") == ""
                          ) {
                            handleLanguageSelect("French_3");
                            setDetailProvider({
                              ...detailProvider,
                              frenchlevel: "Fluent",
                            });
                          } else {
                            setDetailProvider({
                              ...detailProvider,
                              frenchlevel: "",
                            });
                            handleLanguageSelect("French_5");
                          }
                        }}
                        className={
                          languages.filter((e) => e.name == "French_3") != "" ||
                          detailProvider.frenchlevel == "Fluent"
                            ? "active"
                            : ""
                        }
                      >
                        {t("Fluent")}
                      </li>
                      <li
                        onClick={(e) => {
                          if (
                            languages.filter((e) => e.name == "French_4") == ""
                          ) {
                            handleLanguageSelect("French_4");
                            setDetailProvider({
                              ...detailProvider,
                              frenchlevel: "Native",
                            });
                          } else {
                            setDetailProvider({
                              ...detailProvider,
                              frenchlevel: "",
                            });
                            handleLanguageSelect("French_5");
                          }
                        }}
                        className={
                          languages.filter((e) => e.name == "French_4") != "" ||
                          detailProvider.frenchlevel == "Native"
                            ? "active"
                            : ""
                        }
                      >
                        {t("Native")}
                      </li>
                    </ul>
                  </div>
                  <div className="language">
                    <strong>{t("italian")}</strong>
                    <ul>
                      <li
                        onClick={(e) => {
                          if (
                            languages.filter((e) => e.name == "Italian_1") == ""
                          ) {
                            handleLanguageSelect("Italian_1");
                            setDetailProvider({
                              ...detailProvider,
                              italianlevel: "Beginner",
                            });
                          } else {
                            setDetailProvider({
                              ...detailProvider,
                              italianlevel: "",
                            });
                            handleLanguageSelect("Italian_5");
                          }
                        }}
                        className={
                          languages.filter((e) => e.name == "Italian_1") !=
                            "" || detailProvider.italianlevel == "Beginner"
                            ? "active"
                            : ""
                        }
                      >
                        {t("Beginner")}
                      </li>
                      <li
                        onClick={(e) => {
                          if (
                            languages.filter((e) => e.name == "Italian_2") == ""
                          ) {
                            handleLanguageSelect("Italian_2");
                            setDetailProvider({
                              ...detailProvider,
                              italianlevel: "Intermediate",
                            });
                          } else {
                            setDetailProvider({
                              ...detailProvider,
                              italianlevel: "",
                            });
                            handleLanguageSelect("Italian_5");
                          }
                        }}
                        className={
                          languages.filter((e) => e.name == "Italian_2") !=
                            "" || detailProvider.italianlevel == "Intermediate"
                            ? "active"
                            : ""
                        }
                      >
                        {t("Intermediate")}
                      </li>
                      <li
                        onClick={(e) => {
                          if (
                            languages.filter((e) => e.name == "Italian_3") == ""
                          ) {
                            handleLanguageSelect("Italian_3");
                            setDetailProvider({
                              ...detailProvider,
                              italianlevel: "Fluent",
                            });
                          } else {
                            setDetailProvider({
                              ...detailProvider,
                              italianlevel: "",
                            });
                            handleLanguageSelect("Italian_5");
                          }
                        }}
                        className={
                          languages.filter((e) => e.name == "Italian_3") !=
                            "" || detailProvider.italianlevel == "Fluent"
                            ? "active"
                            : ""
                        }
                      >
                        {t("Fluent")}
                      </li>
                      <li
                        onClick={(e) => {
                          if (
                            languages.filter((e) => e.name == "Italian_4") == ""
                          ) {
                            handleLanguageSelect("Italian_4");
                            setDetailProvider({
                              ...detailProvider,
                              italianlevel: "Native",
                            });
                          } else {
                            setDetailProvider({
                              ...detailProvider,
                              italianlevel: "",
                            });
                            handleLanguageSelect("Italian_5");
                          }
                        }}
                        className={
                          languages.filter((e) => e.name == "Italian_4") !=
                            "" || detailProvider.italianlevel == "Native"
                            ? "active"
                            : ""
                        }
                      >
                        {t("Native")}
                      </li>
                    </ul>
                  </div>
                  <div className="language">
                    <strong>{t("german")}</strong>
                    <ul>
                      <li
                        onClick={(e) => {
                          if (
                            languages.filter((e) => e.name == "German_1") == ""
                          ) {
                            handleLanguageSelect("German_1");
                            setDetailProvider({
                              ...detailProvider,
                              germanlevel: "Beginner",
                            });
                          } else {
                            setDetailProvider({
                              ...detailProvider,
                              germanlevel: "",
                            });
                            handleLanguageSelect("German_5");
                          }
                        }}
                        className={
                          languages.filter((e) => e.name == "German_1") != "" ||
                          detailProvider.germanlevel == "Beginner"
                            ? "active"
                            : ""
                        }
                      >
                        {t("Beginner")}
                      </li>
                      <li
                        onClick={(e) => {
                          if (
                            languages.filter((e) => e.name == "German_2") == ""
                          ) {
                            handleLanguageSelect("German_2");
                            setDetailProvider({
                              ...detailProvider,
                              germanlevel: "Intermediate",
                            });
                          } else {
                            setDetailProvider({
                              ...detailProvider,
                              germanlevel: "",
                            });
                            handleLanguageSelect("German_5");
                          }
                        }}
                        className={
                          languages.filter((e) => e.name == "German_2") != "" ||
                          detailProvider.germanlevel == "Intermediate"
                            ? "active"
                            : ""
                        }
                      >
                        {t("Intermediate")}
                      </li>
                      <li
                        onClick={(e) => {
                          if (
                            languages.filter((e) => e.name == "German_3") == ""
                          ) {
                            handleLanguageSelect("German_3");
                            setDetailProvider({
                              ...detailProvider,
                              germanlevel: "Fluent",
                            });
                          } else {
                            setDetailProvider({
                              ...detailProvider,
                              germanlevel: "",
                            });
                            handleLanguageSelect("German_5");
                          }
                        }}
                        className={
                          languages.filter((e) => e.name == "German_3") != "" ||
                          detailProvider.germanlevel == "Fluent"
                            ? "active"
                            : ""
                        }
                      >
                        {t("Fluent")}
                      </li>
                      <li
                        onClick={(e) => {
                          if (
                            languages.filter((e) => e.name == "German_4") == ""
                          ) {
                            handleLanguageSelect("German_4");
                            setDetailProvider({
                              ...detailProvider,
                              germanlevel: "Native",
                            });
                          } else {
                            setDetailProvider({
                              ...detailProvider,
                              germanlevel: "",
                            });
                            handleLanguageSelect("German_5");
                          }
                        }}
                        className={
                          languages.filter((e) => e.name == "German_4") != "" ||
                          detailProvider.germanlevel == "Native"
                            ? "active"
                            : ""
                        }
                      >
                        {t("Native")}
                      </li>
                    </ul>
                  </div>
                  <div className="language">
                    <strong>{t("spanish")}</strong>
                    <ul>
                      <li
                        onClick={(e) => {
                          if (
                            languages.filter((e) => e.name == "Spanish_1") == ""
                          ) {
                            handleLanguageSelect("Spanish_1");
                            setDetailProvider({
                              ...detailProvider,
                              spanishlevel: "Beginner",
                            });
                          } else {
                            setDetailProvider({
                              ...detailProvider,
                              spanishlevel: "",
                            });
                            handleLanguageSelect("Spanish_5");
                          }
                        }}
                        className={
                          languages.filter((e) => e.name == "Spanish_1") !=
                            "" || detailProvider.spanishlevel == "Beginner"
                            ? "active"
                            : ""
                        }
                      >
                        {t("Beginner")}
                      </li>
                      <li
                        onClick={(e) => {
                          if (
                            languages.filter((e) => e.name == "Spanish_2") == ""
                          ) {
                            handleLanguageSelect("Spanish_2");
                            setDetailProvider({
                              ...detailProvider,
                              spanishlevel: "Intermediate",
                            });
                          } else {
                            setDetailProvider({
                              ...detailProvider,
                              spanishlevel: "",
                            });
                            handleLanguageSelect("Spanish_5");
                          }
                        }}
                        className={
                          languages.filter((e) => e.name == "Spanish_2") !=
                            "" || detailProvider.spanishlevel == "Intermediate"
                            ? "active"
                            : ""
                        }
                      >
                        {t("Intermediate")}
                      </li>
                      <li
                        onClick={(e) => {
                          if (
                            languages.filter((e) => e.name == "Spanish_3") == ""
                          ) {
                            handleLanguageSelect("Spanish_3");
                            setDetailProvider({
                              ...detailProvider,
                              spanishlevel: "Fluent",
                            });
                          } else {
                            setDetailProvider({
                              ...detailProvider,
                              spanishlevel: "",
                            });
                            handleLanguageSelect("Spanish_5");
                          }
                        }}
                        className={
                          languages.filter((e) => e.name == "Spanish_3") !=
                            "" || detailProvider.spanishlevel == "Fluent"
                            ? "active"
                            : ""
                        }
                      >
                        {t("Fluent")}
                      </li>
                      <li
                        onClick={(e) => {
                          if (
                            languages.filter((e) => e.name == "Spanish_4") == ""
                          ) {
                            handleLanguageSelect("Spanish_4");
                            setDetailProvider({
                              ...detailProvider,
                              spanishlevel: "Native",
                            });
                          } else {
                            setDetailProvider({
                              ...detailProvider,
                              spanishlevel: "",
                            });
                            handleLanguageSelect("Spanish_5");
                          }
                        }}
                        className={
                          languages.filter((e) => e.name == "Spanish_4") !=
                            "" || detailProvider.spanishlevel == "Native"
                            ? "active"
                            : ""
                        }
                      >
                        {t("Native")}
                      </li>
                    </ul>
                  </div>
                  <div className="language">
                    <strong>{t("chinese")}</strong>
                    <ul>
                      <li
                        onClick={(e) => {
                          if (
                            languages.filter((e) => e.name == "Chinese_1") == ""
                          ) {
                            handleLanguageSelect("Chinese_1");
                            setDetailProvider({
                              ...detailProvider,
                              chineselevel: "Beginner",
                            });
                          } else {
                            setDetailProvider({
                              ...detailProvider,
                              chineselevel: "",
                            });
                            handleLanguageSelect("Chinese_5");
                          }
                        }}
                        className={
                          languages.filter((e) => e.name == "Chinese_1") !=
                            "" || detailProvider.chineselevel == "Beginner"
                            ? "active"
                            : ""
                        }
                      >
                        {t("Beginner")}
                      </li>
                      <li
                        onClick={(e) => {
                          if (
                            languages.filter((e) => e.name == "Chinese_2") == ""
                          ) {
                            handleLanguageSelect("Chinese_2");
                            setDetailProvider({
                              ...detailProvider,
                              chineselevel: "Intermediate",
                            });
                          } else {
                            setDetailProvider({
                              ...detailProvider,
                              chineselevel: "",
                            });
                            handleLanguageSelect("Chinese_5");
                          }
                        }}
                        className={
                          languages.filter((e) => e.name == "Chinese_2") !=
                            "" || detailProvider.chineselevel == "Intermediate"
                            ? "active"
                            : ""
                        }
                      >
                        {t("Intermediate")}
                      </li>
                      <li
                        onClick={(e) => {
                          if (
                            languages.filter((e) => e.name == "Chinese_3") == ""
                          ) {
                            handleLanguageSelect("Chinese_3");
                            setDetailProvider({
                              ...detailProvider,
                              chineselevel: "Fluent",
                            });
                          } else {
                            setDetailProvider({
                              ...detailProvider,
                              chineselevel: "",
                            });
                            handleLanguageSelect("Chinese_5");
                          }
                        }}
                        className={
                          languages.filter((e) => e.name == "Chinese_3") !=
                            "" || detailProvider.chineselevel == "Fluent"
                            ? "active"
                            : ""
                        }
                      >
                        {t("Fluent")}
                      </li>
                      <li
                        onClick={(e) => {
                          if (
                            languages.filter((e) => e.name == "Chinese_4") == ""
                          ) {
                            handleLanguageSelect("Chinese_4");
                            setDetailProvider({
                              ...detailProvider,
                              chineselevel: "Native",
                            });
                          } else {
                            setDetailProvider({
                              ...detailProvider,
                              chineselevel: "",
                            });
                            handleLanguageSelect("Chinese_5");
                          }
                        }}
                        className={
                          languages.filter((e) => e.name == "Chinese_4") !=
                            "" || detailProvider.chineselevel == "Native"
                            ? "active"
                            : ""
                        }
                      >
                        {t("Native")}
                      </li>
                    </ul>
                  </div>
                  <div className="language">
                    <strong>
                      {t("Other language")}
                      <input
                        type="text"
                        placeholder={t("text-type")}
                        onChange={(e) =>
                          setDetailProvider({
                            ...detailProvider,
                            otherlangname: e.target.value,
                          })
                        }
                        defaultValue={
                          detailProvider.otherlangname != null
                            ? detailProvider.otherlangname
                            : ""
                        }
                      />
                    </strong>
                    <ul>
                      <li
                        onClick={(e) => {
                          if (
                            languages.filter((e) => e.name == "Other_1") == ""
                          ) {
                            handleLanguageSelect("Other_1");
                            setDetailProvider({
                              ...detailProvider,
                              otherlevel: "Beginner",
                            });
                          } else {
                            setDetailProvider({
                              ...detailProvider,
                              otherlevel: "",
                            });
                            handleLanguageSelect("Other_5");
                          }
                        }}
                        className={
                          languages.filter((e) => e.name == "Other_1") != "" ||
                          detailProvider.otherlevel == "Beginner"
                            ? "active"
                            : ""
                        }
                      >
                        {t("Beginner")}
                      </li>
                      <li
                        onClick={(e) => {
                          if (
                            languages.filter((e) => e.name == "Other_2") == ""
                          ) {
                            handleLanguageSelect("Other_2");
                            setDetailProvider({
                              ...detailProvider,
                              otherlevel: "Intermediate",
                            });
                          } else {
                            setDetailProvider({
                              ...detailProvider,
                              otherlevel: "",
                            });
                            handleLanguageSelect("Other_5");
                          }
                        }}
                        className={
                          languages.filter((e) => e.name == "Other_2") != "" ||
                          detailProvider.otherlevel == "Intermediate"
                            ? "active"
                            : ""
                        }
                      >
                        {t("Intermediate")}
                      </li>
                      <li
                        onClick={(e) => {
                          if (
                            languages.filter((e) => e.name == "Other_3") == ""
                          ) {
                            handleLanguageSelect("Other_3");
                            setDetailProvider({
                              ...detailProvider,
                              otherlevel: "Fluent",
                            });
                          } else {
                            setDetailProvider({
                              ...detailProvider,
                              otherlevel: "",
                            });
                            handleLanguageSelect("Other_5");
                          }
                        }}
                        className={
                          languages.filter((e) => e.name == "Other_3") != "" ||
                          detailProvider.otherlevel == "Fluent"
                            ? "active"
                            : ""
                        }
                      >
                        {t("Fluent")}
                      </li>
                      <li
                        onClick={(e) => {
                          if (
                            languages.filter((e) => e.name == "Other_4") == ""
                          ) {
                            handleLanguageSelect("Other_4");
                            setDetailProvider({
                              ...detailProvider,
                              otherlevel: "Native",
                            });
                          } else {
                            setDetailProvider({
                              ...detailProvider,
                              otherlevel: "",
                            });
                            handleLanguageSelect("Other_5");
                          }
                        }}
                        className={
                          languages.filter((e) => e.name == "Other_4") != "" ||
                          detailProvider.otherlevel == "Native"
                            ? "active"
                            : ""
                        }
                      >
                        {t("Native")}
                      </li>
                    </ul>
                  </div>
                </div>
                <br />
                <br />
                <div className="iconsec">
                  <div className="right2">
                    <div className="form_group qualification full set">
                      <br />
                      <label>
                        {t("Do you have any")} <a style={
                        {
                          marginRight: "3px"
                        }
                      }>{t("allergies")} </a>
                        <span className="smallpop">
                          {t("informationAbout")}
                        </span>
                        {t("which could interfere with your work performance?")}
                        <span
                          className={error.anyallergies != "" ? "starred" : ""}
                        >
                          *
                        </span>
                      </label>
                      <div className="checkbox create">
                        <ul
                          onClick={(e) =>
                            setError({ ...error, anyallergies: "" })
                          }
                        >
                          <li>
                            <input
                              type="radio"
                              name="allergies"
                              onClick={(e) =>
                                setDetailProvider({
                                  ...detailProvider,
                                  anyallergies: t("confirm"),
                                })
                              }
                              checked={
                                detailProvider.anyallergies == t("confirm")
                                  ? true
                                  : false
                              }
                            />
                            <span> {t("confirm")}</span>
                          </li>
                          <li>
                            <input
                              type="radio"
                              name="allergies"
                              onClick={(e) =>
                                setDetailProvider({
                                  ...detailProvider,
                                  anyallergies: t("objection"),
                                })
                              }
                              checked={
                                detailProvider.anyallergies == t("objection")
                                  ? true
                                  : false
                              }
                            />
                            <span> {t("objection")}</span>
                          </li>
                        </ul>
                        {detailProvider.anyallergies == t("confirm") ? (
                          <>
                            <textarea
                              rows="2"
                              placeholder={t("short-description")}
                              maxlength="300"
                              name="message"
                              onChange={(e) => {
                                setError({
                                  ...error,
                                  anyallergiesdescription: "",
                                });
                                setDetailProvider({
                                  ...detailProvider,
                                  anyallergiesdescription: e.target.value,
                                });
                              }}
                              defaultValue={
                                detailProvider.anyallergiesdescription != null
                                  ? detailProvider.anyallergiesdescription
                                  : ""
                              }
                              className={
                                error.anyallergiesdescription != ""
                                  ? "bordererror"
                                  : ""
                              }
                            ></textarea>
                            <span>
                              {t("number-characters")}
                              {300 -
                                detailProvider.anyallergiesdescription.length}
                            </span>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="form_group qualification full set">
                      <label>
                        {t("Do you have any")} <a style={
                        {
                          marginRight: "3px"
                        }
                      }>{t("medical condition")} </a>
                        <span className="smallpop">
                          {t("informationAbout")}
                        </span>
                        {t("which could interfere with your work performance?")}
                        <span
                          className={
                            error.medicalcondition != "" ? "starred" : ""
                          }
                        >
                          *
                        </span>
                      </label>
                      <div className="checkbox create">
                        <ul
                          onClick={(e) =>
                            setError({
                              ...error,
                              medicalcondition: "",
                            })
                          }
                        >
                          <li>
                            <input
                              type="radio"
                              name="medical"
                              onClick={(e) =>
                                setDetailProvider({
                                  ...detailProvider,
                                  medicalcondition: t("confirm"),
                                })
                              }
                              checked={
                                detailProvider.medicalcondition == t("confirm")
                                  ? true
                                  : false
                              }
                            />
                            <span> {t("confirm")}</span>
                          </li>
                          <li>
                            <input
                              type="radio"
                              name="medical"
                              onClick={(e) =>
                                setDetailProvider({
                                  ...detailProvider,
                                  medicalcondition: t("objection"),
                                })
                              }
                              checked={
                                detailProvider.medicalcondition ==
                                t("objection")
                                  ? true
                                  : false
                              }
                            />
                            <span> {t("objection")}</span>
                          </li>
                        </ul>
                        {detailProvider.medicalcondition == t("confirm") ? (
                          <>
                            <textarea
                              rows="2"
                              placeholder={t("short-description")}
                              maxlength="300"
                              name="message"
                              onChange={(e) => {
                                setError({
                                  ...error,
                                  medicalconditiondescription: "",
                                });
                                setDetailProvider({
                                  ...detailProvider,
                                  medicalconditiondescription: e.target.value,
                                });
                              }}
                              defaultValue={
                                detailProvider.medicalconditiondescription !=
                                null
                                  ? detailProvider.medicalconditiondescription
                                  : ""
                              }
                              className={
                                error.medicalconditiondescription != ""
                                  ? "bordererror"
                                  : ""
                              }
                            ></textarea>
                            <span>
                              {t("number-characters")}
                              {300 -
                                detailProvider.medicalconditiondescription
                                  .length}
                            </span>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="left2">
                    <div className="icon">
                      <ul>
                        {detail.service_type && detail.service_type.tab1 ? (
                          <>
                            <li
                              onClick={(e) => setError({ ...error, smoke: "" })}
                              className={error.smoke != "" ? "starred" : ""}
                            >
                              {t("Do you smoke?")}
                              <div className="icons">
                                <BanSmokeIcon
                                  className={
                                    option.smoke == "false" ? "active" : ""
                                  }
                                  onClick={(e) => {
                                    setOption({ ...option, smoke: "false" });
                                  }}
                                />

                                <SmokeIcon
                                  className={
                                    option.smoke == "true" ? "active" : ""
                                  }
                                  onClick={(e) =>
                                    setOption({ ...option, smoke: "true" })
                                  }
                                />
                              </div>
                            </li>
                            <li
                              onClick={(e) =>
                                setError({
                                  ...error,
                                  carorlicence: "",
                                })
                              }
                              className={
                                error.carorlicence != "" ? "starred" : ""
                              }
                            >
                              {t("Do you own a car or licence?")}
                              <div className="icons">
                                <BanCarIcon
                                  className={
                                    option.licence == "false" ? "active" : ""
                                  }
                                  onClick={(e) => {
                                    setOption({ ...option, licence: "false" });
                                  }}
                                />

                                <CarIcon
                                  className={
                                    option.licence == "true" ? "active" : ""
                                  }
                                  onClick={(e) =>
                                    setOption({ ...option, licence: "true" })
                                  }
                                />
                              </div>
                            </li>
                            <li
                              onClick={(e) =>
                                setError({ ...error, cooking: "" })
                              }
                              className={error.cooking != "" ? "starred" : ""}
                            >
                              {t("cooking-kids")}
                              <div className="icons">
                                <BanCookIcon
                                  className={
                                    option.kids == "false" ? "active" : ""
                                  }
                                  onClick={(e) => {
                                    setOption({ ...option, kids: "false" });
                                  }}
                                />

                                <CookIcon
                                  className={
                                    option.kids == "true" ? "active" : ""
                                  }
                                  onClick={(e) =>
                                    setOption({ ...option, kids: "true" })
                                  }
                                />
                              </div>
                            </li>
                            <li
                              onClick={(e) =>
                                setError({
                                  ...error,
                                  lighthousework: "",
                                })
                              }
                              className={
                                error.lighthousework != "" ? "starred" : ""
                              }
                            >
                              {t("light-housework")}
                              <div className="icons">
                                <BanHouseIcon
                                  className={
                                    option.housework == "false" ? "active" : ""
                                  }
                                  onClick={(e) =>
                                    setOption({ ...option, housework: "false" })
                                  }
                                />

                                <HouseIcon
                                  className={
                                    option.housework == "true" ? "active" : ""
                                  }
                                  onClick={(e) =>
                                    setOption({ ...option, housework: "true" })
                                  }
                                />
                              </div>
                            </li>
                            <li
                              onClick={(e) =>
                                setError({ ...error, traveling: "" })
                              }
                              className={error.traveling != "" ? "starred" : ""}
                            >
                              {t("Traveling with family")}
                              <div className="icons">
                                <BanPlaneIcon
                                  className={
                                    option.family == "false" ? "active" : ""
                                  }
                                  onClick={(e) => {
                                    setOption({ ...option, family: "false" });
                                  }}
                                />

                                <PlaneIcon
                                  className={
                                    option.family == "true" ? "active" : ""
                                  }
                                  onClick={(e) =>
                                    setOption({ ...option, family: "true" })
                                  }
                                />
                              </div>
                            </li>
                          </>
                        ) : (
                          ""
                        )}
                      </ul>
                    </div>
                    <div
                      className="form_group full"
                      style={{ marginTop: "20px" }}
                    >
                      <label>
                        {t("Interested in working abroad")}
                        <span
                          className={error.workingabroad != "" ? "starred" : ""}
                        >
                          *
                        </span>
                      </label>
                      <div className="checkbox create">
                        <ul
                          onClick={(e) =>
                            setError({ ...error, workingabroad: "" })
                          }
                        >
                          <li>
                            <input
                              type="radio"
                              name="abroad"
                              onClick={(e) =>
                                setDetailProvider({
                                  ...detailProvider,
                                  workingabroad: t("confirm"),
                                })
                              }
                              checked={
                                detailProvider.workingabroad == t("confirm")
                                  ? true
                                  : false
                              }
                            />
                            <span> {t("confirm")}</span>
                          </li>
                          <li>
                            <input
                              type="radio"
                              name="abroad"
                              onClick={(e) =>
                                setDetailProvider({
                                  ...detailProvider,
                                  workingabroad: t("objection"),
                                })
                              }
                              checked={
                                detailProvider.workingabroad == t("objection")
                                  ? true
                                  : false
                              }
                            />
                            <span> {t("objection")}</span>
                          </li>
                          <li>
                            <input
                              type="radio"
                              name="abroad"
                              onClick={(e) =>
                                setDetailProvider({
                                  ...detailProvider,
                                  workingabroad: t("Open to offers"),
                                })
                              }
                              checked={
                                detailProvider.workingabroad ==
                                t("Open to offers")
                                  ? true
                                  : false
                              }
                            />
                            <span> {t("Open to offers")}</span>
                          </li>
                        </ul>
                      </div>
                      {/* <div className='errorfield'>{error.message}</div>*/}
                    </div>
                    {detail.service_type &&
                    detail.service_type.tab1 &&
                    Object.keys(detail.service_type).length == 1 ? (
                      <div className="form_group full">
                        <label>
                          {t("Would you consider living with the family?")}
                          <span
                            className={
                              error.livewithfamily != "" ? "starred" : ""
                            }
                          >
                            *
                          </span>
                        </label>
                        <div className="checkbox create">
                          <ul
                            onClick={(e) =>
                              setError({
                                ...error,
                                livewithfamily: "",
                              })
                            }
                          >
                            <li>
                              <input
                                type="radio"
                                name="family"
                                onClick={(e) =>
                                  setDetailProvider({
                                    ...detailProvider,
                                    livewithfamily: t("confirm"),
                                  })
                                }
                                checked={
                                  detailProvider.livewithfamily == t("confirm")
                                    ? true
                                    : false
                                }
                              />
                              <span> {t("confirm")}</span>
                            </li>
                            <li>
                              <input
                                type="radio"
                                name="family"
                                onClick={(e) =>
                                  setDetailProvider({
                                    ...detailProvider,
                                    livewithfamily: t("objection"),
                                  })
                                }
                                checked={
                                  detailProvider.livewithfamily ==
                                  t("objection")
                                    ? true
                                    : false
                                }
                              />
                              <span> {t("objection")}</span>
                            </li>
                            <li>
                              <input
                                type="radio"
                                name="family"
                                onClick={(e) =>
                                  setDetailProvider({
                                    ...detailProvider,
                                    livewithfamily: t("Open to offers"),
                                  })
                                }
                                checked={
                                  detailProvider.livewithfamily ==
                                  t("Open to offers")
                                    ? true
                                    : false
                                }
                              />
                              <span> {t("Open to offers")}</span>
                            </li>
                          </ul>
                        </div>
                        {/* <div className='errorfield'>{error.message}</div>*/}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div className="button">
                  <button
                    onClick={(e) => {
                      handleSetStep(5);
                      setMobile(-60);
                      window.scrollTo({ top: 0 });
                      setTimeout(() => {
                        setcalenderType(1);
                      }, 500);
                    }}
                  >
                    {t("text-back")}
                  </button>
                  <button
                    onClick={(e) => {
                      if (
                        (detailProvider.englishlevel != "" ||
                          detailProvider.frenchlevel != "" ||
                          detailProvider.italianlevel != "" ||
                          detailProvider.germanlevel != "" ||
                          detailProvider.spanishlevel != "" ||
                          detailProvider.chineselevel != "" ||
                          detailProvider.otherlevel != "") &&
                        (detail.service_type &&
                        Object.keys(detail.service_type).length == 1 &&
                        detail.service_type.tab1
                          ? option.smoke != "" &&
                            option.licence != "" &&
                            option.kids != "" &&
                            option.housework != "" &&
                            option.family != ""
                          : detailProvider.medicalcondition != "") &&
                        detailProvider.workingabroad != "" &&
                        detailProvider.anyallergies != "" &&
                        (detailProvider.anyallergies == "Yes"
                          ? detailProvider.anyallergiesdescription != ""
                          : languages[0]) &&
                        detailProvider.medicalcondition != "" &&
                        (detailProvider.medicalcondition == "Yes"
                          ? detailProvider.medicalconditiondescription != ""
                          : languages[0]) &&
                        (detail.service_type &&
                        detail.service_type.tab1 &&
                        Object.keys(detail.service_type).length == 1
                          ? detailProvider.livewithfamily != ""
                          : detailProvider.medicalcondition != "")
                      ) {
                        handleSetStep(7);
                        setMobile(-75);
                        window.scrollTo({ top: 0 });
                        fetchProviderData(false);
                      } else {
                        step6.map((e) => {
                          validateAfterLoginField(e.name);
                        });
                      }
                    }}
                  >
                    {t("text-next")}
                  </button>
                </div>
              </div>
              {/**/}
              <div
                className="detail  verification  setp7"
                style={step == 7 ? {} : { display: "none" }}
              >
                <div className="form_group space">
                  <label>{t("verify-accounts")}</label>
                  <div className="social_verify">
                    <FacebookProvider appId="3384521628485216">
                      <LoginButton
                        scope="email"
                        onCompleted={handleResponse}
                        onError={handleError}
                        className="facebook"
                      >
                        <span>
                          <FacebookIcon
                            className={
                              detailProvider.facebookverify ? "active" : ""
                            }
                            fillColor={"none"}
                            color={"#B7B7B7"}
                            textColor={"#B7B7B7"}
                          />
                        </span>
                      </LoginButton>
                    </FacebookProvider>

                    <LinkedinContent
                      handleResponse={handleResponse}
                      profile={"icon"}
                      status={detailProvider.linkdinverify}
                    />
                  </div>
                  <br />
                  <br />
                  <label>
                    {t("Do you have")} <a style={{
                      marginRight: "3px"
                  }}>{t("background check")} </a>
                    <span className="smallpop">{t("backgroundCheck")}</span>
                    {t("document")}
                    <span
                      className={
                        error.backgroundstatus != "" ||
                        (detailProvider.backgroundstatus == t("confirm")
                          ? error.backgrounddoc != ""
                          : error.backgroundstatus != "")
                          ? "starred"
                          : ""
                      }
                    >
                      *
                    </span>
                  </label>
                  <div className="checkbox create">
                    <ul
                      onClick={(e) => {
                        setError({
                          ...error,
                          backgroundstatus: "",
                          backgrounddoc: "",
                        });
                      }}
                    >
                      <li>
                        <input
                          type="radio"
                          name="background"
                          onClick={(e) =>
                            setDetailProvider({
                              ...detailProvider,
                              backgroundstatus: t("confirm"),
                            })
                          }
                          checked={
                            detailProvider.backgroundstatus == t("confirm")
                              ? true
                              : false
                          }
                        />
                        <span> {t("confirm")}</span>
                      </li>
                      <li>
                        <input
                          type="radio"
                          name="background"
                          onClick={(e) =>
                            setDetailProvider({
                              ...detailProvider,
                              backgroundstatus: t("objection"),
                            })
                          }
                          checked={
                            detailProvider.backgroundstatus == t("objection")
                              ? true
                              : false
                          }
                        />
                        <span> {t("objection")}</span>
                      </li>
                    </ul>
                  </div>
                  <div className="upload">
                    <input
                      type="file"
                      placeholder={t("choose-file-upload")}
                      onChange={(e) => {
                        setError({
                          ...error,
                          backgroundstatus: "",
                          backgrounddoc: "",
                        });
                        setDetailProvider({
                          ...detailProvider,
                          backgrounddoc: e.target.files[0],
                        });
                      }}
                      accept=".doc, .docx,.ppt, .pptx,.txt,.pdf"
                    />
                    <span>
                      {detailProvider.backgrounddoc != ""
                        ? detailProvider.backgrounddoc.name
                          ? detailProvider.backgrounddoc.name
                          : detailProvider.backgrounddoc
                        : t("choose-file")}
                    </span>
                    <button>
                      <UploadIcon />
                    </button>
                  </div>
                </div>
                <div className="form_group fs">
                  <label>
                    <strong>
                      {t("verify-phone-number")}
                      <span className={error.phone != "" ? "starred" : ""}>
                        *
                      </span>
                    </strong>
                    {t("enter-phone")} <a style={{
                      marginRight: "3px"
                  }}>{t("verification")}</a>
                    <a style={{
                      marginRight: "3px"
                    }}>{t("procesa")}</a>
                    <span className="smallpop">
                      {t("phone-verification-ask")}
                    </span>
                    {t("purposes")}
                  </label>
                  <div className="verification_number">
                    <div className="number profilecretate">
                      <div
                        className="country_flag"
                        onClick={(e) => codeselect()}
                      >
                        <img src={contactCode.flag} />
                        {contactCode.code != ""
                          ? contactCode.code
                          : "+" + detailProvider.countrycode}
                      </div>
                      <ul style={isCode ? { display: "none" } : {}}>
                        {DATA_COUNTRY.data.map((e) => {
                          return (
                            <li
                              onClick={(a) => {
                                codeselect();
                                setContactCode({
                                  flag: e.flag,
                                  code: e.dial_code,
                                });
                              }}
                            >
                              <img src={e.flag} />
                              {e.country + " " + " " + e.dial_code}
                            </li>
                          );
                        })}
                      </ul>
                      <input
                        type="number"
                        className={
                          errorField.phone && errorField.phone.length > 2
                            ? "bordererror"
                            : ""
                        }
                        placeholder={t("enter-number")}
                        onChange={(e) => {
                          {
                            setError({ ...error, phone: "" });
                            setDetailProvider({
                              ...detailProvider,
                              phone: e.target.value,
                            });
                            detailProvider.phone.length > 7
                              ? setErrorField({ ...errorField, phone: "" })
                              : setErrorField({
                                  ...errorField,
                                  phone: "error",
                                });
                          }
                        }}
                        value={
                          detailProvider.phone != null
                            ? detailProvider.phone
                            : ""
                        }
                      />
                      <button
                        onClick={(e) => handleVirifyMobile()}
                        style={
                          detail.phoneVerifiedStatus != null &&
                          detail.phoneVerifiedStatus == 1
                            ? { display: "none" }
                            : {}
                        }
                      >
                        {isUpdate ? t("text-wait") : t("get-code")}
                      </button>
                    </div>
                    <br />
                    <br />
                    <label>{t("enter-code-verify")} </label>
                    <div className="number">
                      <input
                        type="number"
                        className={
                          (errorField.otperror &&
                            errorField.otperror.length > 2) ||
                          error.phone != ""
                            ? "bordererror"
                            : ""
                        }
                        placeholder={t("enter-code")}
                        onChange={(e) => {
                          setOtp(e.target.value);
                          otp.length > 2
                            ? setErrorField({ ...errorField, otperror: "" })
                            : setErrorField({
                                ...errorField,
                                otperror: "error",
                              });
                        }}
                      />
                      <button
                        onClick={(e) => handleVerifyOtp()}
                        style={
                          detail.phoneVerifiedStatus != null &&
                          detail.phoneVerifiedStatus == 1
                            ? { display: "none" }
                            : {}
                        }
                      >
                        {t("text-verify")}
                      </button>
                    </div>
                  </div>
                  <div
                    className="success"
                    id="success"
                    style={
                      detail.phoneVerifiedStatus != null &&
                      detail.phoneVerifiedStatus == 1
                        ? { display: "block" }
                        : { display: "none" }
                    }
                  >
                    <FlagIcon />
                    {t("verification-thank")}
                  </div>
                  <div
                    className="success2"
                    id="success4"
                    style={{ display: "none" }}
                  >
                    <MarkIcon />
                    {t("verification-fail")}
                  </div>
                  <div
                    className="success2"
                    id="success3"
                    style={{ display: "none" }}
                  >
                    <MarkIcon />
                    {t("verification-fail-contact")}
                    <Link to="/contact-us">{t("tech-support")}</Link>
                    {t("further-assistance")}
                  </div>
                  {/* <div className='errorfield'>{error.message}</div>*/}
                </div>
                <div className="form_group full">
                  <br />
                  <br />
                  <br />
                  <label>
                    {t("give-consert-profile")}
                    <span
                      className={
                        error.plateformsocialmedia != "" ? "starred" : ""
                      }
                    >
                      *
                    </span>
                  </label>
                  <div className="checkbox create">
                    <ul
                      onClick={(e) =>
                        setError({
                          ...error,
                          plateformsocialmedia: "",
                        })
                      }
                    >
                      <li>
                        <input
                          type="radio"
                          name="social"
                          onClick={(e) =>
                            setDetailProvider({
                              ...detailProvider,
                              plateformsocialmedia: t("confirm"),
                            })
                          }
                          checked={
                            detailProvider.plateformsocialmedia == "" ||
                            detailProvider.plateformsocialmedia == t("confirm")
                              ? true
                              : false
                          }
                        />
                        <span> {t("confirm")}</span>
                      </li>
                      <li>
                        <input
                          type="radio"
                          name="social"
                          onClick={(e) =>
                            setDetailProvider({
                              ...detailProvider,
                              plateformsocialmedia: t("objection"),
                            })
                          }
                          checked={
                            detailProvider.plateformsocialmedia ==
                            t("objection")
                              ? true
                              : false
                          }
                        />
                        <span> {t("objection")}</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="button">
                  <button
                    onClick={(e) => {
                      handleSetStep(6);
                      setMobile(-60);
                      window.scrollTo({ top: 0 });
                    }}
                  >
                    {t("text-back")}
                  </button>
                  <button
                   
                    onClick={(e) => {
						 fetchProviderData(true);
                      if (detailProvider.plateformsocialmedia == "") {
                        setDetailProvider({
                          ...detailProvider,
                          plateformsocialmedia: t("confirm"),
                        });
                      }
                      if (
                        (detailProvider.backgroundstatus == t("confirm")
                          ? detailProvider.backgrounddoc != ""
                          : detailProvider.backgroundstatus != "") &&
                        detailProvider.backgroundstatus != "" &&
                        (detail.phoneVerifiedStatus == 1 || verify == "done")
                      ) {
                        fetchProviderData(true);
                      } else {
                        step7.map((e) => {
                          validateAfterLoginField(e.name);
                        });
                      }
                    }}
                  >
                    {t("text-finish")}
                  </button>
                </div>
              </div>

              {showLoginModal && (
                <Modal show={showLoginModal} onHide={handleCloselogin_first}>
                  <Modal.Body>
                    <div className="promocode_content login_first">
                      <Link to="" onClick={handleCloselogin_first}>
                        +
                      </Link>
                      <h2>{t("Congratulations! Your profile is live now.")}</h2>
                      <img src="./images/create_profile.png" />
                      <p className="pro">
                        {t("wish-senscare-profile")}
                        <Link to="/search-providers" style={
                          {
                            marginLeft: "2px"
                          }
                        }>{t("text-here")}</Link>.
                      </p>
                      <button style={
                        {
                          height: "auto",
                        }
                      } onClick={handleRedirect}>
                        {t("search-jobs")}
                      </button>
                    </div>
                  </Modal.Body>
                </Modal>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
